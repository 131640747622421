.post-login-page {
  margin-top: 20px;
  padding: 0 10px;

  @include media(null, 1280px) {
    max-width: 1280px;
  }

  @include media-xs {
    padding: 0;
    margin: 0;
  }
}

/* Menu */

.post-login__menu {
  margin-right: 20px;
  border-radius: 5px;
  width: 230px;
  max-width: 230px;

  @include media-xs {
    display: none;
  }
}
.post-login-menu {
  position: sticky;
  position: -webkit-sticky;
  top: $header-collapsed-height;
  border: 1px solid color(promotions-card, block-background);
  border-radius: 5px;

  > li {
    font-size: 14px;

    .badge {
      display: none!important;
    }
  }

  > li:not(:first-child) {
    border-top: 1px solid color(promotions-card, block-background);
  }

  &_wrap {
    border-radius: 5px;

    .aside-menu__hot-links {

      .logged-in {
        gap: 10px;
        display: flex;
      }

      .aside-menu__deposit, .aside-menu__withdrawal {
        padding-top: 26px;
        width: 100%;

        &::before {
          font-family: 'Glyphter-custom';
          top: 8px;
          position: absolute;
        }
      }

      .aside-menu__deposit {
        &::before {
          content: '\0044';
        }
      }

      .aside-menu__withdrawal {
        &::before {
          content: '\0043';
          transform: rotate(-90deg);
        }
      }

      .button-default::after {
        content: none;
      }
    }
  }

  .settings-menu__item-override {

    a:not(.fpLink) {
      margin: 0;
      padding: 10px 5px 10px 14px;
      border-top: 1px solid color(details-summary, border-top);
    }

    .details-root {
      border: 0;
      background: none;
      box-shadow: none;
      text-transform: uppercase;

      div[class*="Summary-root"] {
        min-height: 0;
        padding: 0;

        .settings-menu {
          .settings-menu__item-override {
            padding: 5px 10px 5px 28px;
            border-radius: 0;
            outline: none;
            text-transform: none;
            border-top: 1px solid color(promotions-card, block-background);

            a {
              &.active, &:hover {
                color: inherit;
              }

            }
          }
          .settings-menu__item-override:last-of-type {
            border-bottom: initial;
            box-shadow: initial;
          }
        }
      }
      .detailsSummary-content {
        margin: 0;
        padding: 5px 5px 5px 14px;

        &:hover {
          color: color(primary, main);
        }
      }
      &.details-expanded {
        .detailsSummary-content {
          &::after {
            transform: rotateX(180deg)
          }
        }
      }
    }
    .settings-menu {
      width: 100%;
    }
    > a {
      z-index: 2;
      color: inherit;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 0;
        transition: border $main-transition-time;
      }
      &.active,
      &:hover {
        color: color(primary, main) !important;

        &::before {
          color: color(primary, main);
          transition: color $main-transition-time;
        }
        &::after {
          z-index: -1;
        }
      }
      &.active {
        &::after {
          width: calc(100% + 7px);
        }
      }
    }
    &--limits, &--loyalty, &--time-out {
      a {
        margin: 0;
        padding: 5px 5px 5px 14px;
        text-transform: uppercase;
        &:hover {
          color: color(primary, main);
        }
      }
    }
    &--logout {
      padding: 5px 5px 5px 14px;

      a {
        color: var(--game-status-loss);
      }
    }
  }

  a {
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none;
  }
}

/* Content */

.post-login-content-wrap {

  &--profile-account {
    .profile-account-notifications {
      .notification-root {
        margin-bottom: 15px;
        animation: none;
      }
    }

    .list-details {
      @include media-xs {
        background: var(--input-background);
        border-radius: 5px;
        color: color(primary, main);
        padding: 10px 10px 10px 10px;
        text-transform: uppercase;

        dd {
          margin: 0 0 10px 0;
          font-weight: bold;
        }

        dd:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &--game, &--deposit, &--withdrawal {
    .account__title {
      margin: 14px 0 20px;
    }
  }

  &--account-validation,
  &--sof-validation {
    display: flex;
  }
  &:not(&--account-validation):not(&--sof-validation):not(&--limits):not(&--documents):not(&--reset-password) {
    width: calc(100% - 250px);
    flex-basis: calc(100% - 250px);

    @include media-xs {
      width: 100%;
      flex-basis: 100%;
    }
  }
  &--profile {
    border-radius: 5px;
  }
  &--change-password {
    h1 {
      width: initial !important;
    }

    .change-password__notifications {
      > div {
        margin-bottom: 10px;
        animation: 0;

        &:empty {
          margin-bottom: 0;
        }
      }
    }
  }
  &_transactions {
    .post-login-content {
      padding: 0;
    }
  }

  &--current-bonuses {
    .bonus {
      &-info {
        border-bottom: 1px solid color(select, background);
        margin-bottom: 22px;
        padding-bottom: 25px;

        label {
          position: relative;
          font-size: 14px;
          text-transform: uppercase;
        }

        .group-root {
          > div {
            height: 40px;
            line-height: 1.5;
          }
        }

        .circularProgress-root {
          width: 120px;
          height: 120px;

          .circularProgress {
            &-back {
              stroke-width: 1px;
            }

            &-path {
              stroke-width: 2px;
            }

            &-content {
              text-align: center;
              flex-direction: column;

              div {
                font-size: 12px;
              }
            }
          }
        }

        @include custom-hint;

        div {
          font-size: 24px;
        }

        .linearProgress-line {
          border-radius: 4px;
          height: 17px;
        }
      }

      &-row {

        .notification-root {
          color: color(select, background);
          padding: 19px 29px;

          .notification-message {
            width: 100%;
          }

          p {
            font-size: 16px;
            text-align: center;
          }
        }

        h3 {
          font-size: 14px;
          letter-spacing: 0.5px;
          margin: 14px 0;
          line-height: 1;
          text-transform: uppercase;
        }

        .table-root {
          th, td {
            text-transform: none;
            padding: 8px;
            font-size: 12px;
            text-align: left;
            color: #666;

            a {
              text-decoration: underline;

              &:hover {
                text-decoration: none;
              }
            }

            button {
              min-width: auto;
              min-height: auto;
              padding: 5px;
            }
          }
        }
      }

      &-forfeit {
        border-top: 1px solid color(select, background);
        margin-top: 22px;
        padding-top: 25px;

        button {
          margin: 20px 0;
        }

         > p, .markdown-root p {
          margin: 0;
        }


      }

      &-buttons {
        margin-top: 20px;
        text-align: center;

        a {
          margin-right: 10px;
        }
      }
    }

    .currency {
      font-size: 8px;
      text-transform: uppercase;
    }

    @include media-xs {
      dt {
        font-weight: bold;
      }

      dl, dd {
        color: var(--file-attachments-border);
        margin: 0;
      }
    }
  }
}

.forfeit-modal {
  @include media-xs {
    .modal-paper .modalContentWrapper {
      background-color: color(footer, background);
      margin: 10px;
      color: color(modal, text);

      div.modal-titleRoot {
        background: none;
        border-bottom: 0;
        margin-bottom: 2px;
        box-shadow: 0 1px 0 0 color(notice, hr), 0 2px 0 0 color(notice, background);

        h2 {
          margin-top: 0;
        }
      }

      div.modal-contentRoot {
        > div.grid-root {
          padding: 0;
        }
      }

      .confirmation-modal__buttons {
        border-top: 0;
        margin-top: 2px;
        box-shadow: 0 -1px 0 0 color(notice, hr), 0 -2px 0 0 color(notice, background);
        padding-top: 14px;
      }
    }
  }
}

.post-login-content {
  flex-direction: column;

  > .container {
    padding: 0;

    @include media-xs {
      padding: 0 15px;
    }
  }

  @include media-xs {
    margin-bottom: 20px;
  }

  .adornment-password {
    right: 30px;
  }

  .activity-form {

    .gridItem-root {
      width: 100%;

      .custom-table-head .custom-table-cell {
        text-transform: none;
        font-weight: bold;
      }

      .custom-table-cell {
        text-align: left;
        font-size: 12px;
        padding: 15px;
      }

      .custom-table-body {
        .custom-table-row:nth-child(odd) > .custom-table-cell {
          background: #EAEAEA;
        }
      }
    }
  }

  h1 {
    text-align: left;
    font-size: 24px;
    line-height: 1;
    margin: 0 0 14px 0;
    padding: 0;
    color: color(primary, main);

    @include media-xs {
      padding: 11px 10px 9px;
      background: color(popup, summary-background);
      color: color(main-menu, link-hover);
      font-size: 14px;
      font-weight: 300;
      line-height: 16px;
      text-transform: uppercase;
      letter-spacing: .5px;
      margin: -10px -8px 12px;
    }
  }

  .select-root .select-selectMenu {
    line-height: 1.5;
  }

  label {
    span {
      color: color(label, unfilled);
    }
  }

  .fields-container {
    margin: 0 0 0 -10px;

    @include media-xs {
      width: calc(100% + 40px);
      margin: 0 -20px;
    }
    > .gridItem-root {
      padding: 0 10px;
      max-width: 35%;
      min-width: 290px;

      @include media-xs {
        max-width: 100%;
      }

      .select-searchInput::before, .select-searchInput::after {
        content: none;
        background: none;
      }

      @include media-xs {
        padding: 0 20px;
      }
    }
  }
  .item {
    background-color: var(--post-login-content-background);
  }
}
.button-wrapper {
  color: color(label, unfilled);

  p {
    margin: 0;
  };

  @include media-xs {
    text-align: center;
  }

  .button-root {
    margin: 20px 0;

    @include media-xs {
      margin: 4px 0 0 0;
      min-width: auto;
    }
  }
}
.desktop #root[data-url="/my/limits"],
.desktop #root[data-url="/my/time-out"] {
  .post-login-content .post-login__title {
    display: none;

    @include media-xs {
      display: flex;
    }
  }
}
