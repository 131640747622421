.typography {
  font-size: 12px;

  @include media-xs {
    .terms-of-service {
      padding: 0 15px;
    }

    h2 {
      width: 100vw;
      height: 56px;
      font-size: 14px;
      letter-spacing: 1.2px;
      line-height: 18px;
      font-weight: 400;
      margin-top: 0;
      justify-content: center;
      align-items: center;
      margin-left: -15px;
      text-align: center;
      color: color(primary, main);
      background: color(secondary, dark);
      text-transform: uppercase;
      margin-bottom: 20px;
      display: none;

      @include media-xs {
        display: flex;
      }
    }
  }

  h1, h2 {
    position: relative;
    margin: 20px 0 44px;
    font-size: 30px;
    letter-spacing: .05em;
  }
  .underlined-title {
    h1 {
      display: inline-block;

      &::before {
        content: '';
        position: absolute;
        bottom: -16px;
        left: 0;
        width: 100%;
        height: 6px;
        background-color: color(primary, main);
      }
      @include media-xs {
        font-size: 22px;
        margin-bottom: 14px;
      }
    }
    + .border {
      margin-top: 20px;
    }
  }
  h3 {
    color: color(primary, main);
    font-size: 25px;
    font-weight: $font-weight-base;
    letter-spacing: 0.5px;
    margin: 14px 0;

    @include media-xs {
      font-size: 14px;
    }
  }
  p {
    font-size: 12px;
    line-height: 1.5;
  }
  ul,
  ol {
    list-style-type: disc;

    li {
      margin-bottom: 4px;
      position: relative;
      font-size: 12px;
    }
  }
  img {
    max-width: 100%;
  }
  .border {
    padding: 20px 50px;
    border: 1px solid var(--body-text-dark-color);

    @include media-sm {
      padding: 10px 20px;
    }
  }
}
