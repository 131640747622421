.deposit-form {

  &::after {
    @include svg-icon('custom');
    content: '\0051';
    position: absolute;
    font-size: 550px;
    right: -50px;
    bottom: -50px;
    z-index: -1;
    opacity: 0.03;

    @include media-xs {
      content: none;
    }
  }

  .depositThanks {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 200;

    @include media-xs {
      position: fixed;
    }

    div {
      position: relative;
      background: color(primary, main);
      width: 400px;
      height: 247px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      color: #000000;
      font-weight: 600;
      font-size: 25px;

      @include media-xs {
        width: 300px;
        height: 185px;
        font-size: 20px;
      }
    }

    &__info {
      color: color(success);
      text-align: center;
      text-transform: uppercase;
      font-size: 16px;

      @include media-xs {
        margin: 40px 0 20px;
        padding: 10px 15px;
        font-weight: 700;
        font-size: 14px;
      }
    }

    &__details {
      color: var(--file-attachments-border);
      font-weight: 500;
      text-transform: initial;

      @include media-xs {
        color: color(success);
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
      }

      p {
        margin: 0;

        strong {
          color: #000;
          font-weight: normal;
        }
      }
    }

    &__continue-button {
      display: block;
      margin: 10px auto;
    }

    &__close {
      display: block;
      position: absolute;
      top: 5px;
      right: 5px;
      width: 15px;
      height: 15px;
      background: #decf89;
      border-radius: 50%;
      outline: 0;
      opacity: 1;
      cursor: pointer;

      &::before {
        content: 'X';
        position: absolute;
        color: color(primary, main);
        top: 1px;
        left: 50%;
        font-size: 10px;
        font-weight: 800;
        transform: translateX(-50%);
      }
    }
  }

  label.field-label, label {
    @include media-xs {
      display: block;
      margin-bottom: 5px;
      font-size: 12px;
      padding: 0;
    }
  }

  h3 {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    color: color(primary, main);
    margin-top: 2px;
    margin-bottom: 28px;
    text-transform: none;
    min-width: 580px;
    line-height: 1;
    letter-spacing: 0.5px;
  }

  .button-wrapper {
    text-align: center;

    @include media-xs {
      margin-bottom: 30px;

      .button-root {
        margin: 0;
      }
    }
  }

  @include media-xs {
    .select-root {

      .select-select {
        align-items: center;
      }
    }
  }

  &__deposit {
    display: flex;
    border-radius: 5px;
    border: 1px solid color(settings-menu, border);
    width: 734px;
    margin: 0 auto;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
      width: 3px;
    }

    ::-webkit-scrollbar-thumb {
      background: color(settings-menu, border);
      border-radius: 3px;
    }

    @include media-xs {
      flex-direction: column;
      width: 100%;
      gap: 12px 0;
      border: none;
      padding: 15px;
    }

    &--short-header {
      display: flex;
      align-items: center;
      margin: -5px 0 15px 0;

      img {
        width: auto;
        margin-right: 10px;
        opacity: 1;
      }
    }

    &--method, &--amount-info {
      padding: 25px;

      @include media-xs {
        padding: 0;
      }
    }

    &--amount-info {
      width: 100%;

      .amount-notification {
        border: 1px solid var(--game-status-loss);
        font-size: 16px;
        text-align: center;
        padding: 10px 15px;
        color: var(--game-status-loss);
        margin-bottom: 20px;
        border-radius: 3px;
      }
    }

    &--method {
      width: 70%;

      label {
        cursor: pointer;
      }
    }

    &--info {
      font-size: 12px;
      font-weight: initial;
      color: color(secondary, dark)
    }

    &--amount-info {
      .method-description {
        color: #868686;
        background-color: color(input, background);
        padding: 19px 29px;
        margin-bottom: 12px;

        &-10000 {
          p {
            margin: 0;
          }
        }

        &-10003 {
          padding: 0;
          background: none;
          color: color(secondary, dark);

          ul {
            padding-left: 15px;

            li {
              margin-bottom: 15px;

              &::marker {
                color: var(--file-attachments-border);
              }

              p {
                margin: 0;
              }

              a {
                font-size: 10px;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    &--amount {
      margin-bottom: 8px;

      .field-helper:empty {
        font-size: 0;
        margin-top: 0;

        @include media-xs {
          font-size: inherit;
          margin-top: inherit;
        }
      }

      @include media-xs {
        display: flex;
        flex-direction: column-reverse;
        border: none;
        margin-bottom: 0;
        gap: 12px;
      }

      .selector-amount {
        display: flex;
        color: color(primary, main);
        background-color: color(background, body);

        label {
          display: flex;
          width: 100%;
          margin: 15px 15px 15px 0;
          padding: 11px 14px 8px;
          border: 1px solid color(primary, main);
          border-radius: 10px 3px;
          text-align: center;
          color: color(primary, main);
          cursor: pointer;
          justify-content: center;
          align-items: center;

          &:hover {
            color: color(secondary, main);
            border: 1px solid color(secondary, main);
          }
        }

        .radio-default {
          display: none;
        }

      }

      .field-root {

        @include media-xs {
          flex-direction: column;
          background: none;
          padding: 0;
          align-items: normal;
        }

        .field-helperError {
          padding-left: 5px;
        }

        label {
          padding: 0;
          width: 100%;
        }

        .input-root {
          width: 100%;
          padding-left: 44px;

          .currency {
            border-radius: 3px 0 0 3px;
            height: 40px;
            width: 44px;
            text-align: center;
            position: absolute;
            left: 0;
            text-transform: uppercase;
            font-size: 12px;
            line-height: 40px;
            padding:0 10px;
            color: color(input, background);
            background: #868686;
          }
        }
      }
    }

    &--method {
      display: flex;
      flex-direction: column;
      overflow: auto;
      gap: 15px 0;

      @include media-xs {
        height: auto;
        width: auto;

        .field-root .field-helper {
          font-size: 0;
        }
      }

      label {
        display: flex;
        align-items: center;
        height: 34px;

        @include media-xs {
          height: auto;
        }

        .radio-default {
          width: auto;
          height: 34px;
          margin-right: 15px;

          svg {
            color: color(secondary, dark);
            border-radius: 50%;
            width: 17px;
            height: 17px;
          }
        }

        .radio-checked + img {
          opacity: 1;
        }

        .radio-checked {

          .custom-checkbox {
            width: 17px;
            height: 17px;
            background: #fff;
            border-radius: 50%;
            position: relative;
            border: 1px solid color(secondary, dark);
          }

          .custom-checkbox::after {
            content: '';
            width: 9px;
            height: 9px;
            background: color(secondary, dark);
            display: block;
            border-radius: 50%;
            position: absolute;
            top: 3px;
            left: 3px;
          }
        }

        .radio-checked + img + span.payment-method-field__info {
          opacity: 1;
        }

        .radio-checked + img {
          filter: brightness(0%) saturate(0%) contrast(70%);
        }
      }
    }
  }

  &__title {
    display: inline-block;
    margin: 25px 0 10px;
    padding-bottom: 2px;
    font-size: 16px;
    font-weight: $font-weight-alt;
    border-bottom: 6px solid color(primary, main);

    p {
      margin: 0;
    }
    &--method {
      margin-top: 0;
    }
  }
  &__sub-title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0 5px;
    padding-bottom: 5px;
    font-size: 16px;
    border-bottom: 2px solid color(primary, main);

    strong {
      font-size: 20px;
    }
    .exchange {
      display: block;
      text-align: right;
    }
    p {
      margin: 0;
      font-weight: $font-weight-alt;
    }
  }
  &__notifications {
    max-width: 734px;
    margin: 0 auto 30px auto;

    @include media-xs {
      padding: 15px;
      margin: 0
    }
    .notification {
      &-root {
        margin-bottom: 10px;
      }
      &-warning {
        background: none;
        border: 1px solid color(attention);
        color: color(secondary, dark);
        text-align: center;
        padding: 19px 29px;

        @include media-xs {
          border: none;
          padding: 10px 0;
        }

        .markdown-root {
          margin-bottom: 15px;

          @include media-xs {
            margin-bottom: 0;
          }
        }
        p {
          font-size: 14px;
          font-weight: 600;
        }
        a {
          @include media(null, $size-xs) {
            color: color(secondary, dark);
          }
        }
        strong {
          color: color(attention);
        }
      }
      &-message {
        width: 100%;
      }
    }
  }
  .content-deposit {
    background: url('/cms/img/promotions/star.svg') no-repeat;
    background-position-x: -150px;
    height: 100%;
    background-size: 50%;

    &__notifications {
      margin-left: -28px;
      margin-top: -30px;
      max-width: calc(100% + 56px);
      width: calc(100% + 56px);

      .notification {
        &-root {
          padding: 19px 29px;
          justify-content: center;
          font-size: 16px;
          color: color(select, background);

          p {
            font-size: 16px;
            color: color(select, background);
          }
        }

        &-message {
          width: 100%;
          text-align: center;
        }
      }
  }

    &:not(:empty) {
      margin-bottom: 20px;
    }

    .notification-root {
      &:not(:last-child) {
        margin-bottom: 10px !important;
      }
    }
  }
  &__ukgc-message {
    text-align: justify;
  }
  &__fields-title {
    p {
      margin: 0;
    }
  }
  &__fields {

    .gridItem-root[class*='deposit-form__field--']:not(:only-child) {
      max-width: 50%;
      flex-basis: 50%;
      margin: 0 -5px;
      padding: 0 5px;
    }
    .gridItem-root[class*='deposit-form__field--customer_cc_number']:not(:only-child) {
      @include media-sm {
        max-width: 100%;
        flex-basis: 100%;
        margin: 0;
        padding: 0;
      }
    }
  }

  &__declarations-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
  }

  &__declarations-actions {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }
}
.customize-decorator__wrapper.content-deposit {
  .customize-decorator--content {
    min-height: calc(100vh - 72px);
    padding-bottom: 0;
  }
}
#root[data-url="/payment/deposit"] .main-decorator {
  .main-decorator__content {
    position: relative;

    .button-root {
      margin-bottom: 0;
    }
  }
}
.deposit-form__frame,
.resume-form__frame {
  width: 100%;
  height: 90vh;
  border: none;

  // cubits
  &.deposit-form__frame_provider_410004 {
    margin: 5px 0;
    height: 560px;
  }
  // voucher
  &.deposit-form__frame_provider_150001 {
    display: none;

    & + .deposit-step-back {
      display: none !important;
    }
  }
  // trustly
  &_provider_300001,
  &_provider_300002 {
    height: 600px;

    .tablet &,
    .mobile & {
      background-color: color(primary, light);
    }
  }

  // zimpler
  &_provider_440001 {
    height: 580px;

    .tablet &,
    .mobile & {
      height: 500px;
    }
  }

  &--authentication {
    display: none;

    &_visible {
      height: 100%;
      width: 100%;
      border: none;
      min-height: 400px;
    }
  }
}
.deposit-form__frame {
  display: block;
  height: calc(90vh - 140px);
  @include media-sm {
    min-height: calc(100vh - 155px);
    max-height: calc(100vh - 155px);
  }

  .applied-country--gbr & {
    height: calc(90vh - 190px);
    @include media-sm {
      min-height: calc(100vh - 190px);
      max-height: calc(100vh - 190px);
    }
  }

  &.loading {
    height: 0 !important;
    min-height: 0 !important;
  }
  & + .deposit-form__circular-progress {
    display: none;
    margin-left: 50%;
    transform: translate(-50%, 0);
  }
  &.loading + .deposit-form__circular-progress {
    display: block;
  }
}

.payment-select-field,
.payment-method-field,
.deposit-form__field--bank_issuer_id {
  .select-select {
    display: inline-flex;
    width: 100%;

    span {
      display: inline-block;
    }
  }
}
.payment-method-field__img,
img.payment-select-field__img {
  width: 70px;
  max-height: 30px;
  margin-right: 5px;
  object-fit: contain;
  filter: brightness(0%) saturate(0%) contrast(70%);
  opacity: 0.5;
}
.payment-method-field__info {
  margin-left: 30px;
  display: block;
  font-size: 10px;
  font-style: italic;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 0.5;
}
div.payment-select-field__img {
  margin-right: 5px;
}
.payment-method-field__img-country {
  width: auto;
  max-height: 30px;
  margin-right: 5px;
}
.deposit-summary {
  &__bonuses-title {
    p {
      margin-bottom: 0;
      line-height: 1;
    }
  }
  &__turnover-title {
    p {
      margin: 0;
    }
    em {
      font-size: 12px;
      font-style: normal;
      color: color(input, hint);
    }
  }
  &__deduct,
  &__receive {
    max-width: 45%;

    @include media-xs {
      max-width: 100%;
    }
    .group-root {
      p {
        margin: 0;
        line-height: 2;
        border-bottom: 1px solid #ccc;
      }
      strong {
        float: right;
      }
    }
    .exchange {
      display: block;
      text-align: right;
    }
  }
  &__fee {
    margin-top: 30px;
    font-size: 13px;
    color: color(text, light);

    @include media-xs {
      margin: 10px 0 20px;
    }
    &::before {
      @include svg-icon;
      content: '\0044';
      padding-right: 5px;
      vertical-align: top;
      color: color(warning);
    }
  }
}
.deposit-limit-confirmation {
  margin-top: 30px;
  text-align: center;
}
.deposit-step-back,
.resume-step-back {
  position: absolute;
  top: 40px;
  left: 80px;
  display: flex;
  min-width: 0;
  min-height: 0;
  padding: 0;
  font-size: 14px;
  border: 0;
  background: none;
  overflow: hidden;
  transition: all 0.2s;

  @include media-sm {
    left: 15px;
  }
  .button-label {
    transform: translateY(-100%);
    transition: transform .2s;
  }
  &::before {
    @include svg-icon;
    content: '\0062';
    margin-right: 5px;
    font-size: 15px;
    transform: rotate(180deg);
  }
  &:hover {
    color: color(secondary, main);
    background: none;

    .button-label {
      transform: translateY(0);
    }
  }
}
.supported-currency-field .field-helperHint,
.exchange {
  font-size: 12px;
  color: color(input, hint);
}

/** ePro */
.deposit-form--370001 {
  @media (min-width: 480px) {
    .deposit-form__field {
      &--customer_cc_name,
      &--customer_cc_surname {
        max-width: 45%;
        flex-basis: 45%;
      }
      &--customer_cc_expmo,
      &--customer_cc_expyr,
      &--customer_cc_cvc {
        max-width: 30%;
        flex-basis: 30%;
      }
    }
  }
}
/* **/

/** Neteller */
.deposit-form--110002 {
  @media (min-width: 480px) {
    .deposit-form__field {
      &--netellerAccountId {
        max-width: 65%;
        flex-basis: 65%;
      }
      &--secureId {
        max-width: 25%;
        flex-basis: 25%;
      }
    }
  }
}
/* **/

// Cryptopay
.deposit-form--910002,
.deposit-form--910003 {
  display: flex;
  flex-direction: column;
  flex: none;
  height: auto;

  .field-payAmount--910002,
  .field-address--910002 {
    align-self: flex-end;
    width: 70%;

    @include media-sm {
      order: 2;
      width: 100%;
      margin-top: 0;
    }
  }
  .field-payAmount--910002,
  .field-address--910002,
  .field-address--910003 {
    input[type="text"] {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .wrapper {
      display: flex;
    }
    .input-root {
      flex: 1;
    }
    .button-default {
      margin-top: 20px;
      margin-bottom: 5px;
      color: color(secondary, main);

      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        pointer-events: none;
        background: color(select, text);
      }
    }
    .button-default::after {
      display: none;
    }
  }
  .field-uri--910002 {
    position: absolute;
    width: inherit;
    margin-top: 155px;

    @include media-sm {
      position: relative;
      order: 1;
      margin-top: 0;
    }
    label {
      display: none;
    }
    .button-primary {
      margin-top: 10px;
    }
  }
  .field-expiresAt--910002 {
    padding-top: 35px;

    @include media-sm {
      order: 3;
      padding-top: 0;
    }
    .expired::before {
      background: color(error);
    }
    label {
      right: 0;
      text-align: center;
    }
    input[type="text"] {
      text-align: center;
    }
  }
}

.deposit-form--910002 {
  .notification-root {
    margin-bottom: 8px;
    animation: none;
  }
}

.field-qrCode--950001 {
  label {
    display: none;
  }
  .button-primary {
    margin-top: 10px;
  }
}

// OXXOPAY, SendAbono
.deposit-form--400002,
.deposit-form--1210001,
.deposit-form--1290001 {
  flex-direction: column;

  .field-reference--400002,
  .field-stpAbonosAccountNumber--1210001,
  .field-reference--1290001 {
    input[type="text"] {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .wrapper {
      display: flex;
    }
    .input-root {
      flex: 1;
    }
    .button-default {
      margin-top: 20px;
      margin-bottom: 5px;
      color: color(secondary, main);

      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        pointer-events: none;
        background: color(select, text);
      }
    }
    .button-default::after {
      display: none;
    }
  }
}

// Sr.Pago
.deposit-form--1010001 {
  > iframe + .deposit-form__circular-progress {
    display: block;
  }
}

.deposit-form__applePayButton  {
  button {
    width: 156px;
    height: 42px;
    border-radius: 0;
    cursor: pointer;
    margin: 20px 0;
  }
  div[role="progressbar"] {
    margin: 20px 0;
  }
}



