.forgot-password {
  &-modal {
    .modal-paper {
      max-width: 600px;
    }

    .field-root {
      .input-root {
        max-width: 50%;
      }

      .field-helperError {
        grid-column: 2;
        grid-row: 2;
      }
    }
  }
  &__submit {
    margin-top: 8px;
  }
}
