$header-underline-height: 7px;

@mixin button-mobile {
  width: 35px;
  height: 35px;
  font-size: 0;
  background: none;
  padding: 0;
}
.header {
  padding-bottom: $header-underline-height;

  &__promotions,
  &__loyalty-program,
  .balance__row.balance__loyalty,
  .settings-menu__item-override--loyalty,
  .settings-menu__item-override--loyalty-program,
  .settings-menu__item-override--promotions,
  &__vegas-tv,
  .settings-menu__item-override--live-studio {
    display: none;
  }

  &::after {
    width: 280px;
  }

  @include media-md {
    &::after {
      width: 80%;
    }
  }

  @media (min-width: 1280px) {
    &::after {
      width: calc(30% + 5px);
    }
  }

  @include media-xs {
    position: sticky;
    z-index: 100;
    background: color(primary, light);
    width: 100%;
    top: 0;
    padding-bottom: 0;
  }

  .header-link {
    padding: 0 12px;
    margin-left: 18px;
    font-size: 13px;
  }
  .button-accent {
    min-width: 0;
    padding: 10px 23px;
  }
  & .button-default {
    padding: 10px 23px;
  }
  @include media-xs-landscape {
    .header__login {
      top: 4px;
    }
  }
  @include media(600px) {
    .header__login {
      top: 0;
    }
  }
  &__container {
    display: flex;
    justify-content: space-between;
    min-height: calc(70px + #{$header-underline-height});
    margin: initial;
    padding: 0;
    max-width: initial;

    @include media(null, $size-md) {
      max-width: 1280px;
      margin: 0 auto;
    }

    @include media-md {
      flex-direction: column-reverse;
    }
    @include media-xs-landscape {
      min-height: initial;
    }
    @include media-xs {
      min-height: auto;
    }
  }
  &__group-left {
    position: relative;
    display: flex;

    @include media-md {
      padding: 0 20px;
      align-items: center;
    }

    @include media(null, $size-md) {
      order: 2;
    }

    .header-link {
      transition: all 0.5s;

      &:hover {
        color: color(primary, main);
        background: none;
      }
      &:active {
        color: color(primary, main);
      }
      @include media-md {
        display: none;
      }
    }

    .header-menu-page-content {
      & .static-content,
      & .static-header-menu,
      & .header-link {
        height: 100%;
      }
      .static-header-menu,
      .header-link {
        display: flex;
      }
      .header-link {
        align-items: center;

        @include media-md {
          display: none;
        }
      }
    }

    &::after {
      content: '';
      position: absolute;
      height: $header-underline-height;
      bottom: -$header-underline-height;
      right: 0;
      left: -10000px;
      background: color(primary, main);
      border-radius: 0 5px 0 0;
      display: block;

      @include media-md {
        left: 0;
        right: initial;
        width: 80%;
      }

      @include media-xs {
        content: none;
      }
    }
  }
  &__group-right {
    @include media(null, $size-md) {
      order: 1;
    }
  }
  &__group-right {
    display: flex;
    align-items: center;
    padding: 0 15px;
    gap: 10px;

    @include media-md {
      justify-content: right;
      background: color(input, background);
      padding: 0;
    }

    @include media(null, $size-md) {
      order: 3;
    }
    @include media-xs-landscape {
      display: none;
    }
    @include media-xs {
      padding: 0;
    }
  }
  .button-root {
    line-height: 100%;
    min-height: 38px;
    font-size: 13px;
  }

  &__registration {
    @include media-xs {
      display: none;
    }
  }
  @include media-xs {
    .header__login {
      @include button-mobile;
      min-width: auto;

      .button-label:before {
        @include svg-icon('custom');
        font-size: 20px;
        color: color(primary, main);
      }
    }
    .header__login {
      .button-label:before {
        content: '\004d';
      }
    }
  }
  &__logo {
    display: flex;
    height: 100%;
    min-width: 150px;
    min-height: 50px;
    background-image: url('/cms/img/logo.svg');
    background-position: center center;
    background-repeat: no-repeat;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    margin: 0 25px 0 15px;
    background-size: 150px 50px;

    @include  media-md {
      width: 100%;
      margin: 0;
    }
  }
  & &__menu {
    @include button-mobile;
    display: none;

    .button-label::before {
      @include svg-icon('custom');
      font-size: 24px;
      color: color(primary, main);
      content: '\004a';
    }

    @include media-md {
      display: block;
      min-width: auto;
    }
  }
  & &__casino {
    margin-right: auto;
    padding-left: 0;

    .button-label::before {
      content: '\005e';
    }

    &--live {
      .button-label::before {
        content: '\005d';
      }
    }
  }
  & &__verification {
    margin-right: auto;
    padding-left: 0;

    .button-label::before {
      content: '\0061';
      color: color(error);
    }
  }
  & &__deposit {
    @include media-md {
      display: none;
    }
  }
  & &__withdraw {
    @media (max-width: 1250px) {
      & {
        display: none;
      }
    }
  }
  .balance__button {
    padding: 0;
    white-space: nowrap;
    font-size: 12px;
    transition: all 0.5s;
    text-transform: none;

    @include media-md {
      margin: 0 auto;
    }

    &:hover {
      color: color(primary, main);
      background: none;

      @include media-xs {
        color: inherit;
      }
    }

    .caret {
      height: 40px;
      width: 34px;

      &::after {
        @include svg-icon('custom');
        display: flex;
        justify-content: center;
        align-items: center;
        content:'\0042';
        height: 100%;
        width: 100%;
        color: color(primary, main);
        transition: all 0.5s;
      }
    }

    &.open {
      .caret {
        &::after {
          transform: rotate(180deg);
        }
      }
    }

    .button-label::before {
      display: none;
    }

    .balance__button {
      &--nick-name {
        padding: 10px;
      }
      &--balance,
      &--free-spins,
      &--award-points {
        position: relative;
        padding: 10px 10px 10px 25px;

        &::before {
          position: absolute;
          @include svg-icon('custom');
          top: 10px;
          left: 0;
          font-size: 18px;
          color: var(--file-attachments-border);
        }
      }
      &--balance {
        padding-left: 14px;

        @include media-xs {
          padding-left: 25px;

          &::before {
            content: '\0050';
          }
        }
      }
      &--free-spins {
        &::before {
          content: '\004b';
        }
      }
      &--award-points {
        &::before {
          content:'\004c';
        }
      }
    }
  }
  &__icon-links-group {
    display: flex;
    justify-content: center;
    margin-left: auto;
    padding-left: 18px;

    .button-root {
      padding-right: 5px;
      padding-left: 5px;
    }
    @include media-xs {
      display: none;
    }
  }
  & &__promotions--icon .button-label::before {
    content: '\0056';
    margin-top: -7px;
  }
  & &__free-spins--icon .button-label::before {
    content: '\006a';
  }
  & &__tournaments {
    margin-left: auto;

    @include media-md {
      padding-right: 0;
    }

    .button-label::before {
      content: '\006e';
    }
  }

  &__timer {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    transition: all .3s;

    &-area {
      padding: 8px 0;
      color: color(primary, light);

      &-flex{
        display: flex;
        flex-direction: column;
        align-items: center;

        & .session-time{
          font-size: 12px;
          color: color(text, main);
        }
      }

      .is-logged-in & {
        cursor: help;
      }
    }

    .session-time__title {
      display: inline-block;
      font-size: inherit;
      color: color(text, main);
      margin-right: 4px;

      &:first-letter {
        text-transform: uppercase;
      }

      &:after {
        content: ':';
      }
    }
  }
  &__or {
    display: flex;
    align-items: center;

    @include media-xs {
      display: none;
    }
  }
  @include media-sm {
    & &__casino,
    & &__tournaments,
    &__verification,
    &__promotions {
      .button-label {
        font-size: 0;

        span {
          font-size: 0;
        }
        &::before {
          font-size: 20px;
        }
      }
    }
  }
}
