.games-filter__favourite {
  width: 200px;
  margin: 10px;

  @include media-xs {
    width: 100%;
    margin: 5px 0;
  }

  .favourite-game-filter__button {
    justify-content: left;
    flex-direction: row-reverse;
    position: relative;
    width: 100%;
    padding: 10px 15px;
    text-transform: none;
    font-size: 16px;
    color: var(--file-attachments-border);

    &::before {
      @include svg-icon('custom');
      position: absolute;
      right: 10px;
      content: '\0051';
      font-size: 15px;
      padding-left: 5px;

      @include media-xs {
        font-size: 12px;
        padding-left: 0;
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 2.5%;
      width: 95%;
      border-top: 1px solid color(primary, main);;
      transform: inherit;
      background: none;

      @include media-xs {
        display: none;
      }
    }

    &:hover {
      background: none;

      @include media-xs {
        background: var(--home-menu-background-mobile);
      }
    }

    @include media-xs {
      background: var(--home-menu-background-mobile);
      border-radius: 3px;
      font-size: 12px;
    }

    .button-label + span {
      display: none;
    }

    &--favourite-on {
      &::before {
        color: color(primary, main);
      }
    }

    &--favourite-off {
      &::before {
        color: var(--file-attachments-border);
      }
    }
  }
}

