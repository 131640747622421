.playerDocument {
  &__image-wrapper {
    height: 111px;
    width: 177px;
    margin: 10px;

    @include media-xs {
      height: auto;
      width: 100%;
      margin: 0 0 15px;
    }

    .image {
      @include size(100%);
    }
  }
  &__image-container {
    @include size(100%);
    position: relative;
    border-radius: 5px;
    overflow: hidden;
  }
  &__image {
    @include size(100%);

    img {
      @include media-xs {
        width: 100%;
      }
    }
  }
  &__view-more {
    position: absolute;
    @include size(100%);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    color: color(primary, main);
    margin: 0;
    font-size: 12px;
    border-radius: 0;
    text-transform: initial;
    background-color: rgba(100%, 100%, 100%, 0.75);
    cursor: pointer;

    .button-label {
      width: auto;
    }

    .button-label + span {
      display: none;
    }

    &:hover {
      background-color: rgba(100%, 100%, 100%, 0.75);
      color: color(secondary, main);
    }
  }
  &__error {
    display: flex;
    justify-content: center;
    align-items: center;
    @include size(100%);
    background: color(error);
    border-radius: 5px;
    color: color(primary, light);

    @include media-xs {
      min-height: 150px;
    }
  }
}
