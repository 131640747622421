.playerVerificationStep3 {
  .playerVerificationForm3Amatic {
    max-width: 722px;
    margin: 0 auto;

    &__fields {

      gap: 0 10px;

     .gridItem-root {
        flex: 1 1 340px
      }
    }
  }
}
