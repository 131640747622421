.other-documents-form {
  max-width: 480px;
  margin: 0 auto;
  padding-bottom: 30px;

  @include media-xs {
    padding: 20px;
  }

  &__take-photo-button,
  &__upload-image-button {
    .button-label::before {
      @include svg-icon('custom');
      position: relative;
      font-size: 12px;
      padding-right: 10px;
    }
  }

  &__take-photo-button .button-label::before {
    content: '\0063';
  }

  &__upload-image-button .button-label::before {
    content: '\0062';
  }

  .iban-form {
    &--title {
      width: 100%;
      font-weight: 400;
      font-size: 24px;
      line-height: 32.74px;
      text-align: center;
    }

    &--hint,
    &--hint-2,
    &--hint-3 {
      font-size: 16px;
    }
  }

  &__buttons {
    text-align: center;
    width: 100%;
    margin-top: 15px;

    button {
      font-size: 16px;
    }

    &--upload-image {
      display: flex;
      justify-content: center;
      margin-bottom: 15px;
      gap: 15px;

      @include media-xs {
        flex-direction: column;
        align-items: center;
      }

      .button-accent {
        font-size: 16px;
        height: 46px;
        padding: 7px 50px;
        width: max-content;

        @include media-xs {
          margin-bottom: 10px;
        }
      }
    }

    &--steps {
      display: flex;
      gap: 2em;
      justify-content: center;
    }
  }

  .iban-form-form--1011 .field-input label {
    font-size: 16px;
    font-weight: 700;
  }

  div[class*="profile-account-form__field"] {
    margin-bottom: 10px;
  }

  &__image {
    &--wrapper {
      position: relative;
      height: 168px;
      width: fit-content;
      border-radius: 8px;
      overflow: hidden;
      margin: 0 auto 15px;
    }
    &--preview {
      height: 100%;
      width: 100%;

      img {
        height: 100%;
        width: auto;
      }
    }
  }

  &__remove-image-button {
    position: absolute;
    right: 12px;
    top: 12px;
    padding: 7px;
    font-size: initial;

    &::before {
      @include svg-icon('custom');
      content: '\0064';
      position: relative;
      font-size: initial;
      color: color(error);
    }

    &:hover {
      background-color: transparent;
    }
  }

  &__close-button {
    font-size: 16px;
  }
}
