.post-login-content-wrap--limits {
  width: calc(100% - 250px);

  h2 {
    text-transform: inherit;

    @include media-xs {
      font-size: 24px;
      text-transform: uppercase;
    }
  }

  .container {
    padding: 0;

    @include media-xs {
      padding: 15px;
    }
  }

  .field-helperSuccess {
    @include media-xs {
      display: none;
    }
  }

  input.input-input--type--number {
    @include media-xs {
      max-width: 100%;
      height: 40px;
      padding: 9px 15px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      font-size: 12px;
      line-height: 1.5;
    }
  }

  .input-input--hint__static {
    position: absolute;
    top: 13px;
    right: 10px;
  }

  .input-input--hint__static_offset {
    right: 50px;
    top: 34px;
  }

  .input-input--hint__static_checked {
    background-color: initial;

    &:before {
      @include svg-icon;
      content: '\0075';
      color: #247e55;
      font-size: 12px;
    }
  }

  .input-input--hint__static + span {
    width: 95px;
    top: 28px;
    left: 10px;
    @include media-xs {
      max-width: initial;
      width: 242px;
      top: 24px;
      left: 31px;
    }
  }

  .input-input--hint__static_offset + span {
    width: fit-content;
    left: initial;
    top: 45px;
    right: 50px;
  }

  .select-selectMenu {
    padding: 10px 30px 10px 15px;
    line-height: 1.7;
  }

  .select-icon {
    width: 34px;
  }

  .limit-form-block {
    margin-bottom: 23px;
    padding-bottom: 32px;
    border-bottom: 1px solid #2E2E2E;

    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    @include media-xs {
      padding-bottom: 20px;
      border-bottom: none;
    }
  }

  .limit-form-block_slim {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
  }

  .limit-form-title_main {
    @include media-xs {
      box-shadow: 0 1px 0 0 #0f0f10, 0 2px 0 0 #272628;
      background: #171718;
      width: 100%;
      margin: 0 0 12px 0;
      padding: 11px 10px 9px 10px;
      color: #f1f1f1;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: .5px;
      line-height: 16px;
    }
  }

  .limit-form-title_main + .limit-form-row__item {
    padding: 0 8px;
  }

  .limit-form-row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 30px;
    margin-bottom: 20px;

    @include media-xs {
      flex-direction: column;
      margin-bottom: 20px;

      > .confirm-button {
        margin: 0 auto;
      }
    }
  }

  .limit-form-row__item {
    position: relative;

    &_small {
      max-width: initial;
      flex-basis: initial;
    }

    &_small-extra {
      max-width: 130px;
      flex-basis: 130px;

      @include media-xs {
        max-width: initial;
        flex-basis: initial
      }
    }

    @include media-xs {
      width: 100%;
    }
  }

  .limit-form-row__banner {
    width: 100%;
    margin-bottom: 20px;
    padding: 19px 29px;
    border: none;
    background-color: transparent;
    color: #c84b4b;
    font-size: 12px;
    font-weight: normal;

    p {
      font-size: inherit;
    }

    @include media-xs {
      padding: 10px 35px 10px 15px;
      background-color: #c84b4b;
      color: #f1f1f1;
      font-weight: 400;
      p {
        text-align: center;
      }
    }
  }

  .limit-form-row__banner_slim {
    padding-left: 0;
    font-size: 16px;
  }

  .limit-form-static-block {
    h2 {
      margin-bottom: 20px;
      font-size: 24px;
      line-height: 1;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }

    p {
      margin: 0 0 14px 0;
      @include media-xs {
        font-size: 12px;
      }
    }

    p.big {
      font-size: 16px;
      @include media-xs {
        font-size: 15px;
      }
    }

    a {
      text-decoration: underline;
    }

    @include media-xs {
      padding: 14px 9px 30px 9px;
    }
  }

  .button-secondary {
    min-width: initial;
    width: initial;
    margin: 13px 0 10px 0;
    padding: 7px 19px 7px;
    background: #E3A52F;
    border-radius: 5px;
    border: 1px solid transparent;
    box-shadow: none;
    font-size: 16px;
    line-height: 1.5;
    color: #1D1D1D;

    &:hover {
      color: #b74343;
      background: #E3A52F;
    }

    @include media-xs {
      min-height: 35px;
      font-size: 10px;
      line-height: 14px;
    }
  }

  .limit-form-row .limit-amount__amount-input .input-before {
    min-width: 40px;
    height: 40px;
    display: block;
    padding: 10px 15px 9px;
    border-radius: 5px 0 0 5px;
    border: 1px solid color(secondary, dark);
    background-color: #4a5562;
    font-size: 12px;
    line-height: 1.5;
    color: #fff;

    @include media-xs{
      transform: none;
    }
  }

  @include media-xs {
    padding: 0;
    width: 100%;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
