.login {
  &-modal--loginToPlay {
    .group-root--buttons {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 0;

      @include media-xs {
        justify-content: left;
      }
      .login__button,
      .registration__button {
        margin: 15px 0;
      }
      .registration__button {
        font-size: 16px;
        line-height: 1.5;
        padding: 17px 46px;
      }
      .login__forgot-password {
        letter-spacing: 0.5px;
        text-decoration: none;
      }
    }
    &__button {
      @include media-xs {
        min-width: auto;
      }
    }
    .login-form__or {
      position: relative;
      text-transform: uppercase;
      padding: 4px 8px;
      margin: 25px 0;
      z-index: 1;

      &::before,
      &::after {
        content: '';
        position: absolute;
        z-index: -1;
      }

      &::before {
        top: 50%;
        left: 50%;
        width: 240px;
        transform: translateX(-50%);
        border-top: 1px solid #707070;
        z-index: -1;
      }
      &::after {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #ffffff;
      }
    }
  }
}
