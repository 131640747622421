.upload-image-modal {
  .modal-paper {
    max-width: 660px;

    @include media-xs {
      display: flex;
      justify-content: center;
    }

    .modalContentWrapper {
      padding: 60px 90px;

      @include media-xs {
        padding: 10px;
      }
    }
  }

  &__label {
    font-size: 24px;

    p {
      line-height: normal;
      margin: 0 0 1.5rem;
    }
  }

  &__field {
    margin: 0 0 1.5rem;

    &--message {
      font-size: 16px;
      margin-right: auto;
      padding: 9px 15px;
    }

    &--field {
      display: flex;
      background-color: color(input, background);
      border-radius: 6px;

      .snapshotFileField {
        &__fileLabel {
          cursor: pointer;
        }

        &__fileButton {
          min-width: auto;

          &::before {
            @include svg-icon('custom');
            content: '\0065';
            position: relative;
            font-size: 24px;
          }

          &:hover {
            background-color: transparent;
          }
        }
      }
    }

    &--error {
      min-height: 16px;
      color: color(error);
    }
  }

  &__message {
    text-align: center;
    font-size: 16px;
    margin: 0 0 1.5rem;
  }

  &__buttons {
    display: flex;
    gap: 2em;
    justify-content: center;
    width: 100%;

    @include media-xs {
      flex-direction: column-reverse;
      align-items: center;
    }

    button {
      font-size: 16px;
      width: max-content;
    }

    &--upload-image-button {
      .button-label::before {
        @include svg-icon('custom');
        content: '\0062';
        position: relative;
        font-size: 16px;
        padding-right: 10px;
      }
    }
  }
}
