@mixin snapshot-button {
  @include size(100%);
  display: flex;
  flex-direction: column;
  background: color(promotions-card, block-background);
  color: color(primary, main);
  font-size: 14px;
  border-radius: 0;
  text-transform: uppercase;
  padding: 0;
  min-height: auto;

  @include media-xs {
    background: none;
    border: 1px solid var(--file-attachments-border);
    border-radius: 10px 3px;
    color: var(--file-attachments-border);
    font-size: 10px;
  }

  &:hover {
    background: color(promotions-card, block-background);
    color: color(button-accent, hover);

    &:before {
      color: color(primary, main);
    }
  }

  &:before {
    @include svg-icon('custom');
    color: color(primary, main);
    font-size: 33px;

    @include media-xs {
      display: none;
    }
  }
}
@mixin snapshot-button-small {
  flex-direction: row;
  font-size: 9px;

  & > span {
    width: auto;
    margin-left: 5px;
  }

  &:before {
    font-size: 14px;
  }
}
.snapshot {
  display: flex;
  @include size(100%);
  flex-direction: column;
  justify-content: space-between;

  &__headers {
    display: flex;
    font-size: 14px;

    @include media-xs {
      margin-bottom: 5px;
    }
  }
  &__header {
    @include media-xs {
      margin-right: 5px;
    }
  }
  &__side {
    margin-left: 5px;
  }
  &__content {
    min-height: 156px;

    @include media-xs {
      min-height: auto;
    }

    &-wrapper {
      position: relative;
    }
    .snapshot-image {
      &__wrapper {
        position: relative;
        display: flex;
        max-height: 136px;
        min-height: 136px;
        border-radius: 5px;
        overflow: hidden;

        @include media-xs {
          min-height: auto;
        }

        .snapshot__image {
          height: 136px;
          width: 100%;

          @include media-xs {
            height: 40px;
          }
        }
      }
    }
  }
  &__message {
    position: absolute;
    font-size: 9px;
    line-height: 1.1;
    text-align: left;
    margin: 5px 0;

    @include media-xs {
      position: relative;
      font-size: 10px;
      font-style: italic;
      color: #777;
    }

    & + .markdown-root {
      position: absolute;
      bottom: -30px;
      z-index: 1000;

      p {
        font-size: 9px;
        color: color(error);
        margin: 0;
      }
    }
  }
  &__error {
    position: absolute;
    font-size: 9px;
    color: #c84b4b;
    margin: 0;
    bottom: -36px;

    @include media-xs {
      position: relative;
      font-size: 12px;
      margin-top: 5px;
      bottom: initial;
    }
  }
  &__buttons {
    overflow: hidden;
    border-radius: 5px;
  }
  &__snapshotField,
  &__fileField {
    height: 136px;

    @include media-xs {
      height: 32px;
      border-radius: 5px;
      overflow: hidden;
    }
  }

  &__snapshotField {
    &-button {
      @include snapshot-button;

      .button-label + span {
        display: none;
      }

      &:before {
        content: '\0045';
      }
    }
  }

  &__fileField {
    .snapshotFileField {
      @include size(100%);
    }
  }
  .snapshotFileField {
    border-left: 1px solid var(--body-background);

    @include media-xs {
      border-left: none;
    }

    &__fileButton {
     @include snapshot-button;

      &:before {
        content: '\0047';

        @include media-xs {
          content: none;
        }
      }
    }
    &__fileLabel {
      top: 0;
      cursor: pointer;
    }
  }

  &-image__wrapper.success {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #49A06A;
    color: color(button-accent, color);
    font-size: 14px;

    &:before {
      @include svg-icon('custom');
      content: '\004c';
      color: color(button-accent, color);
      font-size: 50px;
    }
  }

  &-image__wrapper + &__buttons {
    position: absolute;
    max-width: 100%;
    bottom: 0;
    border-radius: 0 0 5px 5px;

    .snapshot__snapshotField,
    .snapshot__fileField {
      height: 27px;
    }
    .snapshotFileField {
      &__fileButton {
        flex-direction: row;
        font-size: 9px;

        &:before {
          font-size: 10px;
        }
      }
    }
    .snapshotFileField {
      &__fileButton {
        @include  snapshot-button-small;
      }
    }
    .snapshot__snapshotField-button {
      @include snapshot-button-small;

      .button-label + span {
        display: none;
      }
    }
  }
  &__view-more {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: color(primary, main);
    margin: 0;
    height: calc(100% - 26px);
    width: 100%;
    font-size: 12px;
    border-radius: 0;
    text-transform: initial;
    background-color: rgba(100%, 100%, 100%, 0.75);
    cursor: pointer;

    .button-label {
      width: auto;
    }

    .button-label + span {
      display: none;
    }

    &:hover {
      background-color: rgba(100%, 100%, 100%, 0.75);
      color: color(button-accent, hover);
    }
  }

  &__uploadedImage {
    margin-left: 10px;
  }
}
