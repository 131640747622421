.blocked-page {
  &__header {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 134px;
  }
  &__logo {
    display: block;
    max-width: 290px;
    width: 100%;
    margin: 32px auto 0;
  }
  .content-wrapper {
    display: flex;
    flex-grow: 1;
    align-items: center;
    min-height: 100vh;
    @include webp-bg-img('/cms/img/bg.webp', '/cms/img/bg.jpg');
    background: {
      position: center;
      repeat: no-repeat;
      size: cover;
    }

    .content {
      max-width: 600px;
      margin: 0 auto;
      padding: 15px;
      text-align: center;
      box-shadow: 0 0 25px 20px rgba(29, 29, 29, 0.7);
      background-color: rgba(29, 29, 29, 0.66);

      &__title {
        font-size: 42px;
        color: #fff;
        font-weight: $font-weight-base;
        text-transform: uppercase;
      }
      &__text {
        font-size: 21px;
        color: #fff;
      }
    }
  }
}
