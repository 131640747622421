.modal-root,
.popup-decorator {
  overflow: visible;
  border-radius: 0;
  * {
    font-family: $font-primary;
  }

  &.is-submodal {
    .modal-paper {
      width: 420px;
      margin: 16px;

      .modalContentWrapper {
        .modal-titleRoot {
          padding-top: 25px;
        }

        .modal-contentRoot {
          padding-right: 25px;
          padding-left: 25px;

          .markdown-root {
            text-align: center;
          }
        }
      }
    }
  }

  .modal-buttonRoot {
    @include close-button;
    border-radius: 50%;
    z-index: 100;
  }
  .modal-rootButtons {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .modal-rootButton {
    > .button-default {
      min-height: 32px;
      padding: 4px 8px;
      color: color(secondary, main);
      font-size: 16px;
      text-decoration: none;

      &:after {
        display: none;
      }
      > .button-label {
        white-space: nowrap;

        &::before {
          content: '';
          position: absolute;
          bottom: -3px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: color(secondary, main);
          transition: all $main-transition-time;
        }
        &:hover::before {
          opacity: 0;
        }
      }
    }
  }
  .modal-paper {
    width: 720px;
    max-width: 100%;
    border-radius: 5px;

    .modalContentWrapper {
      display: flex;
      flex-direction: column;
      overflow-y: auto; // [FIN-21054] - wrong style in FireFox
      padding: 20px;

      .modal-titleRoot {
        padding: 0;

        h2 {
          margin: 0 0 14px 0;
          text-transform: none;
          font-size: 16px;
          font-weight: 300;

          @include media-sm {
            text-transform: uppercase;
            font-size: 14px;
            letter-spacing: .5px;
            color: var(--modal-title-color-mobile);
          }
        }
        & + .modal-contentRoot {
          padding: 0;

          h1 {
            margin-top: 10px;
          }
        }
      }

      .modal-contentRoot,
      .popup-decorator__content {
        border: 0;
        border-radius: 0;
      }
      .modal-contentRoot {
        padding: 0;
        overscroll-behavior: none;
      }
      .modal-contentRoot > div:last-child {
        &.notification-root {
          // if you remove it notifications in modals cannot use margin (a bug of browser, at last Chrome)
          animation: none;
        }

      }
      .modal-contentRoot > div:not(.notification-root):not(.grid-root):not(.deposit-form):not(.withdrawal-form):not(.resume-form):not(.verification-form):not(.game-search):not(.pep-page-content):not(.pep-popup-page-content):not(.upload-image-modal) {
        height: 100%;

        @include isMozillaFirefox {
          height: auto;
        }

        @include isMicrosoftEdge {
          height: auto;
        }
      }
      .field-root {
        padding-right: 0;
      }
    }
  }
  .content__wrapper {
    width: 100%;

    .group-root {
      width: 100%;
    }
  }
}

.page-modal--id-terms-conditions,
.page-modal--id-privacy-policy,
.page-modal--id-responsible-gambling,
.page-modal--id-bonus-terms {
  .modal-paper {
    width: 1168px;
  }
  .underlined-title {
    display: none;
  }
}
.modal__gambling-message {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: auto !important;
  margin: 20px 0;
  padding-bottom: 0 !important; // override: .modal-root .modal-paper .modalContentWrapper .modal-contentRoot > div:last-child `padding-bottom`
  text-align: center;

  @include media-sm {
    text-align: left;
  }

  img {
    @include size(32px);
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 8px;
    filter: brightness(0) grayscale(100%);
  }

  p {
    margin: 0;
    font-size: 14px;
    color: color(text, light);
  }
}
.confirmation-modal__buttons {
  margin-top: 20px;
  justify-content: center;
  gap: 0 20px;

  .gridItem-root {
    max-width: initial;
    flex-basis: auto;
  }
}

/* FreeSpins Finished modal */

.free-spins-finished-modal {
  &__buttons {
    text-align: center;

    button,
    button + button {
      margin: 0 7.5px;
    }
    @include media-xs {
      button + button {
        margin-top: 15px;
      }
    }
  }
}

@include media-sm {
  body .modal-root {
    top: $header-expanded-height;

    .modal-contentRoot {
      display: flex;
      flex-direction: column;
      padding: 20px 20px 15px;
    }
    .modal-paper {
      @include modal-fullscreen-mode;
    }

    &.mobile-modal-theme {
      .modal-paper {
        .modalContentWrapper {
          position: relative;

          .modal-titleRoot {
            position: absolute;
            display: block;
            top: 1px;
            left: 1px;
            right: 1px;
            border-radius: 5px 5px 0 0;
          }

          h2 {
            margin: 0;
            padding: 10px;
          }
        }
      }
    }
  }
}
