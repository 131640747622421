.game-list {
  margin: 0;

  &--all {
    @include media-xs {
      margin: 0;
    }
  }

  @include media-xs {
    margin-bottom: 12px;
    max-width: calc(100% + 20px);
    margin-left: -5px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &__title {
    position: relative;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 0;

    @include media(null, $size-md) {
      max-width: 1280px;
      margin: 0 auto;
    }

    .on-home & {
      @include media-xs {
        padding: 0;
        text-align: left;
      }
    }

    @include media-xs {
      margin-left: 7px;
      padding: 0 0 5px 5px;
    }
  }

  &__title-name {
    color: color(secondary, dark);
    font-size: 22px;

    @include media-xs {
      font-size: 16px;
    }
  }
  &__title-link {
    margin-left: 10px;
    font-size: 16px;
    color: color(primary, main);
    text-transform: none;

    @include media-xs {
      font-size: 12px;
    }

    &:hover {
      color: color(secondary, main);

      &::after {
        opacity: 0;
        bottom: -2px;
      }
    }
  }
  &__empty,
  &__not-available {
    text-align: left;
    color: red;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0.5px;
    margin: 0 0 30px 30px;

    @include media-xs {
      text-transform: none;
      color: var(--file-attachments-border);
      font-size: 12px;
      font-weight: 700;
      margin-left: 10px;
    }
  }
  &__not-available {
    grid-column: auto / span 5;

    @include media-md {
      grid-column: auto / span 4;
    }
    @media (max-width: 900px) {
      grid-column: auto / span 3;
    }
    @include media-xs {
      grid-column: auto / span 2;
    }

    #root[data-url="/group/all"] &,
    #root[data-url="/all-games"] & {
      grid-column: auto / span 4;

      @media (max-width: 900px) {
        grid-column: auto / span 3;
      }
      @include media-xs {
        grid-column: auto / span 2;
      }
    }
  }

  &__games {
    margin: 0 -6px;
    max-width: calc(100% + 12px);

    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    &--grid {
      margin: 0;
      max-width: 100%;

      .game-list__games-wrapper {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 30px;
        flex-shrink: 0;

        @include media-md {
          grid-template-columns: repeat(4, 1fr);
        }
        @media (max-width: 900px) {
          grid-template-columns: repeat(3, 1fr);
        }
        @include media-xs {
          grid-template-columns: repeat(2, 1fr);
        }

        #root[data-url="/group/all"] &,
        #root[data-url="/all-games"] & {
          grid-template-columns: repeat(4, 1fr);

          @media (max-width: 900px) {
            grid-template-columns: repeat(3, 1fr);
          }
          @include media-xs {
            grid-template-columns: repeat(2, 1fr);
          }
        }

        .featured-games-slider {
          grid-area: 1 / 1 / 3 / 3;
        }

        .game-thumbnail {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  &__buttons-panel {
    margin-top: 20px;
    text-align: center;
    grid-column: 1 / 6;

    @include media-md {
      grid-column: 1 / 5;
    }
    @media (max-width: 900px) {
      grid-column: 1 / 4;
    }
    @include media-xs {
      grid-column: 1 / 3;
    }
    #root[data-url="/group/all"] &,
    #root[data-url="/all-games"] & {
      grid-column: 1 / 5;

      @media (max-width: 900px) {
        grid-column: 1 / 4;
      }
      @include media-xs {
        grid-column: 1 / 3;
      }
    }
  }
  &__button {
    @include media-xs {
      min-width: 120px;
    }
  }
}
