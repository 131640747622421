.game-thumbnail {

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 72%;
  }

  .image-content {
    position: absolute;
    overflow: hidden;

    &:hover {
      .game-thumbnail__content,
      .game-thumbnail__rtp {
        opacity: 1;
      }
      .game-thumbnail__last-results,
      .game-thumbnail__info,
      .game-thumbnail__jackpot-badge {
        opacity: 0;
      }
      .game-thumbnail__jackpot-amount {
        height: 0;
      }
    }

    @include media-xs {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    @at-root {
      .mobile &:hover,
      .tablet &:hover {
        opacity: 0;
      }
    }
  }

  &__placeholder {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  &--loaded {
    &::after,
    .game-thumbnail__placeholder:not(.game-thumbnail__placeholder_rendering) {
      display: none;
    }
  }
  &__badges {
    position: absolute;
    z-index: 4;
    top: 2px;
    left: 2px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    opacity: 1;
    transition: opacity $main-transition-time;
    pointer-events: none;

    &:empty {
      display: none;
    }
  }
  &__badge {
    flex: {
      grow: 0;
      shrink: 0;
    }
    background: {
      color: transparent;
      position: center;
      repeat: no-repeat;
      size: contain;
    }

    &--new {
      @include size(50px);
      background-image: url('/cms/img/icons/new.png');
    }
    &--free-spins {
      @include size(49px, 40px);
      background-image: url('/cms/img/icons/free-spins.svg');
    }
  }
  &__jackpot-amount {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    color: #f1f1f1;
    font-size: 24px;
    font-weight: 300;
    line-height: 1.3;
    text-align: center;
    background: rgba(0, 0, 0, 0.8);
    pointer-events: none;
    transition: height 0.3s;
    text-transform: uppercase;
    display: block;
    height: 60px;

    &--title {
      color: color(promotions-card, title-background);
      margin-top: 12px;
      font-size: 12px;
      font-weight: 500;
      line-height: 1;
      text-transform: uppercase;
    }
  }
  &__jackpot-badge {
    @include size(53px, 40px);
    position: absolute;
    z-index: 4;
    bottom: 0;
    left: 3px;
    background: url('/cms/img/icons/jackpot.svg') transparent center/contain no-repeat;
  }
  /* Fix jumping game images (Safari) (1) */
  > img {
    flex-shrink: 0;
    height: 0; // 1
    width: 100%;
    padding-top: 72%; // 1
    visibility: hidden;
    object-fit: cover;
    object-position: center;

    &[src=""] {
      height: 0; // 1
    }
  }
  &--loaded img {
    height: 100%; // 1
    padding-top: initial; // 1
    visibility: initial;
    border-radius: 3px;
  }
  &__title,
  &__rtp {
    .desktop .game-slider & {
      @include media-md {
        font-size: 1.75vw;
      }
    }
  }
  &__title {
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;

    .desktop & {
      @media (min-width: 768px) {
        @include truncate;
      }
    }
    @include media-md {
      font-size: 14px;
    }
    @include media-lg {
      font-size: 12px;
    }
  }
  &__rtp {
    position: absolute;
    bottom: 6px;
    left: 8px;
    z-index: 1;
    font-size: 12px;
    color: #fff;
    opacity: 0;
    transition: opacity $main-transition-time;

    & > p {
      margin: 0;
    }
  }
  &__buttons {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
  &__button {
    position: relative;
    min-width: 60px;
    min-height: 30px;
    margin: 2px;
    padding: 4px 12px;
    align-items: center;
    max-width: 100%;
    font-size: 10px;
    text-transform: uppercase;

    &--real,
    &--demo {
      font-weight: $font-weight-base;
      margin-bottom: 5%;
      font-size: 10px;
      width: 50%;

      .desktop & {
        @include media-sm {
          display: none;
        }

        @include media-min-lg {
          width: 80%;
        }
      }

      .is-logged-out & {
        @include media-lg {
          padding: 4px 12px;

          &::before {
            display: none;
          }
        }
      }

      [data-url="/group/all"] &,
      [data-url="/all-games"] &,
      .game-slider--game-page & {
        padding-top: 4px;

        &::before {
          display: none;
        }
      }
    }

    &--favourite {
      display: block;
      position: absolute;
      top: 5px;
      right: 0;
      z-index: 3;
      min-width: 0;
      margin: 0;
      padding: 10px 12px;
      font-size: 0;
      line-height: 1;
      vertical-align: middle;
      border: 0;

      &::before {
        @include svg-icon('custom');
        content: '\0051';
        font-size: 25px;
        color: white;
      }
      .button-label {
        display: none;
      }
      @media (min-width: 480px) {
        &:hover {
          background: none;

          &::before {
            color: #b5a147;
          }
        }
      }
      .desktop & {
        @include media-sm {
          display: none;
        }
      }
    }
    &--favourite-on {
      &::before {
        color: #b5a147;
      }
    }
  }
  &__button-overlay {
    width: 100%;
    height: 100%;
    font-size: 0;

    @media (pointer: coarse) {
      pointer-events: auto;
    }
    .desktop & {
      @include media-sm {
        pointer-events: auto;
      }
    }
  }
  &__content {
    padding: 8px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    opacity: 0;
    transition: $main-transition-time;
  }
  &__last-results {
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 6px 3px 33px;
    background: {
      color: rgba(255, 255, 255, .25);
      position: 0 50%;
      repeat: no-repeat;
      size: contain;
    };
    border-radius: 16px;
    transition: opacity $main-transition-time;

    @include media-sm {
      transform: translateX(-50%) scale(.9);
    }

    &--roulette {
      background-image: url('/cms/img/games/game-thumbnail/last-results-roulette.svg');
    }
    &--blackjack {
      background-image: url('/cms/img/games/game-thumbnail/last-results-blackjack.svg');
    }
    &--baccarat {
      background-image: url('/cms/img/games/game-thumbnail/last-results-baccarat.svg');
    }
  }
  &__last-results-item {
    @include size(20px);
    border-radius: 50%;
    color: #fff;
    font-size: 10px;
    font-weight: $font-weight-alt;
    line-height: 20px;
    text-align: center;

    &:not(:last-of-type) {
      margin-right: 3px;
    }

    &:first-of-type {
      @include size(24px);
      font-size: 12px;
      line-height: 24px;
    }

    &--red {
      background: #ff4747;
    }
    &--black {
      background: #000;
    }
    &--green {
      background: #71ac31;
    }
  }
  &__dealer {
    max-width: 100%;
  }
  &__info {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 8px;
    width: 100%;
    transition: opacity $main-transition-time;

    @include media-sm {
      padding: 12px;
    }
  }
  &__info-item {
    color: #fff;
    font-weight: $font-weight-alt;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 1);

    &:not(:last-child) {
      margin-bottom: 2px;
    }

    &--name {
      font-size: 18px;

      .desktop & {
        @media (min-width: 768px) {
          @include truncate;
        }
      }
    }
    &--bets,
    &--seats {
      font-size: 12px;
    }
    &--seats {
      &-open {
        color: color(success);
      }
      &-taken {
        color: color(error);
      }
    }
  }

  .featured-games-slider & {
    .game-thumbnail__badges,
    .game-thumbnail__jackpot-amount,
    .game-thumbnail__jackpot-badge {
      display: none;
    }

    &__title,
    &__rtp {
      font-size: 20px;
    }
    &__button--real {

      &::before {
        display: block;
      }
    }
  }
}
