@import 'LiveStudio';
@import 'LoyaltyProgram';
@import 'Promotions';
@import 'Promotion';
@import 'Faq';
@import 'Press';
@import 'CookiePolicy';
@import 'TermsOfService';
@import 'Pep';

/* Layout */

.static-page {
  &__container {
    max-width: initial;
    padding-top: 15px;
  }
  &__menu {
    padding-right: 15px;

    @include media-md {
      display: none;
    }
  }
  &-content {
    .content-blocks {
      display: grid;
      justify-content: space-between;

      .content-block {
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        background-color: color(promotions-card, background);
        margin: 15px;
        padding: 0;

        &-content {
          text-align: center;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          padding: 25px 28px;
          overflow: hidden;

          &__header {
            font-size: 18px;
          }

          &__text {
            font-size: 12px;
          }

          &__header,
          &__text {
            margin-top: 0;
            margin-bottom: 14px;
          }
        }
      }
    }
  }
}

/* Promotions-static */

.promotions-static {
  text-align: center;
}

/* Other */

#static-page__anchor {
  position: relative;
  top: -75px;
}
