body .pep-modal {

  .modal-buttonRoot {
    @include media-xs {
      display: block;
      z-index: 1000;
    }
  }

  .modal-paper {

    .modal-contentRoot {
      @include media-xs {
        height: 100%;
        max-height: 100%;
      }
    }

    .modalContentWrapper {
      @include media-xs {
        padding: 0;
      }
    }

    .confirmation-modal__buttons {
      gap: 10px;
    }
  }
}
