.promotions {
  &__card-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 40px;

    @include media-sm {
      margin-bottom: 20px;
    }
    @include media-xs {
      justify-content: center;
      margin-bottom: 0;
    }
  }
  &__forfeit {
    padding: 0 15px;
    margin: 40px 0;

    @include media-sm {
      margin: 20px 0;
    }
  }

  &__error {
    .notification-root {
      text-align: center;
      padding: 19px 29px;
      border-radius: 5px;
      background: none;

      p {
        font-size: 16px;
      }

      @include media-xs {
        color: color(error);
      }
    }
  }
}
