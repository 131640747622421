.registration {
  @include media-xs {
    .login__title {
      font-size: 14px;
    }

    &--regular {
      margin: 10px;

      .notification-root {
        border: none;
        color: var(--file-attachments-border);
        background: color(input, background);
        text-align: center;

        + .fields-container {
          margin-top: 10px;
        }

        h2 {
          color: color(success);
          font-weight: bold;
          margin-top: 0;
        }

        p {
          text-transform: uppercase;
          font-size: 14px;

          strong {
            color: color(success);
            font-weight: normal;
          }
        }
      }
    }
  }
}
.registration-modal {

  .stepper {
    &-root {
      height: 70px;
      align-items: center;
      padding: 0;
    }

    &-step {
      max-width: none;

      &:not(.active) {
        display: none;
      }

      > div:not(:first-child) {
        display: none;
      }

      .stepper-item {
        > div:first-child {
          display: none;
        }
      }
    }

  }

  .modal-paper {
    @include modal-fullscreen-mode;
    padding: 0;

    .modalContentWrapper .modal-titleRoot  {
      text-align: center;
      background: var(--modal-title-background);

      @include media-sm {
        background: none;
        border-radius: 5px;
        margin: 0 0 15px 0;
      }

      h2 {
        font-size: 24px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        line-height: 1;
        color: color(input, background);
      }
    }

  }

  &:not(.registration-modal--error) {
    .modalContentWrapper {
      position: relative;
      padding: 0;

      @include media-md {
        position: static;
      }
    }
  }
  .registration-form {
    &--mobile {
      padding: 0 10px;

      .notification-root .notification-message {
        position: relative;

        h2 {
          font-size: 13px;
          text-transform: inherit;
          font-weight: bold;
          color: #fff;
        }

        .button-root {
          min-width: auto;
          min-height: auto;
          position: absolute;
          top: -5px;
          right: -5px;
          font-size: 15px;
          opacity: .2;
          font-weight: 700;
          color: #fff;
          text-transform: inherit;
        }
      }
    }
  }
}
.registration-form {

  .content-wrapper {
    max-width: 800px;
    min-width: 456px;
    margin: 25px auto 0;
    width: calc(30vw + 150px);

    @include media-xs {
      width: 100%;
      min-width: auto;
      margin-top: 0;
      padding: 10px;
    }



    .notification {
      &-root {
        padding: 19px 29px;
        justify-content: center;

        p {
          font-size: 16px;
          color: color(select, background);
        }
      }

      &-message {
        width: 100%;
        text-align: center;
      }
    }

    .privacy-policy-text {
      margin: 0 10px;
    }
  }

  .pep-buttons {
    max-width: 600px;
    margin: 0 auto;

    @include media-xs {
      text-align: center;
      margin-bottom: 15px;
    }

    .button-root {
      font-size: 12px;
      line-height: 1;
      margin: 10px;

      @include media-xs {
        margin: 4px 0 0 0;
      }

    }
  }
  .adornment-password {
    right: 20px;
  }

  .field-root .field-helper {
    //font-size: 0;
  }

  .field-root .field-helperError {
    //font-size: 10px;
  }

  .form-title {
    margin-top: 25px;
    padding: 0 10px;
    text-align: center;

    h4 {
      text-transform: none;
      font-size: 18px;
      font-weight: 400;
      color: color(footer, link);
      letter-spacing: 0.5px;
      margin: 14px 0 10px 0;
      line-height: 1;
    }

    p {
      font-size: 12px;
      margin:0 0 14px 0;
    }
  }

  .button-default {
    background: color(primary, dark);
  }

  .fields-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0 30px;
  }

  .registration-code {

    &--wrapper {
      margin: 0 10px;
      border-bottom: 1px solid color(primary, dark);

      .field-select {
        svg {
          fill: color(primary, main);
        }
      }

      p {
        font-size: 10px;
        color: color(label, unfilled);
        margin-top: 20px;
      }
    }

    &--block {
      display: flex;
      flex-direction: row;
      gap: 0 5%;

      > div {
        flex-basis: 47%;
      }
    }
  }

  .support-section {
    font-size: 10px;
    line-height: 1.5;
    padding: 0 10px;
  }

  .registration-form__successful {
    p {
      font-size: 14px;

      strong {
        color: color(primary, main);
      }
    }
  }

  .fields-container, .form-title, .registration-code--wrapper, .support-section, .registration-form__successful {
    position: relative;
    justify-content: center;
    max-width: 650px;
    margin: 20px auto;

    h2 {
      text-transform: uppercase;
      font-size: 26px;
      font-weight: 400;
      color: color(primary, main);
      margin-top: 0;
    }

    .button-root {
      margin-top: 13px;
    }

    .full-width {
      width: 100%;
    }

    .privacy-policy-text {
      font-size: 85%;

      @include media-xs {
        color: var(--file-attachments-border);
        font-size: 100%;
      }

      p {
        line-height: 1.6;

        a {
          text-decoration: none;
        }
      }

    }

    &__grid-root {
      padding: 0 10px;
      margin-bottom: 10px;

      @include media-xs {
        padding: 0;
      }

      &--ageAndPolicyChecked, &--receiveEmail, &--publicPerson {
        margin: 0;
      }

      .markdown-root {
        p {
          margin: 0;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .fields-container, .form-title, .registration-code--wrapper, .support-section, .registration-form__successful {
      min-width: 0;
      margin: 0 auto;
      width: 100%;
      left: 0;
      transform: none;
    }
  }
  &__successful {
    margin-top: 20px;
    text-align: center;

    .markdown-root {
      margin-bottom: 20px;
    }
  }
  &__gdpr-notification {
    font-size: 14px;
    text-align: justify;
    color: color(text, main);
  }
  &__buttons {
    display: flex;
    justify-content: center;
    margin: 20px auto 0;

    @include media-sm {
      margin-bottom: 20px;
    }
    button + button {
      margin-left: 30px;
    }
  }
  .fields-container__grid-root--ageChecked,
  .fields-container__grid-root--policyChecked,
  .fields-container__grid-root--ageAndPolicyChecked,
  .fields-container__grid-root--receiveEmail,
  .fields-container__grid-root--receiveSms,
  .fields-container__grid-root--receiveEmailAndSms,
  .fields-container__grid-root--statementPrivacyChecked,
  .fields-container__grid-root--statementLegislationChecked,
  .fields-container__grid-root--statementLegallyCompetentChecked,
  .fields-container__grid-root--statementOwnAccountChecked,
  .fields-container__grid-root--statementNoFraudAmlChecked,
  .fields-container__grid-root--statementIdentificationChecked,
  .fields-container__grid-root--publicPerson {
    max-width: 100%;
    flex-basis: 100%;
    order: 10;
  }
  .fields-container__grid-root--campaignsEnabled{
    display: none;
  }
  .fields-container__grid-root--receiveEmail {
    label {
      align-items: flex-start;
    }
  }
  .fields-container__grid-root--selected-country-ROU {
    &.fields-container__grid-root--birthDate {
      display: none;
    }
  }
  &__logging-in {
    margin-top: 10px;
    text-align: center;

    &-text {
      margin-top: 10px;
    }
  }

  &__stepper {

    .active .stepper-item {
      p {
        font-size: 18px;
        color: color(input, background);
        text-transform: uppercase;
        margin: 0;
      }
    }

    .stepper-step {

      > div:nth-child(2) {
        width: calc(100% + 20vw);
      }

      p {
        color: color(input, text);
      }
    }
  }

  &--mobile {
    .fields-container .gridItem-typeItem {
      margin-bottom: 5px;
    }
  }
}
