.playerVerificationStep5 {
  .container {
    max-width: initial;

    @include media(null, $size-xs) {
      padding: 0;
    }
  }
  .playerVerificationWaitingForApproval {
    padding: 30px 28px 26px;

    @include media-xs {
      padding: 0;
    }

    &__content {
      padding: 15px;
      text-align: center;
      background-color: color(input, background);

      @include media-xs {
        padding: 0;
        background-color: transparent;
      }
    }
    &__title {
      font-size: 24px;
      text-transform: uppercase;
      color: color(success);
      margin: 20px 0 0;

      @include media-xs {
        font-size: 14px;
        margin: 10px 0 0;
      }
    }
    &__header {
      font-weight: 500;
      font-size: 19px;

      @include media-xs {
        font-size: 12px;
      }
    }
    &__text {
      font-weight: 500;
      font-size: 16px;
      margin-top: 15px;
      margin-left: 20%;
      margin-right: 20%;

      @include media-xs {
        font-size: 12px;
        color: var(--file-attachments-border);
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}
