.forgot-password {
  .grid-root {
    @include media-xs {
      padding: 15px;
    }
  }
  &__title {
    @include media-xs {
      font-size: 14px!important;
      letter-spacing: 1.2px;
      line-height: 18px;
      font-weight: 400;
    }
  }
  &__fields {
    @include media-xs {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  &__submit {
    @include media-xs {
      text-align: center;
    }
  }
  &__info {
    @include media-xs {
      display: block;
      padding: 10px 10px 15px;
      margin-top: 5px;
      line-height: 1.3;
      text-align: center;
      letter-spacing: .5px;
      color: var(--file-attachments-border);
      background-color: color(input, background);
      font-weight: 300;
    }
  }
}
