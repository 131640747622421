.footer-menu {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 0 20px;

  .tablet & {
    justify-content: space-around;
  }
  @include media-xs {
    justify-content: center;
    padding: 0;
    gap: normal;

    & > div {
      width: 50%;
    }
  }

  li {
    @include media-xs {
      margin: 0 10px;
    }
  }

  a {
    display: inline-block;
    padding: 18px 0;
    color: color(footer, link);
    font-size: 12px;
    line-height: 22px;
    font-weight: $font-weight-base;

    @include media-sm {
      padding-left: 12px;
      padding-right: 12px;
      font-size: 12px;
    }
    @include media-xs {
      width: 100%;
      padding: 0;
      font-size: 9px;
      line-height: 1;
    }
  }
}
