.user-balance-panel {
  position: relative;
  left: 0;
  z-index: 10;
  width: 100%;
  max-height: 0;
  overflow: hidden;

  &.popover__opened {
    max-height: 500px;

    @include media-xs {
      max-height: initial;
    }
  }

  &:not(.no-animation) {
    transition: max-height 0.5s ease;
  }

  @include media(1024px) {
    position: relative;
    top: 0;
    margin: 0;
  }

  .balance {
    &__scroll-wrapper {
      min-height: 140px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 10px 0;
      color: color(input, background);
      background: color(secondary, dark);

      @include media(767px) {
        flex-wrap: wrap;
        min-width: 700px;
      }

      @include media-xs {
        flex-direction: column;
        min-width: auto;
        letter-spacing: .1em;
      }
    }

    &__row {
      padding: 10px 20px 10px 0;
      position: relative;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: space-between;
      justify-content: space-between;

      img {
        width: 54px;

        @include media(767px) {
          display: none;
        }
      }

      &--bonus, &--real {
        color: color(label, unfilled);
      }

      &--total-balance, &--real, &--bonus {
        margin-top: 5px;
      }

      &--free-spin, &--award-points {
        position: relative;
        font-size: 14px;

        &::before {
          font-family: 'Glyphter-custom';
          font-size: 20px;
          position: absolute;
          top: 0;
          left: -35px;
          color: color(primary, main);
        }
      }

      &--free-spin::before {
        content: '\004b'
      }

      &--award-points:before {
        content: '\004c';
      }

      &--logout {
        .button-logout {
          margin: 0;
          padding: 0;
        }
      }

      &--link {
        .button-root {
          text-transform: capitalize;
          display: block;
          padding: 8px 13.5px 8px 13.5px;
          font-size: 12px;
        }
      }

      &--progress-circle {
        position: relative;
        width: 100px;
        height: 100px;
        margin-right: 20px;

        .circularProgress-back {
          stroke-width: 1px;
          stroke: var(--file-attachments-border);
        }

        .circularProgress-path {
          stroke-width: 2px;
          stroke: color(primary, main);
        }

        .label {
          color: color(label, unfilled);
          font-weight: 400;
          font-size: 7px;
          position: absolute;
          width: 50px;
          text-align: center;
          top: 50%;
          left: 50%;
          transform: translate(-25px, -20px);
          z-index: 10;
        }

        .value {
          position: absolute;
          text-align: center;
          top: 50%;
          left: 0;
          z-index: 10;
          color: color(input, background);
          width: 100%;
        }
      }

      &--buttons {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
        align-content: space-between;
        justify-content: space-between;
        padding: 0 20px;

        a {
          text-transform: uppercase!important;
        }
      }

      &::after {
        content: '';
        position: absolute;
        top: 34px;
        right: 0;
        height: 50px;
        border-right: 1px solid color(primary, main);
      }
    }

    &__nick-name {
      @include media-xs {
        display: none;
      }
    }

    &__loyalty {
      min-width: 170px;
      padding: 10px 20px;

      .status-for-mobile {
        display: none;
      }
      .balance__status {
        color: color(label, unfilled);

        span {
          color: color(input, background);
        }
      }

      @include media-xs {
        align-items: start;
      }

      @include media(767px) {
        order: 5;
        flex-direction: column-reverse;
        padding-left: 50px;
        align-items: start;
        font-size: 14px;

        .status-for-mobile {
          display: block;
          font-weight: 700;
          color: #7b7348;
          margin-left: 5px;
        }

        .balance__status {
          color: color(input, background);
        }

        .balance__link {
          font-size: 14px;
          text-transform: uppercase;

          a {
            display: flex;
            flex-wrap: nowrap;
          }
        }

        &::before {
          content: '\0051';
          font-family: 'Glyphter-custom';
          font-size: 20px;
          position: absolute;
          top: 10px;
          left: 15px;
          color: color(primary, main);
        }

        &::after {
          content: none;
        }
      }
    }

    &__balance {
      flex-direction: row;
      padding: 0 0 0 50px;
      text-align: left;
      align-items: center;

      @include media-xs {
        justify-content: unset;
      }

      @include media(767px) {
        &::after {
          content: none;
        }
      }

      .balance__value {
        float: right;
      }

      .balance__row--balance {
        padding-right: 20px;
        min-width: 200px;

        @include media-xs {
          width: calc(100% - 120px);
        }
      }

      .balance__row--link {
        text-transform: uppercase;
        font-size: 14px;
        margin-bottom: 12px;
      }

      &::before {
        font-family: 'Glyphter-custom';
        font-size: 20px;
        content: '\0050';
        position: absolute;
        top: 10px;
        left: 15px;
        color: color(primary, main);
      }

      &::after {
        content: none;
      }
    }

    &__wagering-requirement {
      padding: 0;

      &::after {
        content: none;
      }
    }

    &__buttons {
      padding-left: 20px;
      align-items: start;

      .button-root {
        padding: 8px 13.5px 8px 13.5px;
        margin: 0;
      }

      @include media-xs {
        order: 7;
        flex-direction: row;
        justify-content: center;
        padding: 0;

        .button-root {
          width: 50%;
          margin: 10px;
        }
      }

      @include media(1024px) {
        &::after {
          content: none;
        }
      }
    }

    &__award-points {
      padding-left: 50px;
      text-align: left;
      align-items: start;

      @include media-xs {
        justify-content: left;
      }

      @include media(767px) {
        order: 6;
        flex-direction: row;

        .balance__row--award-points {
          padding-left: 50px;

          &::before {
            left: 15px;
          }
        }
      }

      &::after {
        content: none;
      }

      .balance__row--link {
        font-size: 14px;
        text-transform: uppercase;
      }
    }
  }

  .userBalanceMenu {
    background: color(settings-menu, background);
    padding: 15px 0;

    li {
      font-size: 16px;
      text-transform: uppercase;
      text-align: center;
      margin: 15px 0;
    }

    .settings-menu__item-override {
      &--logout {
        text-transform: capitalize;
        font-size: 12px;
      }
    }
  }
}
