.tabs {
  border: 1px solid color(details-summary, border-top);

  &__tab {
    cursor: pointer;
    background-color: color(promotions-card, block-background);
    border-bottom: 1px solid color(details-summary, border-top);

    &.active {
      background-color: transparent;
      border-bottom: transparent;
    }
    &:not(:first-child) {
      border-left: 1px solid color(details-summary, border-top);
    }
  }

  &__content {
    &-list {
      min-height: 244px;
    }
  }

  &__link {
    p {
      color: var(--file-attachments-border);
      font-size: 14px;
      font-weight: 700;
      margin: 0;
      padding: 15px 0;
      text-transform: uppercase;
      letter-spacing: 0.5px;

      strong {
        color: color(footer, link);
      }
    }
  }
}
