.game-slider {
  position: relative;
  margin-left: -15px;
  width: calc(100% + 30px);
  overflow-x: hidden;

  @include media-xs {
    overflow: hidden;
    margin: 0;
    width: 100%;
  }

  .swiper {
    &-wrapper {
      position: relative;
      display: flex;
      height: 100%;
      padding: 25px 0;

      @include media-xs {
        padding: 0;
      }
    }

    &-slide {
      display: flex;
      flex: none;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      margin: 0;
      transition: 500ms ease-in-out;

      .game-thumbnail {
        height: 100%;
      }

      &:hover {
        margin: 0 0.85% !important;
        transform: scale(1.1);

        @include media-xs {
          margin: 0;
          transform: none;
        }
      }
    }

    &-button-prev,
    &-button-next {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 20px;
      height: 71px;
      cursor: pointer;
      background: rgba(12,12,12,0.75);
      transition: .2s;

      &:hover {
        background: rgba(12,12,12,1);
      }

      .home-content--slider & {
        top: 14px;
      }
      @include media-sm {
        width: 34px;
      }
      &::before {
        content: '';
        width: 10px;
        height: 10px;
        display: block;
        position: absolute;
        top: 55%;
        margin-top: -9px;
        transform: rotate(45deg);
      }
      &.is-disabled {
        opacity: 0;
        pointer-events: none;
      }
    }

    &-button-prev {
      left: 0;

      &::before {
        left: 6px;
        border-left: 1px solid color(primary, main);
        border-bottom: 1px solid color(primary, main);
      }
    }
    &-button-next {
      right: 0;

      &::before {
        right: 6px;
        border-left: 1px solid color(primary, main);
        border-bottom: 1px solid color(primary, main);
        transform: rotate(-135deg);
      }
    }
  }

  &__loading {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0;
    padding-bottom: 14.1%;
    opacity: .6;
    background-color: color(primary, light);

    @include media-md {
      padding-bottom: 17.65%;
    }
    @include media-sm {
      padding-bottom: 23.7%;
    }
    @include media-xs {
      padding-bottom: 34.8%;
    }
  }
  &__loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  &--home-page {
    .swiper-button-prev,
    .swiper-button-next {
      top: 40%;

      @include media-xs {
        display: none;
      }
    }
  }
  &--game-page {
    .game-thumbnail__badges {
      top: 0;
      right: 0;
    }
  }
}


