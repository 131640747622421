.profile-account-form {
  .payment-select-field__img {
    margin: 0;
  }
}

/** Bank account */
.profile-account-form--1001 {
  @media (min-width: 480px) {
    .profile-account-form__field {
      &--bankAccount,
      &--bankName,
      &--bankAccountOwnerName {
        max-width: 45%;
        flex-basis: 45%;
      }
    }
  }
}
/* **/
/* **/

/** Entercash - EUR */
.profile-account-form--1079 {
  @media (min-width: 480px) {
    .profile-account-form__field {
      &--ibanAccountOwnerName,
      &--ibanAccount,
      &--ibanBic {
        max-width: 45%;
        flex-basis: 45%;
      }
    }
  }
}
/* **/

/** Entercash - not EUR */
.profile-account-form--1080 {
  @media (min-width: 480px) {
    .profile-account-form__field {
      &--bankAccountOwnerName,
      &--bankAccount,
      &--bankBic {
        max-width: 45%;
        flex-basis: 45%;
      }
    }
  }
}

.stored-payment-details {
  .button-root {
    padding: 4px 6px 2px;
  }
  div[role=progressbar] svg {
    min-width: 10px;
  }
}
