.content-login {
  .mobile-page-theme {
    .container {

      label {
        color: color(label, color);
      }

      input {
        background-color: color(select, background);
      }
      .notification-root {
        border-radius: 5px;
      }
    }
  }
}
.login {
  .container {
    max-width: 800px;
  }
  &-modal,
  .authentication {
    &--regular {

      .login {
        &-form {
          padding: 0 15px 25px;

          .adornment-password {
            margin-right: 25px;
          }

          @include media-xs {
            padding-top: 15px;
          }

          .field-root {
            margin-bottom: 10px;
          }
        }

        &-register {
          background: color(input, background);
          padding: 15px 23px 25px;
          color: color(footer, background);

          @include media-xs {
            text-align: center;
          }

          p {
            font-size: 14px;
            margin-top: 0;
            padding: 4px 0px;
          }
        }
      }

      .group-root--buttons {
        position: relative;
        display: flex;
        align-items: center;
        margin: 0;

        @include media-sm {
          justify-content: left;
          padding-top: 40px;
        }

        @include media-xs {
          flex-direction: column;
          justify-content: center;
          padding: 0;

          .login__forgot-password {
            margin: 16px 0 0 0;
          }
        }

        .login__bitton {
          margin-bottom: 10px;
        }

        .login__forgot-password {
          position: absolute;
          right: 10%;
          color: color(footer, link);

          @include media-sm {
            top: 0;
            right: initial;
            left: 0;
            margin-left: 0;
          }

          @include media-xs {
            position: static;
          }
        }
        .registration__button {
          @include media(null, $size-sm) {
            display: none;
          }
        }
      }
      &__button {
        @include media-xs {
          min-width: auto;
        }
      }
    }
  }
}
