.summer150-page-content, .firstdep-50k-page-content, .streamreg-page-content, .mregkod-page-content, .streamreg-fd-page-content {
  max-width: calc(100% + 30px);
  width: calc(100% + 30px);
  margin-left: -15px;
}
.static-page-content.promotionDetailsPage {
  margin-top: -15px;
  padding-top: 15px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-attachment: fixed;
  margin-left: -15px;
  width: calc(100% + 30px);
  padding-bottom: 30px;

  .title {
    display: none;

    @include media-xs {
      display: flex;
    }
  }

  .mobile-only {
    display: none;
  }

  @include media-xs {
    display: block;
    background: none;
    padding: 0;
  }

  .content-blocks {
    max-width: 600px;
    margin: 0 auto;
    background: color(promotions-card, block-background);
    border-radius: 5px;

    @include media-xs {
      padding: 0;
      border-radius: 0;
    }

    .content-block {
      background: none;
      margin: 0;

      @include media-xs {
        padding: 0 10px;
      }

      &-sub-vip_header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .expired-notice {
          margin-left: 20px;

          span, p {
            margin: 0;
            font-size: 13px;
            line-height: 2;
          }

          @include media-xs {
            margin-left: 0;

            span, p {
              font-size: 10px;
            }
          }
        }

        .post-login-link {
          margin: 25px 20px 25px 0!important;

          @include media-xs {
            margin: 15px 0 15px 0 !important;
          }
        }

        .countdown-counter {
          display: flex;
          text-align: center;
          padding-left: 60px;
          position: relative;

          @include  media-xs {
            padding-left: 35px;
          }

          &::before {
            content: '';
            position: absolute;
            width: 35px;
            height: 35px;
            top: 6px;
            left: 11px;
            background: url('/cms/img/icons/countdown.svg') no-repeat transparent;
            background-size: cover;

            @include  media-xs {
              width: 25px;
              height: 25px;
              left: 0;
              top: 3px;
            }
          }

          .countdown {
            position: relative;
            border: 1px solid color(primary, main);
            border-radius: 10px 3px;
            overflow: hidden;

            @include media-xs {
              min-width: 30px;
            }

            &:not(:first-child) {
              margin-left: 10px;

              @include media-xs {
                margin-left: 5px;
              }
            }

            span {
              color: color(promotions-card, block-background);
              background: color(primary, main);
              text-transform: uppercase;
              display: block;

              @include media-xs {
                font-size: 7px;
                padding: 2px 3px;
              }
            }

            .value {
              display: flex;
              flex-direction: row;
              font-size: 20px;
              color: color(primary, main);
              border-radius: 3px;
              position: relative;

              @include media-xs {
                justify-content: center;
              }

              div {
                display: flex;
                width: 20px;
                height: 100%;
                font-size: 20px;
                justify-content: center;
                align-items: center;
                border-radius: 3px;
                margin: 1px;

                @include media-xs {
                  font-size: 12px;
                  width: auto;
                }
              }
            }
          }
        }
      }

      &-sub-header {
        img {
          @include media-xs {
            border-radius: 0;
          }
        }

        p {
          min-height: 35px;
          padding: 15px;
          margin: -5px 0 0 0;
          color: color(footer, background);
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: .5px;
          background: color(primary, main);
          text-align: center;

          strong {
            font-size: 20px;

            @include media-xs {
              font-size: 15px;
            }
          }
        }
      }

      &-info {
        padding: 0;
        border-radius: 0 0 5px 5px;

        @include media-xs {
          padding: 10px 8px;
          margin: 0;
        }
      }

      >  .content-block-content:not(.content-block-info) {
        padding: 0;
      }

      &--summary {
        .content-block-header {
          margin-left: 8px;
          margin-right: 8px;
          padding: 11px 0 9px;
          margin-bottom: 2px;
          border-radius: 4px 4px 0 0;
          background: color(popup, summary-background);
          box-shadow: 0 1px 0 0 color(notice, hr), 0 2px 0 0 color(notice, background);
          color: color(notice, color);
          font-size: 14px;
          font-weight: 300;
          line-height: 16px;
          text-transform: uppercase;
          letter-spacing: .5px;

          h4 {
            display: block;
            padding-top: 1px;
            margin-top: 0;
            margin-bottom: 0;
            color: color(notice, color);
            font-size: 14px;
            font-weight: 300;
            line-height: 15px;
            text-transform: uppercase;
            letter-spacing: .5px;
          }
        }

        dl {
          width: 100%;
          display: block;
          margin: 0 0 20px 0;

          @include media-xs {
            margin: 0 0 10px 0;
            font-size: 10px;
            text-align: center;
          }
        }

        dl > dt {
          width: 45%;
          font-weight: 400;
          clear: left;

          @include media-xs {
            width: 100%;
            font-size: 10px;
            color: var(--file-attachments-border);

            strong {
              color: color(secondary, dark);
            }

          }
        }

        dl > dd {
          width: 55%;

          @include media-xs {
            width: 100%;
          }
        }

        dl, dt {
          font-size: 13px;
        }

        dl > dt, dl > dd {
          display: block;
          float: left;
          line-height: 2;
          margin-inline-start: 0;
        }
      }

      .content-block-content {

        &-head {
          font-size: 16px;
          text-align: center;

          @include media-xs {
            padding: 5px;
            font-size: 12px;
            border-radius: 0 0 3px 3px;
          }
        }

        .content-block-details {
          text-align: left;
          padding: 20px 20px 30px;

          @include media-xs {
            background: none;
            padding: 10px 8px;

            .text-center {
              display: none;
            }
          }
        }
      }

      &-footer {
        padding: 0 20px 30px;
        border-radius: 0 0 5px 5px;

        p, ol li {
          font-size: 13px;
        }

        .content-block-up h4 {
          margin: 0 0 14px;
        }

        h4 {
          color: #000;
        }

        ol {
          list-style-type: decimal;

          li::before {
            content: none;
          }
        }
      }

      &-text-trigger-button {
        cursor: pointer;
        width: 100%;
        display: block;
        text-align: center;
        position: relative;
        color: color(primary, main);
        margin-top: 12px;

        &::before, &::after {
          content: ' ';
          position: absolute;
          left: 50%;
          top: calc(100% + 5px);
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 10px 0 10px;
          border-color: color(link, hover) transparent transparent transparent;
        }

        &::after {
          top: calc(100% + 5px - 2px);
          border-color: color(background, body) transparent transparent transparent;
        }

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }

        &.btn-close {
          &::before, &::after {
            border-width: 0 10px 10px 10px;
            border-color: transparent transparent color(link, hover) transparent;
          }

          &::after {
            top: calc(100% + 5px + 2px);
            border-color: transparent transparent color(background, body) transparent;
          }
        }
      }

      .post-login-link {
        margin: 0 auto;
        max-width: 250px;
        padding: 6px 50px;
        font-size: 16px;
        line-height: 1.5;

        @include media-xs {
          padding: 10px;
          font-size: 12px;
        }
      }

      &-video-btn {
        display: block;
        margin: 20px auto;
        font-size: 14px;
        text-align: center;
        text-decoration: underline;

        @include media-xs {
          margin: 0 auto 10px auto;
          font-size: 12px;
        }

        &::before {
          font-family: 'Glyphter-custom';
          content: '\0049';
          display: inline-block;
          height: 24px;
          width: 36px;
          vertical-align: middle;
          font-size: 18px;
          margin-top: -3px;

          @include media-xs {
            content: none;
          }
        }
      }

      .btn {
        display: block;
        background-color: color(primary, main);
        color: color(background, body);
        height: 50px;
        padding: 13px 15px 11px;
        font-size: 16px;
        line-height: 1.5;
        border-radius: 5px;
        text-transform: uppercase;

        @include media-xs {
          font-size: 14px;
          height: max-content;
          padding: 6px 10px;
        }
      }

      #content-block-text-trigger + .content-block-footer {
        display: none;

        @include media-xs {
          display: block;
          background: #fff;
          padding: 10px 10px 20px 10px;
          margin-left: -10px;
          width: calc(100% + 20px);
          color: var(--file-attachments-border);

          .text-center {
            display: none;
          }

          h4 {
            font-size: 12px;
            text-transform: uppercase;
          }

          p, ol, li {
            font-size: 10px;
          }
        }
      }

      #content-block-text-trigger:checked + .content-block-footer {
        display: block;
      }
    }
  }
}

.promotionDetailsPage__otherPromotionsTitle {
  display: inline-block;
  margin-left: 50%;
  padding: 5px 15px;
  background: color(background, header);
  color: color(primary, main);
  font-size: 18px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transform: translateX(-50%);
  margin-top: 30px;

  @include media-xs {
    display: none;
  }
}

.promotionDetailsPage__otherPromotionsBlock {

  padding-top: 40px;

  .promotions-page {
    position: relative;
    max-width: calc(100% + 30px);
    width: calc(100% + 30px);
    margin-left: -15px;

    .content-blocks {
      max-width: 1280px;
      margin-left: auto;
      margin-right: auto;
    }

    &::before {
      content: '';
      width: calc(100% + 30px);
      height: calc(100% + 45px);
      position: absolute;
      top: -15px;
      left: -15px;
      background: url('/cms/img/promotions/promotions-bg.jpg') no-repeat;
      background-size: cover;
      z-index: -1;

      @include media(1024px) {
        height: 330px;
      }

      @include media(168px) {
        height: 360px;
      }
    }
  }

  @include media-xs {
    display: none;
  }
}
