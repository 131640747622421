.game-name-filter {
  &__icon {
    &::before {
      @include svg-icon;
      content: '\0059';
      vertical-align: middle;
      @include media-sm {
        padding: 0 5px;
      }
    }
  }
}
