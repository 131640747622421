.post-login-content-wrap--current-bonuses {
  .mobile-table-row {
    background: var(--input-background);
    padding: 10px 15px;
    border-radius: 5px;
    margin: 10px 0;

    .hidden {
      height: 0;
      margin: 0;
      overflow: hidden;
      color: inherit;

      dt, dd {
        text-transform: initial;

        .button-root {
          padding: 4px 6px 2px;
          min-height: auto;
          min-width: auto;
          font-size: 9px;
          line-height: 1.3;
          letter-spacing: .5px;
        }

        p {
          margin: 0;
        }
      }
    }

    .show-hidden + .hidden {
      height: auto;
      padding: 10px 15px;
      margin: 10px 0 0 -15px;
      display: block;
    }

    dl {
      margin: 0;
      padding: 0;
      transition: all 0.5s ease;

      dt, dd {
        line-height: 1.5;
        display: inline-block;
        color: var(--file-attachments-border);
      }

      dt {
        width: 40%;
        font-weight: normal;
      }

      dd {
        width: 60%;
      }
    }
  }
}
