.account__content {
  .fields-container {
    .gridItem-root {

      &__lastName {
        order: 1;
      }
      &__firstName {
        order: 3;
      }
      &__mothersMaidenName {
        order: 2;
      }
      &__maidenName {
        order: 6;
      }
      &__birthPlace {
        order: 5;
      }
      &__citizenship, &__phoneNumber, &__receiveEmail, &__campaignsEnabled {
        order: 7;
      }

      &__receiveEmail,
      &__receiveSms,
      &__campaignsEnabled,
      &__publicPerson {
        max-width: 100%;
        flex-basis: 100%;
      }
    }
  }
}
.profile-form {
  &__verify {
    border-radius: 50%;
    min-height: 26px;
    min-width: 26px;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: color(error);

    & > .button-label:before {
      @include svg-icon;
      content: '\00b1';
    }
  }
  &__notifications {
    > div {
      margin-bottom: 10px;

      &:empty {
        margin-bottom: 0;
      }
    }
  }
}
.cookiebot-text {
  color: #5E6061;
  text-decoration: none;
  &:hover {
  text-decoration: none;
  color: #5E6061;

  }
 }
