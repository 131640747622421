.lvcVerification {
  position: relative;
  min-height: 100vh;
  overflow: hidden;

  @include media-xs {
    position: initial;
    min-height: initial;
    overflow: initial;
  }

  &::after {
    @include svg-icon('custom');
    content: '\0051';
    position: absolute;
    font-size: 550px;
    right: -50px;
    bottom: -60px;
    color: #ededee;
    z-index: -1;

    @include media-xs {
      display: none;
    }
  }

  &__header-wrapper {
    .lvcVerification {
      &__title {
        position: relative;
        text-align: center;
        font-size: 18px;
        color: color(input, background);
        margin: 0;
        padding: 30px 0;
        font-weight: 400;
        background: color(modal, header);
        min-width: 580px;
        line-height: 1;
        text-transform: uppercase;

        @include media-xs {
          text-align: left;
          min-width: auto;
        }

        &.quickVerify {
          @include media(null, $size-xs) {
            font-size: 18px;
            padding: 27px 0;
            text-transform: uppercase;
          }
        }
      }
      &__close {
        @include close-button;
        @include size(32px);
        background: #464546;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);

        &:hover {
          background: #464546;
        }
      }
    }
  }
  &__notification-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .notification {
      &-root {
        padding: 19px 29px;
        justify-content: center;
        font-size: 16px;
        border-radius: 5px;
        max-width: 722px;
        margin: 30px 0 0 0;

        p {
          font-size: 16px;
          color: color(select, background);
        }
      }

      &-message {
        width: 100%;
        text-align: center;
      }
    }
    @include media-xs {
      margin: 10px;
    }
  }
  &__content-wrapper {
    padding: 0 0 30px;

    @include media-xs {
      padding: 0;
    }

    .lvcVerification {
      &__header {
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 0.5px;
        margin: 30px 0;
        text-align: center;
        color: color(primary, main);

        @include media-xs {
          margin: 10px 0;
          font-size: 12px;
          letter-spacing: initial;
          text-transform: uppercase;
          text-align: left;
          color: color(secondary, dark);
        }
      }
      &__buttons {
        margin-top: 40px;

        @include media-xs {
          margin-top: 20px;
          text-align: left;

          .button-root {
            margin: 0;
            min-height: 35px;
          }

          > div {
            display: flex;
            flex-direction: column;
            justify-content: left;
            align-items: self-start;
            gap: 20px;
          }
        }

        @include media(null, $size-xs) {
          .button-root {
            margin: 0 10px;
          }
        }
      }
      &__mandatory {
        margin-top: 42px;
        text-align: center;

        @include media-xs {
          display: none;
        }
      }
    }
    & > .container {
      max-width: 920px;

      @include media-xs {
        padding: 0 20px 20px;
      }

      label {
        font-size: 14px;
      }
    }
  }
  &__support {
    display: block;
    color: var(--file-attachments-border);
    background-color: color(input, background);
    padding: 10px 10px 20px;
    margin: 20px -20px -20px;
  }
}
.verification.mobile-page-theme > .container .verification--lvcVerification {
  padding: 0;
}
.content-verification .container > .title {
  display: none;
}
