.authorization-required-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  > .grid-root > .gridItem-root {
    max-width: 800px;
  }
}
