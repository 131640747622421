.is-logged-in {
  .home-banner {
    max-height: 550px;
    min-height: 420px;
    @include webp-bg-img('/cms/img/main-bg-postlogin.webp', '/cms/img/main-bg-postlogin.jpg');
    background: {
      color: color(primary, dark);
      position: center;
      repeat: no-repeat;
      size: cover;
    }

    @include media-md {
      max-height: 500px;
    }
    h2 {
      margin-top: 60px;
      margin-bottom: 30px;
      font-size: 24px;
      color: color(primary, light);
    }
  }
}
.promoted-game-slider {
  position: relative;
  overflow: hidden;

  &__wrapper {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    padding-bottom: 40px;
    color: color(primary, light);
  }
  &__slide {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    flex: none;
    flex-shrink: 0;
    background: none;

    .image-img {
      max-width: 50%;
      border: 4px solid color(primary, light);

      @include media-xs {
        max-width: 40%;
      }
    }
    .image-content {
      position: static;
      max-width: 50%;
      height: 100%;
      padding-left: 30px;
      text-align: left;

      @include media-xs {
        max-width: 60%;
        padding: 0 20px;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  &__title {
    font-size: 18px;
    text-transform: uppercase;

    @include media-xs {
      font-size: 16px;
    }
  }
  &__description {
    font-size: 14px;
  }
  &__button {
    margin-top: 10px;

    .button-label {
      width: auto;

      &::before {
        content: '';
        width: 0;
        height: 0;
        padding-right: 10px;
        border-style: solid;
        border-width: 8px 0 8px 16px;
        border-color: transparent transparent transparent #2f2f2f;
      }
    }
  }
  &__pagination {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);

    .swiper-pagination-bullet {
      position: relative;
      display: inline-block;
      width: 18px;
      height: 18px;
      margin-right: 5px;
      border-radius: 100%;
      border: 2px solid color(primary, light);
      cursor: pointer;

      &-active {
        &::before {
          content: '';
          position: absolute;
          top: 2px;
          left: 2px;
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: color(primary, light);
        }
      }
    }
  }
}
