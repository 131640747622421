.select-locale {
  height: 40px;
  text-align: center;

  .select-icon {
    right: -15px;
    display: none;
  }
  &__item {

    &:focus {
      border-left: 5px solid transparent;
      background: none;
    }
    &:hover {
      border-left: none;
      background: none;
    }
  }
  &__flag {
    flex: 0 0;
    max-width: none;
    margin-right: 10px;
  }
  &__lang-name {
    margin-right: 10px;
  }
  .select-selectMenu {

    .select-locale__item:hover {
      border-left-color: transparent;
    }
  }
  .field-select {
    & > div {
      &::before {
        display: none;
      }
      &:hover {
        &::before {
          display: none;
        }
      }
    }
  }
  .select-focused {
    border: none;

    &::before,
    &::after {
      display: none;
    }
  }
  ul {
    padding-bottom: 5px;
  }
  &__selector {
    .select-menuPaper {
      margin-left: -5px;
      min-width: auto !important;
      background: #fff;
      width: 200px;

      li {
        padding: 0;
        color: color(primary, main)!important;

        .select-locale__lang-name {
          display: block;
          width: 100%;
          margin: 0;
          padding: 5px 10px;
        }

        .selected-locale {
          color: #fff;
          background: skyblue;
        }

        &:not(:last-child) {
          margin-bottom: 5px;
        }
      }
    }
  }
  .field-root {
    display: inline-flex;

    .field-helper {
      display: none;
    }

    .radioGroup-root {
      label {
        @include size(40px);
        flex: initial;
        min-width: initial;

        .formControlLabel-label {
          border: none;

          .select-locale__flag {
            margin: 0;
          }
        }

        .radio-checked + .formControlLabel-label {
          border-color: initial;
          box-shadow: 0 0 0 2px #e19c00;
        }
      }
    }
  }
}
