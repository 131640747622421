.content-activation {
  background: color(input, background);

  .customize-decorator {
    &--header {
      display: none;
    }

    &--content {
      padding: 0;

      .button-root {
        position: fixed;
        top: 20px;
        right: 30px;
        width: 32px;
        height: 32px;
        line-height: 32px;
        color: color(secondary, main);
        background: var(--file-attachments-border);
        text-align: center;
        text-decoration: none;
        border-radius: 50%;
        min-width: auto;
        font-size: 12px;
        padding: inherit;

        &:hover {
          text-decoration: underline;
        }
      }

      .container {
        padding: 0;
        margin: 40px 0;
      }
    }
  }

  .mobile-page-theme {
    .container {
      background: color(footer, background);
      border-radius: 0 0 5px 5px;
      border: none;
      text-align: left;
    }
    .title + .activation-page {

      @include media-xs {
        padding: 0;
      }
    }
  }

  .notification-root {
    border-radius: 5px;
    font-weight: bold;
  }
}

.activation-page {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @include media-xs {
    justify-content: inherit;
    background: color(background, body);
    padding-bottom: 15px!important;

    .container {
      margin: 0;
    }
  }

  &__title {
    color: color(primary, main);
    font-size: 24px;
  }

  &__content {
    .error-wrapper {
      height: 450px;
      justify-content: center;
      align-items: center;
      display: flex;

      @include media-xs {
        height: auto;
      }

      .button-root {
        @include close-button;
        position: absolute;
        top: 20px;
        right: 30px;
      }
    }

    @include media-xs {
      padding: 10px;
    }
  }

  .mobile &__content .button-default,
  .mobile &__content .activation-page__description {
    display: none;
  }
}
