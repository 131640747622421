body .vipServices_section {
  margin-top: -35px;

  .header_section {
    width: calc(100% + 30px);
    position: relative;
    left: -15px;

    .regular-vip_title {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;
      background-color: color(secondary, dark);
      margin-bottom: 0;
      color: color(footer, link);
      font-size: 18px;
      line-height: 24px;
      font-weight: normal;
      text-transform: uppercase;

      @include media-xs {
        width: 100vw;
        height: 56px;
        font-size: 14px;
        letter-spacing: 1.2px;
      }
    }
  }

  .main-section {
    margin: 0 -15px;
    font-size: 14px;

    .vipServices {
      &__header {
        background-image: url('/cms/img/loyalty-program/vipservices-mainbg.jpg');
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
        height: 830px;

        @include media-xs {
          height: max-content;
          background-image: url('/cms/img/loyalty-program/vipservices-mainbg-mobile.jpg');
          background-size: 150%;
        }

        > div {
          width: 500px;
          height: 100%;
          left: 50%;
          float: none;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-right: 20px;
          transform: translateX(30%);
          position: relative;

          @include media(1280px) {
            float: right;
            position: initial;
            transform: none;
          }

          @include media-xs {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 0 10px;
            float: none;
            width: auto;

            > div:first-child {
              position: relative;
              background: color(secondary, dark);
              margin: 220px -10px 0;
              padding: 0 10px;
              overflow: hidden;
              z-index: 0;

              :after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                transform: translateX(20%);
                background-image: url('/cms/img/promotions/star.svg');
                background-size: 95%;
                background-repeat: no-repeat;
                background-position: center right;
                z-index: -1;
              }
            }
          }
        }

        h3 {
          color: color(footer, link);
          font-size: 50px;
          line-height: 65px;
          font-weight: 400;
          text-transform: uppercase;

          @include media-xs {
            font-size: 35px;
            line-height: 45px;
            margin-top: 20px;
            margin-bottom: 10px;
          }
        }

        p {
          margin-bottom: 30px;
          color: color(promotions-card, block-background);
          font-size: 20px;
          line-height: 25px;
          font-weight: 400;

          @include media-sm {
            font-size: 16px;
            line-height: 20px;
            margin: 0 0 30px 0;
          }
        }
      }

      &__info {
        display: flex;
        flex-direction: column;

        &-title {
          text-align: center;

          h3 {
            margin: 0;
            padding-top: 30px;
            padding-bottom: 15px;
            color: color(primary, light);
            text-transform: none;
            font-size: 45px;
            line-height: 61px;
            font-weight: 400;

            @include media-sm {
              font-size: 25px;
              line-height: 34px;
              padding-top: 8px;
              padding-bottom: 0;
            }
          }

          &--text {
            color: color(secondary, dark);
            text-transform: uppercase;
            font-size: 18px;
            line-height: 22px;
            font-weight: 700;
            margin-bottom: 40px;
            margin-top: 0;

            @include media-sm {
              font-size: 16px;
              line-height: 22px;
              margin-bottom: 30px;
            }
          }
        }

        &-block {
          background: color(primary, main);
          text-align: center;
          padding-bottom: 25px;

          img {
            padding: 25px 10px 10px;
            max-width: 95px;
          }

          .vipServices-container {
            display: flex;

            @include media-sm {
              flex-wrap: wrap;
            }

            > div {
              flex-basis: 25%;
              padding: 0 15px;

              @include media-sm {
                flex-basis: 50%;
              }
            }
          }

          &--title {
            margin: 0 -2px;
            color: color(secondary, dark);
            font-size: 18px;
            line-height: 22px;
            font-weight: 600;
            text-transform: uppercase;

            @include media-sm {
              font-size: 14px;
              line-height: 18px;
            }
          }

          &--range {
            color: #404040;
            font-size: 18px;
            line-height: 22px;
            font-weight: 600;
            margin-top: 0;

            @include media-sm {
              font-size: 14px;
              line-height: 18px;
            }
          }
        }

        &-desc {
          text-align: center;
          padding: 5px 0 223px;
          display: flex;

          @include media-xs {
            padding: 30px 0 180px;
            margin-bottom: 40px;
          }

          &--title {
            padding-top: 40px;
            padding-right: 0;
            margin-bottom: 0px;
            color: color(secondary, dark);
            text-transform: uppercase;
            font-size: 18px;
            line-height: 22px;
            font-weight: 700;
            margin-top: 0;

            @include media-sm {
              font-size: 16px;
              line-height: 20px;
              font-weight: 700;
              padding-top: 0;
            }
          }

          &--text {
            padding-right: 0;
            font: normal 18px/22px Nunito;
            color: var(--loyality-program-color);
            margin-top: 0;

            @include media-sm {
              font-size: 16px;
              line-height: 20px;
            }
          }

          .vipServices-container {
            display: flex;

            @include media-md {
              flex-direction: column;
            }

            > div {
              flex-basis: 50%;

              @include media-xs {
                margin-bottom: 10px;
              }
            }
          }
        }

         > div:nth-child(odd) {
          background: color(footer, link);
        }
      }

      &__loyalty {
        margin-top: -196px;
      }
    }
  }

  .vipServices__blocks {
    text-align: center;
    padding: 0px 20px;

    @include media-xs {
      margin-bottom: 20px!important;
    }

    h3:first-child {
      margin-top: 55px;

      @include media-xs {
        margin-top: 30px;
      }
    }

    > div h3 {
      color: color(footer, link);
      text-transform: none;
      margin-top: 25px;
      font-size: 38px;
      line-height: 24px;
      font-weight: 300;

      @include media-xs {
        font-size: 30px;
      }
    }

    > div p {
      color: var(--loyality-program-color);
      margin-bottom: 30px;
      padding-left: 10%;
      padding-right: 10%;
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;

      @include media-xs {
        font-size: 12px;
        padding: 0;
        line-height: 18px;
      }
    }
  }

  .vipServices__blocks-images {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    justify-content: center;
    grid-gap: 10px;

    @include media-xs {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .vipServices__block {
      position: relative;
      border-radius: 20px 5px 20px 5px;
      overflow: hidden;

      &-text {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0.3s, opacity 0.3s;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p:first-child {
          color: #ededed;
          font-size: 28px;
          line-height: 28px;
          font-weight: 700;
          margin-bottom: 5px;
        }

        p:last-child {
          color: #bab7b7;
          font-size: 16px;
          line-height: 18px;
          margin-top: 5px;
        }
      }

      &:hover .vipServices__block-text {
        border-radius: 20px 5px 20px 5px;
        background: rgba(12, 12, 12, 0.8);
        visibility: visible;
        opacity: 1;
      }
    }

    .vipServices__block-image img {
      object-fit: cover;
    }

    .vipServices__block-content, .vipServices__block-image, .vipServices__block-image img {
      width: 100%;
      height: 100%;
    }

    .vipServices__block-content {
      position: relative;
      overflow: hidden;
    }

    .vipServices__block:nth-child(1) {
      grid-column: 1 / 3;
      grid-row: 1 / 1;
    }

    .vipServices__block:nth-child(2) {
      grid-column: 1 / 3;
      grid-row: 2 / 2;
    }

    .vipServices__block:nth-child(3) {
      grid-column: 3 / 3;
      grid-row: 1 / 3;
    }
  }

  .vipServices-container {
    padding: 0 20px;
    max-width: 1300px;
    margin: auto;
    position: relative;

    @include media-xs {
      padding: 0 10px;
      position: relative;
    }
  }
}

[data-url="/loyalty-program"] {
  @include media-xs {
    .title {
      display: none;
    }
  }
}
