.post-login-content-wrap--time-out {
  h2 {
    display: none;
  }

  legend {
    margin-top: 20px;
    margin-bottom: 10px;
    color: var(--body-text-dark-color);
  }

  .limit-form {
    &--container {
      padding: 0;

      @include media-xs {
        padding: 15px;
      }
    }
    &__content, &__actions, &__time {
      max-width: 100%;
      flex-basis: 100%;
    }
    &__time {
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      @include media-md {
        grid-template-columns: repeat(2, 1fr);
      }
      @include media-sm {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }
    }
    &__actions {
      display: flex;

      @include media-xs {
        justify-content: center;
      }
    }
  }
}
