.aside-menu {
  display: grid;
  grid-template-areas: "locale close"
                       "payment payment"
                       "menu menu"
                       "logout logout";
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto 1fr auto;
  max-width: 100vw;
  width: 220px;
  height: 100%;

  &-modal {
    .modal-paper {
      background-color: rgba(33,31,34,.9);
      justify-content: center;
      align-items: center;
      text-align: center;
      @include media-xs-landscape {
        width: 100%;
        height: 100%;
        max-height: 100%;
        margin: 0;
      }
      .modalContentWrapper {
        width: 100%;
        .modal-contentRoot {
          display: flex;
          flex-direction: column;
          margin: 0 auto;
        }
        .live-chat-button {
          width: 220px;
          margin: 5px 0;
        }
      }
    }
  }

  .mobile &,
  .tablet & {
    @include customVh;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .welcome {
    color: color(modal, title-mobile);
    padding: 15px 10px 0;

    &-title {
      margin-bottom: 12px;
      box-shadow: none;
      padding: 0;
      word-break: break-all;
    }

    &-details {
      color: color(label, color);
      margin-bottom: 12px;
      background: none;
      border: none;
      box-shadow: none;
      padding: 0;

      & > .group-root {
        padding-bottom: 2px;
      }

    }
  }

  &__locale {
    .select-select {
      max-width: 100%;
      height: auto;
      padding: 12px 15px 10px;
      line-height: normal;
      font-size: 12px;
      border: 0;
      border-radius: 0;
      color: color(primary, main);
      background: none;
      box-shadow: none;
      text-transform: uppercase;

      .select-locale__item {
        text-indent: 20px;
      }
    }

    .select-select::before {
      content: "\f1ab";
      font: normal normal normal 14px/1 FontAwesome;
      position: absolute;
      top: 13px;
    }
  }
  &__deposit, &__withdrawal {
    margin: 5px 0;
    font-size: 14px;
    padding: 12px 15px 10px
  }
  &__resume {
    display: block;
    margin: 0 auto;
    font-weight: $font-weight-base;
  }
  &__login {
    padding: 20px 22px;
    width: 100%;
    font-size: 18px;
  }
  &__logout {
    grid-area: logout;
    padding: 0 0 18px 46px; // margin is working incorrectly with grid-area in Firefox

    .is-ios & {
      padding-bottom: 100px;
    }
  }
  &__sign-in {
    display: block;
    font-weight: $font-weight-base;
  }
  &__links-wrapper {
    grid-area: menu;
    flex-direction: column;
    grid-column: span 2;
    width: 100%;
  }
  &__hot-links {
    grid-area: payment;
    grid-column: span 2;
    width: 100%;
    padding: 0 10px 15px 10px;

    button {
      border-radius: 4px;
      font-size: 12px;
      line-height: 1.5;
      padding: 7px 10px 6px;
      font-weight: 300;
      min-height: 33px;
      min-width: 50px;
    }
  }
  &__close {
    @include close-button();
    position: relative;
    grid-area: close;
    margin: 32px 16px;
    cursor: pointer;

    &::before {
      font-size: 18px;
    }
  }

  a.button-accent {
    color: color(primary, dark);
    display: inline;
  }
  a {
    text-decoration: none;
    color: var(--aside-menu-link-color);
    transition: color $main-transition-time;

    &:hover,
    &.active {
      text-decoration: none;
    }

    &::before {
      transition: color $main-transition-time;
    }
  }
  //TODO: refactor the remaining part
  .detailsSummary-root {
    padding: 0;
    min-height: 0;
  }
  .detailsSummary-content {
    margin: 0;
  }
  .settings-menu__item-override {
    & a {
      position: relative;
      display: block;
      cursor: pointer;
      margin: 10px 0;
      font-size: 18px;
      text-transform: uppercase;
      color: color(primary, main);
    }
    .details-root {
      padding: 22px 20px 25px 46px;
      border: none;
      border-bottom: 1px solid var(--aside-menu-border-color);
      box-shadow: none;
      background: color(background, body);

      &.details-expanded {
        .detailsSummary-content {
          font-weight: $font-weight-alt;

          &::after {
            transform: rotateX(180deg)
          }
        }
      }

      &.menuAsideResponsibleGambling {
        margin-top: 14px;
        padding-top: 0;
        padding-bottom: 0;
        border-bottom: none;
      }
    }
    .detailsSummary-content {
      &::after {
        content: '';
        position: absolute;
        top: 45%;
        right: 20px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid var(--aside-menu-link-color);
      }
    }


  }
  [class*='menuAsideGameGroups'] {
    .settings-menu__item-override {
      a {
        margin-bottom: 22px;
        margin-left: 40px;

        &::before {
          @include svg-icon;
          content: '\0078';
          position: absolute;
          top: 1px;
          left: -13px;
          color: var(--aside-menu-link-color);
          font-size: 20px;
        }
        &:hover,
        &.active {
          &::before {
            color: color(main-menu, link-active);
          }
        }
        &[href*='favourite'] {
          &::before {
            content: '\0058';
            font-size: 22px;
          }
        }
        &[href*='popular'] {
          &:not(:hover):not(.active) {
            color: color(error);

            &:before {
              color: color(error);
            }
          }

          &::before {
            content: '\0079';
          }
        }
        &[href*='slots'] {
          &::before {
            content: '\007a';
          }
        }
        &[href*='jackpot'] {
          &::before {
            content: '\0030';
          }
        }
        &[href*='table'] {
          &::before {
            content: '\0031';
          }
        }
        &[href*='betting'] {
          &::before {
            content: '\002c';
          }
        }
        &[href*='liveBlackjack'] {
          &::before {
            content: '\002d';
          }
        }
        &[href*='livePoker'] {
          &::before {
            content: '\002e';
          }
        }
        &[href*='liveRoulette'] {
          &::before {
            content: '\002f';
          }
        }
        &[href*='liveBaccarat'] {
          &::before {
            content: '\005b';
          }
        }
        &[href*='liveAll'] {
          &::before {
            content: '\005c';
          }
        }
        &[href*='promo'] {
          &::before {
            content: '\007e';
          }
        }
      }
    }
  }
}
