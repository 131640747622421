.home-menu {
  position: relative;
  display: flex;
  align-items: center;
  border: none;
  text-align: center;
  transition: all $main-transition-time;

  .settings-menu__item-override--more {
    text-transform: capitalize;

    &:active {
      color: color(primary, main);
    }

    .caret {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 2px;
      vertical-align: middle;
      border-top: 4px solid;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }
  }

  .more-container {
    position: absolute;
    width: 200px;
    z-index: 9999999999;
    display: block;
    background: color(select, background);
    border-radius: 5px;
    top: 52px;
    left: 0px;
    text-align: left;
    padding: 5px 0;

    a {
      color: color(primary, main);
      padding: 3px 20px;
      width: 100%;

      &::before {
        box-shadow: none;
      }

      &:hover {
        background: rgba(0,0,0, 0.15);
      }
    }

    &--hidden {
      display: none;
    }
  }

  &.swiper-root {
    position: relative;
    overflow-x: hidden;
    padding: 0 20px;

    .swiper-wrapper {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 100%;
    }

    .swiper-prev,
    .swiper-next {
      @include size(30px, 100%);
      position: absolute;
      z-index: 2;
      top: 0;

      &::before {
        @include svg-icon;
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        color: color(primary, accent);
        content: '\0062';
        font-size: 10px;
        transition: opacity $main-transition-time;
      }

      &.is-disabled {
        pointer-events: none;

        &::before {
          opacity: .1;
        }
      }
    }
    .swiper-prev {
      left: 0;
      background-image: linear-gradient(to right, var(--home-menu-swiper-from) 66%, var(--home-menu-swiper-to) 100%);

      &::before {
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }
    .swiper-next {
      right: 0;
      background-image: linear-gradient(to left, var(--home-menu-swiper-from) 66%, var(--home-menu-swiper-to) 100%);

      &::before {
        transform: translate(-50%, -50%);
      }
    }
  }

  .settings-menu__item-override {
    transform: scale(1);
    transition: all $main-transition-time;

    &--search-modal {
      margin-left: auto;

      .mobile & {
        margin-left: 0;
      }

      .button-root {
        min-width: 0;
        padding: 0;

        &:hover {
          background: none;

          .button-label {
            &::before {
              color: color(primary, main);
            }
          }
        }
        .button-label {
          &::before {
            @include svg-icon;
            content: '\0059';
            font-size: 20px;
            font-weight: $font-weight-alt;
            transition: $main-transition-time;
          }
        }
      }
    }
    &--search {
      flex: 1;
      text-align: right;
      transform-origin: right;
    }
  }

  &__wrapper {
    position: relative;
    z-index: 20;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px;

    @include media-xs {
      flex-direction: column;
      margin: 10px 10px 0;
    }

    .game-provider-filter, .game-group-filter, .games-filter__name {
      width: 300px;
      height: 38px;
      margin: 10px;
      position: relative;

      @include media-xs {
        width: 100%;
        margin: 5px 0;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 2.5%;
        width: 95%;
        border-top: 1px solid color(primary, main);
        transform: inherit;
        background: none;

        @include media-xs {
          content: none;
        }
      }

      .button-root {
        width: 100%;
        height: 38px;
        margin: 0;
        padding: 10px 15px;
        justify-content: left;
        color: var(--file-attachments-border);
        text-transform: inherit;
        font-size: 16px;

        @include media-xs {
          background: var(--home-menu-background-mobile);
          border-radius: 3px;
          font-size: 12px;
        }

        &:hover {
          background: none;

          @include media-xs {
            background: var(--home-menu-background-mobile);
          }
        }

        &:after {
          font-family: 'Glyphter-custom';
          font-size: 10px;
          content: '\004e';
          width: 10px;
          height: 6px;
          display: flex;
          align-items: center;
          color: color(primary, main);
          transition: transform .3s;
          position: absolute;
          top: 50%;
          right: 10px;

          @include media-xs {
           color: inherit;
          }
        }
      }

      &-popper {
        width: 100%;
      }

      &-tooltip {
        width: 100%;
        max-height: 300px;
        overflow: auto;
        padding: 0;
        opacity: 1;
        margin: 0;
        background: var(--body-background);
        border-radius: 0 0 3px 3px;
        border: 1px solid color(primary, main);
        border-top: none;

        @include media-xs {
          border: none;
          background: var(--home-menu-background-mobile);
          margin-top: 3px;
          border-radius: 3px;
        }

        .field-checkbox {
          width: 100%;
          padding: 0 10px;

          .checkbox-default, .checkbox-checked {
            @include media-xs {
              @include size(15px);

              .field-helper {
                font-size: 0;
              }
            }
          }

          .checkbox-default + span, .checkbox-checked + span {
            color: var(--file-attachments-border);
            font-size: 16px;

            @include media-xs {
              font-size: 12px;
            }
          }
        }
      }

      .open {
        border-radius: 3px 3px 0 0;
        border: 1px solid color(primary, main);
        border-bottom: none;
        padding: 9px 14px 10px;

        @include media-xs {
          border: none;
          padding: 10px 15px;
        }

        &::after {
          transform: rotate(180deg) translateY(0%) translateX(0%)
        }
      }

      .input-input {
        background: none;
        padding: 10px 15px;
        margin: 0;
        height: 38px;
        color: var(--file-attachments-border);
        font-size: 16px;

        @include media-xs {
          background: var(--home-menu-background-mobile);
          border-radius: 3px;
        }

        &::placeholder {
          font-size: 16px;

          @include media-xs {
            font-size: 12px;
          }
        }
      }

      .input-before {
        margin-right: 10px;
        color: color(primary, main);

        @include  media-xs {
          background: #868686;
          margin: 0;
          width: 38px;
          height: 38px;
          justify-content: center;
          align-items: center;
          display: flex;
          border-radius: 3px 0 0 3px;
        }
      }
    }

    .games-filter__name {
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;

      @include media-xs {
        flex-direction: row;
      }
    }

    .game-provider-filter, .game-group-filter {
      position: relative;

      ::-webkit-scrollbar {
        -webkit-appearance: none;
      }

      ::-webkit-scrollbar:vertical {
          width: 7px;
      }

      ::-webkit-scrollbar:horizontal {
          height: 7px;
      }

      ::-webkit-scrollbar-thumb {
          background: #23252F;
          border-radius: 4px;
      }

      &-button {
        .button-label {
          display: block;
          min-width: 0;
          overflow: hidden;
          text-align: left;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-right: 10px;
        }
      }
    }

    .games-filter__name::before {
      content: none;
    }
  }

  &__inner {
    position: relative;
    padding: 0;
    flex-grow: 1;
  }
  a {
    display: inline-block;
    margin: 0;
    padding: 16px 15px;
    position: relative;
    text-decoration: none;
    border-radius: 0;
    white-space: nowrap;
    color: var(--home-menu-item-color);
    transition: color $main-transition-time;
    font: 12px/1.5 'Roboto', sans-serif;
    &:hover,
    &.active {
      color: color(primary, main);

      &::before {
        color: color(primary, main);
      }
    }

    &.game-search-button {
      flex: 1;

      &::before {
        @include svg-icon;
        content: '\0059';
        vertical-align: middle;
      }
      &::after {
        display: none;
      }
    }
  }
  li:not(:first-child) {
    a {
      &::before {
        @include regular-vegas-separator
      }
    }
  }
}
