.promotion-card {
  max-width: 300px;
  min-width: 240px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  @include media-md {
    width: calc(50% - 20px);
  }

  @include media-xs {
    width: calc(100% - 20px);
    margin: 0 0 10px 0;
  }

  &__body {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    color: color(promotions-card, text);

    img {
      margin-bottom: 10px;
      border-radius: 3px;
    }
  }

  &__footer {
    text-align: center;
    .button-primary {
      padding: 11px 15px 11px;
    }
  }

  &__title {
    @include truncate;
    position: relative;
    margin: 0 0 2px 0;
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    text-transform: uppercase;
    color: color(secondary, dark);
  }

  &__content {
    position: relative;
    margin: 0 6px 14px 6px;
    font-size: 12px;
    text-align: left;
  }

  &__turnover {
    color: #888;
    font-size: 11px;
  }

  &__terms-link {
    display: block;
    margin-bottom: -5px;
    padding: 7px 10px 6px;
    font-size: 12px;
    text-align: center;
    color: color(promotions-card, link);
  }
  &__conditions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  &__condition {
    margin: 0 5px 0 0;
    color: color(secondary, dark);
    font-size: 12px;
    p {
      margin: 0;
      white-space: nowrap;
      font-weight: 300;
    }
    strong, span {
      line-height: 1.5;
      font-weight: 300;
    }
    .markdown-root {
      em {
        font-size: 8px;
        font-style: normal;
      }
      p {
        line-height: 1.5;
      }
    }
  }
}
