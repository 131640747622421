.forgot-password-modal {
  .modal-paper {
    .modalContentWrapper {
      .modal-titleRoot {
        h2 {
          font-size: 24px;
          font-weight: 300;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          margin: 14px 0 5px;
        }
      }
      .modal-contentRoot {
        .markdown-root {
          p {
            margin: 0 0 10px 0;
          }
        }
      }
    }
  }
}
