.protection {
  position: relative;
  width: 100%;
  height: 100%;
  background: url('/cms/img/bg.png') top center;
  background-size: cover;

  .media-sm {
    display: none;
  }

  .media-md {
    display: block;
  }

  @include media-sm {
    .media-sm {
      display: block;
    }
    .media-md {
      display: none;
    }
  }

  @include media-xs {
    width: 100%;
    overflow: hidden;
    margin: 0;

    .select-locale {
      position: absolute;
      bottom: 0;
      left: 25%;
      transform: translateX(-50%);
      height: 50px;
    }

    &__login-form-wrapper,
    &__content-wrapper {
      display: block;
      padding: 10px;
      width: 50%;
    }

    &__login-form-wrapper {
      display: none;

      .protection__wrapper.animation & {
        display: block;
      }
    }

    &__wrapper {
      position: relative;
      display: flex;
      margin-left: 0;
      transition: margin-left .4s ease;
      width: 200%;

      &.animation {
        margin-left: -100%;
      }
    }
  }

  &__wrapper {
    height: 100%;
  }

  &__content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    @include media-sm {
      flex-direction: column;
    }

    @include media-xs {
      width: 50%;
    }
  }

  &__content {
    position: relative;
    width: 40%;

    @include media-sm {
      height: 50%;
      width: 80%;
      text-align: center;
    }

    @include media-xs {
      width: 100%;
      height: auto;
      margin-bottom: 30px;
    }
  }

  &__logo {
    width: 272px;
    height: 55px;
    margin: 20px 0 20px -2%;

    @include media-xs {
      width: 146px;
      height: auto;
      margin: 0 0 20px 0;
    }
  }

  &__image {
    width: 40%;

    @include media-sm {
      bottom: 2%;
      width: 80%;
      margin: 40px;
      display: flex;
      justify-content: center;
    }

    @include media-xs {
      margin: 0;
    }

    img {
      width: 90%;

      @include media-sm {
        width: 260px;
      }

      @include media-xs {
        width: 223px;
      }
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 72px;
    letter-spacing: 0.05em;
    color: color(primary, light);
    line-height: 1;
    font-weight: 700;

    @include media-xs {
      font-size: 42px;
      font-weight: 300;
      margin-bottom: 15px;
    }
  }

  &__soon {
    margin: 0 auto 30px;
    line-height: 1.2;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0.05em;
    color: var(--file-attachments-border);
    text-transform: initial;

    p {
      margin: 0;
    }

    @include media-xs {
      font-size: 16px;
    }
  }

  &__login-form {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 50%;
    width: 200px;
    margin: 4% 0 0 -120px;
    padding: 20px;
    border-radius: 5px;
    background-color: #fff;
    box-sizing: content-box;
    transition: opacity 1s ease-in-out 2s;
    z-index: 2;
    color: color(label, color);

    &:hover,
    &:focus {
      opacity: 1;
      transition: opacity 0.5s ease-in-out 0s;
    }
  }

  &__login-form_mobile {
    @include media-xs {
      position: initial;
      width: 100%;
      margin: 0;
      padding: 15px;
      opacity: 1;
      box-sizing: inherit;
      background-color: #211f22;
    }
  }

  &__login-form-text {
    margin: 0 0 10px 0;
    font-size: 13px;
    text-align: left;
    line-height: 15.6px;
    letter-spacing: normal;
  }

  &__actions {
    display: flex;
    align-items: center;

    @include media-sm {
      justify-content: center;
    }
  }

  &__refresh-btn {
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.05em;
    color: color(primary, light);
    background: var(--file-attachments-border);
    padding-top: 11px;
    padding-bottom: 10px;
    margin-right: 50px;

    &::before {
      @include svg-icon();
      content: '\0077';
      padding-right: 10px;
    }

    @include media-sm {
      margin-right: 0;
    }

    @include media-xs {
     font-size: 12px;
    }
  }
}
