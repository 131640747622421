.content-withdrawal {
  .notification {
    &-root {
      padding: 19px 29px;
      justify-content: center;
      font-size: 16px;
      color: color(select, background);

      p {
        font-size: 16px;
        color: color(select, background);
      }
    }

    &-message {
      width: 100%;
      text-align: center;
    }
  }
}
.withdrawal-form {
  padding-top: 30px;

  @include media-xs {
    padding-top: 0;
    padding-bottom: 30px;
  }

  &::after {
    @include svg-icon('custom');
    content: '\0051';
    position: absolute;
    font-size: 550px;
    right: -50px;
    bottom: -50px;
    z-index: -1;
    opacity: 0.03;

    @include media-xs {
      content: none;
    }
  }

  &__notifications {
    margin: 0 auto;
    width: 704px;

    .notification-info {
      color: color(select, background);
    }

    @include media-xs {
      padding: 15px;

      &:empty {
        padding: 0;
      }
    }
  }

  .notification-info {
    color: color(select, background);
  }

  label.field-label, label {
    @include media-xs {
      display: block;
      margin-bottom: 5px;
      font-size: 12px;
      padding: 0;
    }
  }

  h3 {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    color: color(primary, main);
    margin-top: 2px;
    margin-bottom: 28px;
    text-transform: none;
    min-width: 580px;
    line-height: 1;
    letter-spacing: 0.5px;
  }

  .button-wrapper {
    text-align: center;
  }

  @include media-xs {
    .select-root {

      .select-select {
        align-items: center;
      }
    }
  }

  &__withdrawal {
    border-radius: 5px;
    border: 1px solid color(settings-menu, border);
    width: 704px;
    margin: 0 auto;

    hr {
      display: block;
      margin: 20px -9px;
      border-top: 1px solid color(notice, hr);
      border-bottom: 1px solid color(notice, background);
      border-left: 0;
      border-right: 0;
      clear: both;
    }

    @include media-xs {
      flex-direction: column;
      width: 100%;
      gap: 12px 0;
      border: none;
      padding: 15px;
    }

    &--row {
      display: flex;

      @include media-xs {
        flex-direction: column;
        gap: 12px 0;
      }
    }

    &--short-header {
      display: flex;
      align-items: center;
      margin: -5px 0 15px 0;

      img {
        width: auto;
        margin-right: 10px;
        opacity: 1;
      }
    }

    &--method, &--amount-info {
      padding: 25px;

      @include media-xs {
        padding: 0;
      }
    }

    &--amount-info {
      width: 100%;

      @include media-xs {
        position: relative;

        .form-control-hint-block_offset {
          right: 10px;
        }
      }

      .amount-control {
        input {
          padding-left: 55px;
        }
        .currency {
          left: 0;
          position: absolute;
          color: color(input, background);
          background: #868686;
          font-size: 12px;
          border-radius: 3px 0 0 3px;
          padding: 12px;

          @include media-xs {
            background: #4a5562;
          }
        }
      }
    }

    &--method {
      width: 70%;

      label {
        cursor: pointer;
      }
    }

    &--info {
      font-size: 12px;
      font-weight: initial;
    }

    &--amount-info {
      .method-description {
        color: color(main-menu, link-hover);
        background-color: #272628;
        padding: 19px 29px;
        margin-bottom: 12px;
      }
      .amount-notification {
        border: 1px solid var(--game-status-loss);
        font-size: 16px;
        text-align: center;
        padding: 10px 15px;
        color: var(--game-status-loss);
        margin-bottom: 20px;
        border-radius: 3px;
      }
    }

    &--amount {
      border: 1px solid color(success);
      border-radius: 5px;
      margin-bottom: 20px;

      .welcome-details {
        background: none;
        border: none;
        box-shadow: none;
        margin: 0;
        font-size: 14px;

        &--total {
          background: color(success);
          color: #fff;
        }

        > .group-root {
          display: flex;
          justify-content: space-between;
          padding: 7px 25px 3px 25px;
        }
      }

      @include media-xs {
        display: flex;
        flex-direction: column-reverse;
        border: none;
        margin-bottom: 0;
        gap: 12px;

        .input-root input {
          background: color(select, background);
          padding-left: 50px;
          color: color(label, color);
        }
      }

      .selector-amount {
        display: flex;

        @include media-xs {
          background-color: color(background, body);
          color: color(primary, main);
        }

        label {
          width: 100%;
          text-align: center;
          cursor: pointer;
          padding: 12px 0;
          margin: 0;

          @include media-xs {
            color: color(primary, main);
          }

          &:hover {
            color: color(primary, main);
          }
        }

        .radio-default {
          display: none;
        }

      }

      .field-root {
        padding: 15px 20px 15px 10px;
        display: flex;
        flex-direction: row;
        align-items: center;

        @include media-xs {
          flex-direction: column;
          background: none;
          padding: 0;
        }

        label {
          padding: 0;
          width: 100%;
        }

        .input-root {
          width: 100%;

          .currency {
            border-radius: 5px;
            background: color(input, background);
            height: 40px;
            position: absolute;
            right: -10px;
            text-transform: uppercase;
            font-size: 10px;
            line-height: 40px;
            color: color(settings-menu, border);
            padding-right: 10px;

            @include media-xs {
              @include select-dropdown-arrow;
              right: inherit;
              left: 0;
              padding: 0;
              text-align: center;
              border-radius: 5px 0 0 5px;
              box-shadow: 1px 0 0 color(select, shadow-left), 2px 0 0 color(select, shadow-right);
              color: color(primary, main);
            }
          }
        }
      }
    }

    &--amount-field {
      position: relative;
    }

    &--method {
      display: flex;
      flex-direction: column;
      overflow: auto;
      height: 500px;
      gap: 22px 0;

      @include media-xs {
        height: auto;
        width: auto;
        overflow: inherit;
        gap: 12px 0;
        position: relative;

        .field-root .field-helper:empty {
          font-size: 0;
          margin-top: 0;
        }
      }

      label {
        display: flex;
        align-items: center;
        height: 34px;

        @include media-xs {
          height: auto;
        }

        .radio-default {
          width: auto;
          height: 34px;
          margin-right: 15px;

          svg {
            color: #fff;
            border-radius: 50%;
            width: 17px;
            height: 17px;
            border: 1px solid color(secondary, dark);
          }
        }

        .radio-checked {

          .custom-checkbox {
            width: 17px;
            height: 17px;
            background: #fff;
            border-radius: 50%;
            position: relative;
            border: 1px solid color(secondary, dark);
          }

          .custom-checkbox::after {
            content: '';
            width: 9px;
            height: 9px;
            background: color(secondary, dark);
            display: block;
            border-radius: 50%;
            position: absolute;
            top: 3px;
            left: 3px;
          }
        }

        .radio-checked + img + span.payment-method-field__info {
          opacity: 1;
        }

        .radio-checked + img {
          filter: brightness(0%) saturate(0%) contrast(70%);
          opacity: 1;
        }
      }
    }

    &--alert {
      height: 35px;
      margin: 0;
      color: color(attention);
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__notices {
    display: flex;
    flex-direction: column;
    gap: 10px 0;
    border-radius: 5px;
    margin: 0 0 20px 0;

    @include media-xs {
      > .field-root {
        background: color(success);
        display: flex;
        flex-direction: row;
        align-items: baseline;
        border-radius: 5px;
        padding: 0 10px;

        label {
          color: #fff;
        }

        input {
          color: #fff;
          background: transparent;
          font-size: 18px;
          opacity: 1!important;
        }
      }
    }

    .field-helper {
      font-size: 0;
    }

    .group-root {
      text-align: center;
    }

    .error {
      color: color(error);
      text-transform: uppercase;
    }

  }
  &__title {
    display: inline-block;
    margin: 25px 0 10px;
    padding-bottom: 2px;
    font-size: 16px;
    font-weight: $font-weight-alt;
    border-bottom: 6px solid color(secondary, main);

    p {
      margin: 0;
    }
    &--method {
      margin-top: 0;
    }
  }
  &__sub-title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0 5px;
    padding-bottom: 5px;
    font-size: 16px;
    border-bottom: 2px solid color(secondary, main);

    strong {
      font-size: 20px;
    }
    .exchange {
      display: block;
      text-align: right;
    }
    p {
      margin: 0;
      font-weight: $font-weight-alt;
    }
  }
  &__fields {

    @include media-xs {
      flex-direction: column;
      gap: 12px 0;
    }

    .gridItem-root[class*='withdrawal-form__field--']:not(:only-child) {
      max-width: 100%;
      flex-basis: 100%;

      @include media-xs {
        max-width: 100%;
        flex-basis: 100%;
        margin: 0;
        padding: 0;
      }
    }
  }
}
#root[data-url="/payment/withdrawal"] .main-decorator {
  .main-decorator__content {
    position: relative;

    .button-root {
      margin-bottom: 0;
    }
  }
}
.withdrawal-summary {
  &__bonuses-title {
    p {
      margin-bottom: 0;
      line-height: 1;
    }
  }
  &__deduct,
  &__receive {
    max-width: 45%;

    @include media-xs {
      max-width: 100%;
    }
    .group-root {
      p {
        margin: 0;
        line-height: 2;
        border-bottom: 1px solid #ccc;
      }
      strong {
        float: right;
      }
    }
    .exchange {
      display: block;
      text-align: right;
    }
  }
  &__fee {
    margin-top: 30px;
    font-size: 13px;
    color: color(text, light);

    @include media-xs {
      margin: 10px 0 20px;
    }
    &::before {
      @include svg-icon;
      content: '\0044';
      padding-right: 5px;
      vertical-align: top;
      color: color(warning);
    }
  }
}
.customize-decorator__wrapper.content-withdrawal {
  .customize-decorator--content {
    min-height: calc(100vh - 72px);
    padding-bottom: 0;
  }
}

/** Bank account */
.withdrawal-form--1001 {
  @media (min-width: 480px) {
    .withdrawal-form__field {
      &--bankAccount,
      &--bankName,
      &--bankAccountOwnerName {
        max-width: 45%;
        flex-basis: 45%;
      }
    }
  }
}
/* **/

/** IBAN */
.withdrawal-form--1011 {
  @media (min-width: 480px) {
    .withdrawal-form__field {
      &--ibanAccountOwnerName,
      &--address,
      &--ibanAccount,
      &--ibanBankName,
      &--bankLocation,
      &--bankCity,
      &--beneficiaryCity,
      &--beneficiaryCountry,
      &--beneficiaryState,
      &--beneficiaryZipCode,
      &--beneficiaryStreet,
      &--beneficiaryHouseNumber,
      &--beneficiaryAddress,
      &--ibanBSB,
      &--ibanBic {
        max-width: 45%;
        flex-basis: 45%;
      }
    }
  }
}
/* **/

.withdrawal-form__frame {
  width: 100%;
  height: 666px;
  border: none;

  &.loading {
    height: 0 !important;
    min-height: 0 !important;
  }
  & + .withdrawal-form__circular-progress {
    display: none;
    margin-left: 50%;
    transform: translate(-50%, 0);
  }
  &.loading + .withdrawal-form__circular-progress {
    display: block;
  }
}
.modal-root {
  .modal-paper {
    .modalContentWrapper {
      .modal-contentRoot {
        .withdrawal-form__back {
          position: absolute;
          top: 40px;
          left: 80px;
          display: flex;
          min-width: 0;
          min-height: 0;
          padding: 0;
          font-size: 14px;
          border: 0;
          background: none;
          overflow: hidden;
          transition: all 0.2s;

          .button-label {
            transform: translateY(-100%);
            transition: transform .2s;
          }
          &::before {
            @include svg-icon;
            content: '\0062';
            margin-right: 5px;
            font-size: 15px;
            transform: rotate(180deg);
          }
          &:hover {
            color: color(secondary, main);

            .button-label {
              transform: translateY(0);
            }
          }
        }
      }
    }
  }
}

.revoke-confirmation-modal {
  .modal-paper {
    width: 550px;
  }
}
