.playerVerificationStep2 {
  .playerVerificationForm2 {
    position: relative;
    text-align: center;

    @include media-md {
      flex-direction: column;
      align-items: center;
    }
    @include media-xs {
      flex-direction: column;
    }

    .container {
      width: 734px;

      @include media-xs {
        padding: 0;
        width: 100%;
      }
    }

    &__withoutLvcCard,
    &__withLvcCard {
      display: flex;
      justify-content: space-between;

      @include media-md {
        width: calc(50% - 15px);
        min-width: 435px;
      }

      @include media-xs {
        border: 1px solid color(promotions-card, block-background);
        width: 100%;
        flex-direction: column;
        justify-content: unset;
        min-height: auto;
        padding: 20px 0;
        position: relative;
        min-width: auto;
      }

      &-image {
        width: 100%;
      }

      img {
        width: 304px;
        height: auto;
        align-self: center;

        @include media-xs {
          width: 146px;
        }
      }

      &-text {
        text-align: left;
      }

      &-image, &-text {
        padding: 25px 20px;

        @include media-xs {
          padding: 0;
        }
      }

      .lvcCardNumber {
        margin-top: 20px;

        @include media-xs {
          margin-top: 10px;
        }

        .field-root {
          @include media-xs {
            flex-direction: column;
            display: flex;
          }

          .field-label {
            width: initial;
            padding: 0;
            overflow: unset;
            display: inline-flex;

            @include media-xs {
              width: 100%;
              margin-bottom: 10px;
              font-size: inherit;
              color: color(secondary, dark);
            }
          }

          .input-root {
            max-width: 240px;

            @include media-xs {
              width: 100%;
              max-width: initial;
              padding: 0;
            }
          }
        }
      }

      h3 {
        color: color(primary, main);
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.5px;
        text-transform: uppercase;

        @include media-xs {
          color: color(primary, main);
          font-weight: normal;
          margin-bottom: 0;
        }

        strong {
          color: color(primary, main);
          font-weight: inherit;
        }
      }

      p.sideParagraph {
        margin-bottom: 60px;
      }

      .field-helperError {
        color: color(error);
        margin: 10px 0 0 0;
        font-size: inherit;

        @include media-xs {
          margin-bottom: 10px;
        }
      }

      p {
        font-size: 12px;
        color: var(--home-menu-item-color);
        margin: 2px;

        @include media-xs {
          font-size: 24px;
          text-align: center;
          color: color(primary, main);
          margin: 0 0 10px 0;
          text-transform: uppercase;
        }
      }
      button {
        padding: 0 40px;

        @include media-xs {
          padding: 10px;
          min-height: 35px;
          font-size: 10px;
        }
      }
    }

    &__withLvcCard {
      @include media-md {
        border-radius:  0 0 5px 5px;
        padding-top: 45px;
      }
      @include media-xs {
        padding: 20px;
      }
    }

    &__withoutLvcCard {
      @include media-md {
        border-radius: 5px 5px 0 0;
        padding-bottom: 45px;
      }
      @include media-xs {
        padding: 20px;
      }
    }

    &__or {
      @include media-xs {
        position: relative;
        text-transform: uppercase;
        margin: 10px 0;

        &::before,
        &::after {
          content: '-';
          display: block;
          width: 10px;
          height: 10px;
          position: absolute;
          top: 0;
        }
        &::before {
          left: -15px;
        }
        &::after {
          right: -15px;
        }
      }
    }
  }
}
