* {
  line-height: initial;
}
.content-terms-of-service .mobile-page-theme .container {
  background: unset;
  padding: 0;
  .title {
    display: none;
  }
  #static-page__anchor {
    padding: 0;
  }
}
.terms-of-service {
  color: color(secondary, dark);
  max-width: 1280px;

  @include media-xs {
    color: var(--file-attachments-border);

    .terms {
      margin: 0 -15px;
      padding: 0 15px;
    }
  }

  .regular-title {
    width: 100vw;
    height: 56px;
    font-size: 14px;
    letter-spacing: 1.2px;
    line-height: 18px;
    font-weight: 400;
    margin-top: 0;
    justify-content: center;
    align-items: center;
    margin-left: -15px;
    text-align: center;
    color: color(primary, main);
    background: color(secondary, dark);
    text-transform: uppercase;
    margin-bottom: 20px;
    display: none;

    @include media-xs {
      display: flex;
    }
  }

  .only-mobile {
    display: none;

    @include media-xs {
      display: block;
    }
  }

  h1, h2 {
    text-transform: uppercase;
    font-size: 26px;
    line-height: 1;
    margin-bottom: 20px;
    font-weight: 300;
    letter-spacing: 0.5px;
    margin-top: 0;

    @include media-xs {
      font-weight: initial;
      letter-spacing: normal;
    }
  }

  @include media-xs {
    h2 {
      width: 100vw;
      height: 56px;
      font-size: 14px;
      letter-spacing: 1.2px;
      line-height: 18px;
      font-weight: 400;
      margin-top: 0;
      justify-content: center;
      align-items: center;
      margin-left: -15px;
      text-align: center;
      color: #b5a147;
      background: #1e1e1e;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
  }

  p {
    margin-top: 0;
  }

  a {
    text-decoration: underline;
  }

  b, strong {
    color: color(secondary, dark);
  }

  br {
    display: none;
  }
}
