.playerVerificationStep4 {
  .container {
    width: max-content;
    max-width: 1060px!important;
    min-width: 530px;

    @include media(null, $size-xs) {
      padding: 0;
    }

    @include media-md {
      width: 530px;
    }

    @include media-xs {
      min-width: auto;
      width: auto;
    }
  }

  .playerVerificationForm4 {
    .container {
      @include media-xs {
        padding: 0;
      }
    }
    &__header {
      line-height: 1.3;
      padding-bottom: 10px;

      @include media-xs {
        text-align: left;
        padding-bottom: 0;
      }
    }

    &__snapshots {
      border-radius: 5px;
      border: 1px solid var(--file-attachments-border);

      @include media-md {
        flex-direction: column;
      }
      @include media-xs {
        border-radius: initial;
        background: none;
        border: none;
      }
    }
    &__left,
    &__right,
    &__wide {
      display: flex;
      padding: 20px 10px 7px;

      @include media-xs {
        padding: 0;
      }
    }
    &__left {
      border-right: 1px solid color(settings-menu, border);

      @include media-md {
        border-right: none;
      }
    }
    &__wide {
      .snapshot__headers {
        min-height: 45px;

        @include media-xs {
          min-height: auto;
        }
      }
    }
    &__left,
    &__right {
      width: 100%;
      max-width: 100%;
    }
    &__blocks {
      display: flex;
      justify-content: center;
      align-items: end;

      @include media-xs {
        display: block;
      }

      .sub-block {
        display: flex;
        align-items: end;
        height: 100%;
        padding: 0 10px 10px;

        @include media-xs {
          height: initial;
          padding: 0 0 10px;
        }
      }
    }
    &__image {
      &.sub-block {
        display: block;

        @include media-xs {
          display: none;
        }
      }
    }
  }
}
