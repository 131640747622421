.activity-form {
  &__cell-title {
    display: none;
    margin-bottom: 5px;
    text-transform: uppercase;
    color: var(--activity-form-cell-color);
  }
  .custom-table-cell--status--login {
    color: color(success)
  }
  .custom-table-cell--status--logout {
    color: color(error)
  }
  @media (max-width: 668px) {
    padding: 0;

    .custom-table {
      display: block;
    }
    .custom-table-cell {
      border: none;
    }

    &__item {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .custom-table-cell {
        flex: 1;
        white-space: nowrap;
        padding: 15px;

        &:first-child {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
    &__cell-title {
      display: block;
    }
    &__item {
      margin: 10px 0;
    }
  }
}
.post-login-content-wrap--activity {
  @media (max-width: 668px) {
    background: none;

    .post-login__title {
      margin: 0;
      padding: 20px 0;
    }
  }
}
