.responsible-gambling-questionnaire {
  flex: 1;
  padding: 10px 30px;
  background-color: var(--post-login-content-background);

  &__title {
    margin: 0;

    p {
      position: relative;
      display: inline-block;
      margin: 20px 0 44px;
      letter-spacing: .05em;

      &::before {
        content: '';
        position: absolute;
        bottom: -16px;
        left: 0;
        width: 100%;
        height: 6px;
        background-color: color(primary, main);
      }
    }
  }
  .questionnaire {
    &-content {
      &--full {
        &__answer {
          min-width: initial !important;
        }
        &__question {
          &--error {
            .formControlLabel-label {
              border: 1px solid color(error);
            }
          }
          legend {
            margin-bottom: 10px;
            margin-top: 10px;
          }
          @include media-xs {
            .radioGroup-root {
              flex-direction: column;

              label + label {
                margin-left: 0;
                margin-top: 8px;
              }
            }
          }
        }
        &__finish-button {
          margin-top: 10px;

          @include media-xs {
            width: 100%;
          }
        }
      }
      &--compact {
        &__controls {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          margin-top: 15px;

          @include media-xs {
            flex-direction: column;
          }
        }
        &__prev-button {
          margin: 15px 0 0 -3px;

          &::before {
            @include svg-icon;
            margin-right: 5px;
            content:'\0062';

            transform: rotate(180deg) translateY(-2px);
          }
        }
        &__question {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 15px 0 10px -3px;
        }
        &__counter {
          flex-basis: 42px;
          min-width: 42px;
          height: 42px;
          margin-right: 8px;
          border-radius: 21px;
          font-weight: $font-weight-alt;
          line-height: 42px;
          text-align: center;
          background-color: color(primary, main);
        }
        .field-helperEmpty,
        .field-helperSuccess {
          min-height: 0;
        }
      }
      &--full,
      &--compact {
        &__next-button {
          @include button-secondary;
        }
        &__prev-button {
          cursor: pointer;
          clear: left;

          &:hover {
            text-decoration: underline;
          }
        }
        &__finish-button {
          @include button-primary;
        }
      }
    }
    &-result {
      &__restart-button {
        @include button-secondary;

        @include media-xs {
          width: 100%;
        }
      }
    }
  }
}
