.resume-modal--frame {
  .resume-form,
  .login-modal__form-wrapper {
    height: 100%;
  }
  .modal-contentRoot {
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.login-modal {
  &--regular {

    > div:not(.modal-paper) {
      background: url('cms/img/login-bg.jpg') top center no-repeat;
      background-size: cover;
    }

    @include media-sm {
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
    }

    .modal-paper {
      width: 280px;

      @include media-sm {
        height: 100%;
        border-radius: 0;
      }

      .modalContentWrapper {
        padding: 0;
        border-radius: 3px;
        border: 1px solid #b5a147;

        @include media-xs {
          border: none;
          padding: 45px 0 0 0;
        }

        .modal-titleRoot {
          padding: 15px;
        }

        .modal-contentRoot {
          > div:last-child {
            margin-bottom: 0;
          }

          .authentication__error {
            padding: 0 15px 15px 15px;
          }
        }

        .notification-root {
          background: none;

          .notification-action {
            display: none;
          }

          p {
            font-size: 16px;
            text-align: center;
          }
        }

        .notification-error {
          color: color(error);
        }

        .notification-success {
          color: color(success);
        }

        .notification-warning {
          color: color(warning);
        }
      }
    }
    .modal-rootButtons {
      top: 7px;
      right: 7px;

      .modal-buttonRoot {
        width: 19px;
        height: 19px;
        min-width: 19px;
        min-height: 19px;

        &::before {
          font-size: 10px;
          line-height: normal;
        }
      }
    }

  }
  &--loginToPlay {
    .modal-paper {
      @include modal-fullscreen-mode;
      justify-content: center;
      align-items: center;

      .modalContentWrapper {
        width: 280px;
      }
    }
    .modal-titleRoot {
      text-align: center;
    }
  }
  &__sign-up {
    display: none;
  }
}
