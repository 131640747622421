.customize-decorator {

  &__wrapper {
    height: 100%;
  }

    &--header {
      text-align: center;
      font-size: 18px;
      color: #fff;
      margin: 0;
      padding: 27px 0;
      font-weight: 400;
      background: color(modal, header);
      min-width: 580px;
      text-transform: uppercase;
      line-height: 1;
      position: relative;
      letter-spacing: 0.5px;
    }

    &--content {
      padding: 30px 28px 26px;
      overflow: hidden;
      position: relative;
    }

    &--close {
        @include close-button;
        border-radius: 50%;
        position: absolute;
        top: calc(50% - 15px);;
        right: 16px;
    }
}
