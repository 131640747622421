.contact-us {
  &-wrapper {
    max-width: 600px;
    margin: 40px auto 0;

    @include media-xs {
      padding: 15px;
      margin-top: 0;
    }

    .help-section {
      font-size: 14px;
      margin-bottom: 35px;

      @include media-xs {
        font-size: 12px;
        margin-bottom: 15px;
      }

      a {
        text-decoration: underline;

        &:hover {
          color: color(secondary, main);
        }
      }

      ul {
        padding-left: 18px;

        p {
          margin-top: 0;
        }

        .link-trigger {
          > div, > span {
            display: inline-block;

            p {
              margin: 0;
            }
          }

          > span {
            color: color(primary, main);
            text-decoration: underline;
            cursor: pointer;
            margin-left: 5px;
            margin-bottom: 10px;

            &:hover {
              color: color(secondary, main);
            }
          }
        }
      }
    }

    .contact-us-wrapper-grid,
    .contact-us-wrapper-message,
    .group-root--buttons {
      display: none;
    }

    &-grid {
      display: flex;
      flex-wrap: wrap;
      margin-left: -5px;

      @include media-xs {
        margin: 0;
      }

      .field-input, .select-container {
        width: 50%;
        flex-basis: 50%;
        padding: 0 5px;

        @include media-xs {
          width: 100%;
          flex-basis: 100%;
          padding: 0;
        }
      }
    }

    &-message {
      width: calc(100% - 5px);
      margin-bottom: 20px;

      @include media-xs {
        width: 100%;
        margin-bottom: 0;
      }
    }

    .files-container {

      &--wrapper {
        background: color(input, background);
        border-radius: 0 3px 3px;
        border: 1px solid var(--file-attachments-border);
        color: var(--file-attachments-color);
        width: 100%;
      }

      &--content {
        padding: 15px;
        margin: 0 auto;
        flex-direction: row;
        display: flex;
        background: #fcfcfc;

        @include media-xs {
          flex-direction: column;
          align-items: center;
        }

        .imageField {
          &-root {
            margin-bottom: 5px;
            height: 136px;

            @include media-xs {
              height: auto;
            }
          }

          &-selected {
            .imageField-buttons {
              display: flex;
              position: absolute;
              width: 100%;
              height: 100%;
              background: none;
            }

            img {
              filter: brightness(30%)
            }
          }

          &-content {
            position: absolute;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            text-transform: uppercase;
            z-index: 1;

            &::before {
              font-family: 'Glyphter-custom';
              content: '\0047';
              position: absolute;
              width: 33px;
              height: 33px;
              top: 20px;
              font-size: 33px;
            }
          }

          &-clear {
            color: color(primary, main);
            border: 1px solid color(primary, main);
            border-radius: 10px 3px;
            padding: 3px 5px;
            min-width: auto;
            min-height: auto;
            font-size: inherit;
            top: 30px;
            z-index: 2;
          }

          &-buttons {
            border-radius: 4px;
            height: 100%;
            background: color(input, background);
            justify-content: center;
            align-items: center;
            display: flex;

            @include media-xs {
              border-radius: 10px 3px;
            }

            .fileField-root {
              display: flex;

              @include media-xs {
                top: auto;
                width: 100%;
              }
            }

          .fileField-button {
            color: color(primary, main);
            height: 70px;
            display: flex;
            align-items: end;

            p {
              margin: 0;
            }

              @include media-xs {
                color: inherit;
                height: auto;
              }

              &:hover {
                background: none;
                color: color(button-accent, hover);
              }

              &::before {
                font-family: 'Glyphter-custom';
                content: '\0047';
                position: absolute;
                width: 33px;
                height: 33px;
                top: 15px;
                font-size: 33px;
                color: color(primary, main);

                @include media-xs {
                  width: 20px;
                  height: 20px;
                  top: calc(50% - 10px);
                  left: 15px;
                  font-size: 20px;
                  color: inherit;
                }
              }
            }
          }
        }
      }

      &--row {
        display: flex;
        flex-direction: column;
        margin-bottom: 12px;
        width: 33%;
        padding: 5px;

        @include media-xs {
          width: 80%;
        }

        .imageField-imageWrapper {
          height: 136px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 5px;

            @include media-xs {
              border-radius: 10px 3px;
            }
          }
        }

        label {
          text-transform: uppercase;
          margin-bottom: 10px;
        }
      }

      &--info {
        text-align: center;
        padding: 5px;
        background: #ededed;
      }

    }

    .group-root--buttons {
      margin: 0;

      @include media-xs {
        display: flex;
        flex-direction: column;
        padding: 0 10px;
        align-items: center;
      }

      .clear {
        clear: both;
      }

      > .group-root {
        margin: 0 0 15px 0;
        float: left;

        @include media-xs {
          margin: 0 0 10px 0;
        }
      }

      .is-opened {
        width: 100%;
        float: none;
      }

      .field-checkbox {
        float: right;

        //.checkbox-default {
        //  background: color(primary, dark);
        //  width: 17px;
        //  height: 17px;
        //}
        //.checkbox-checked::before {
        //  font-size: 10px;
        //}
      }

      .button-accent, .button-default {
        width: fit-content;
      }

      .file-attach-button {
        padding-right: 22px;
        padding-left: 60px;
        font-size: 12px;
        overflow: hidden;

        &::before {
          font-family: 'Glyphter-custom';
          content: '\0048';
          position: absolute;
          top: 50%;
          left: 0;
          width: 45px;
          padding: 3px;
          transform: translateY(-50%);
          transition: background .3s;
          font-size: 16px;
          color: #1d1808;
          background: color(primary, main);
        }

        &::after {
          font-family: 'Glyphter-custom';
          content: '\0042';
          position: absolute;
          top: 50%;
          right: 2px;
          width: 10px;
          height: 6px;
          display: block;
          margin-top: -3px;
          transition: all .3s;
          transform: rotate(90deg);
          font-size: 10px;
        }
      }

      .opened {
        background: #ededed;
        border: 1px solid var(--file-attachments-border);
        border-bottom: 1px solid transparent;
        color: var(--file-attachments-border);
        top: 1px;
        border-radius: 5px 5px 0 0;

        @include media-xs {
          display: block;
          margin: 0 auto;
        }

        &::before {
          color: #fff;
          background: var(--file-attachments-border);
        }

        &::after {
          transform: rotate(0);
          top: calc(50% - 5px);
          right: 5px;
        }
      }

      .button-accent + .group-root {
        margin-bottom: 15px;
      }
    }
  }

  &-success {
    margin: 0 auto;
    text-align: center;
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include media-xs {
      height: auto;
      font-size: 14px;
      text-transform: uppercase;
    }

    strong {
      margin-bottom: 20px;
      font-size: 24px;
      font-weight: 300;
      letter-spacing: 0.5px;
      text-transform: uppercase;

      @include media-xs {
        font-weight: 700;
        color: color(success);
        font-size: 18px;
      }
    }

    button {
      padding: 13px 15px 11px;
      font-size: 16px;
      line-height: 1.5;
      margin: 15px 0;
    }
  }
}
