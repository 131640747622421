.pep-page-content {
  max-width: 600px;
  margin: 25px auto 0;

  @include media-xs {
    margin: 25px 10px;
    color: var(--file-attachments-border);

    strong {
      color: #000;
    }
  }

  h2 {
    font-size: 18px;
    color: color(primary, main);
    text-transform: capitalize;
    text-align: center;
    margin-bottom: 28px;

    @include media-xs {
      text-transform: uppercase;
      color: inherit;
      margin-bottom: 20px;
      font-size: 26px;
    }
  }

  ul {
    padding-left: 20px;
    margin: 0 0 20px 0;

    @include media-xs {
      margin: 0 0 10px 0;
    }

    li {
      margin: 10px 0;
    }
  }
}
