.content-faq {
  @include media-xs {

    .mobile-page-theme {
      .title {
        display: none;
      }
      .container {
        background: unset;
      }
    }
  }
}

.staticPageWrapper {
  &--faq {
    margin-top: 15px;

    @include media-xs {
      margin-top: 0;
      padding-bottom: 20px;
    }

    h2 {
      background: color(secondary, dark);
      height: 100px;
      width: 100vw;
      left: -18px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      color: color(primary, main);
      text-transform: uppercase;
      margin-top: -30px;
      margin-bottom: 20px;
      min-width: 580px;
      font-weight: 300;

      @include media-xs {
        width: 100vw;
        height: 56px;
        font-size: 14px;
        letter-spacing: 1.2px;
        line-height: 18px;
        font-weight: 400;
        margin-top: 0;
        margin-left: -10px;
        min-width: auto;
        left: -5px;
      }
    }
  }
}

.staticPageWrapperFaq {
    margin: 0 auto;
    max-width: 1280px;

    .staticPageWrapper__fakeCloseButton {
        position: fixed;
        top: 20px;
        right: 30px;
        width: 32px;
        height: 32px;
        line-height: 32px;
        color: color(link, default);
        background-color: #464546;
        text-align: center;
        text-decoration: none;
        border-radius: 50%;

        @include media-xs {
            position: static;
        }
    }

    h2 {
        margin-top: 5px;
        margin-bottom: 20px;
        font-size: 24px;
        font-weight: 300;
        letter-spacing: 0.5px;
        text-transform: uppercase;

        @include media-xs {
            margin-top: 20px;
            margin-bottom: 20px;
            font-size: 16px;
            text-transform: uppercase;
        }
    }

    h3 {
      font-size: 14px;
      text-transform: uppercase;
      color: color(secondary, dark);
      border-bottom: 1px solid #dadada;
      padding-bottom: 10px;
    }

    a {
      text-decoration: underline;
    }

    h4 {
      cursor: pointer;
      margin: 0 0 10px 0;
      font-size: 14px;
      color: #404040;

      &:hover {
        color: color(primary, main);
      }

      @include media-xs {
        font-size: 12px;
      }
    }

    p {
      max-height:0;
      margin: 0 0 0 16px;
      overflow:hidden;
      height:auto;
      transition: max-height 0.5s, margin-bottom 0.5s;
      font-size: 14px;
      color: color(secondary, dark);

      @include media-xs {
        margin-left: 0;
        font-size: 12px;
      }
    }

    .show + p {
      max-height: 500px;
      margin-left: 16px;
      margin-bottom: 14px;
      transition: max-height 3s, margin-bottom 0.5s;
    }
}

@include media-xs {
  .content-promotions {
    .mobile-page-theme {

      .title {
        display: none;
      }

      .container {
        background: none;

        .static-page__container {
          padding: 0;
        }
      }
    }
  }
}
