.snapshotFileField {
  &__fileLabel {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
  }
  &__fileButton {
    &[class*='disabled'] {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
}
