.press-page-content {
  h2 {
    margin:0 0 20px 0;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: color(notice, color);
  }

  h4 {
    margin: 0 0 10px 15px;
    font-size: 14px;
    text-indent: -16px;
    line-height: 1.5;
  }

  a {
    text-decoration: underline;
  }
}