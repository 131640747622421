.notification-root {
  flex-wrap: nowrap;
  flex: 0 0 auto;
  min-width: 50%;
  width: 100%;
  max-width: 100%;
  align-items: center;
  box-shadow: none;
  text-align: left;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;

  @include media-xs {
    border-radius: 5px;
  }

  p {
    margin: 0;
    font-size: 12px;
  }

  ol {
    padding-left: 13px;
  }

  .notification-message {
    padding: 0;
  }

  .notification-action {
    margin-right: 0;
    padding-left: 0;
    color: color(text, main);
    align-self: baseline;

    button {
      @include close-button;

      &::before {
        color: color(notice, color);
      }
    }
  }

  .notifications .notification & {
    background-color: var(--body-background);
  }
}
.notification-error {
  border: 1px solid color(error);
  color: color(error);
  background: none;

  .notification-action {
    button {
      &::before {
        color: color(error);
      }
    }
  }

  @include media-xs {
    background: color(error);
    color: color(notice, color);
    justify-content: center;
  }
}
.notification-success {
  border: 1px solid color(success);
  color: color(success);
  background: none;

  #root[data-url="/"] ~ .notifications & {
    background:  var(--body-background);
  }

  .notification-action {
    button {
      &::before {
        color: color(success);
      }
    }
  }
}
#root[data-url="/payment/deposit"] ~ .notifications {
  right: 60px;
}
.notification-warning {
  border: 1px solid color(warning);
  color: color(warning);
  background: none;

  .notification-action {
    button {
      &::before {
        color: color(warning);
      }
    }
  }
}
.notification-info {
  border: 1px solid color(info);
  color: color(info);
  background: none;

  .notification-action {
    button {
      &::before {
        color: color(info);
      }
    }
  }
}
.notifications {
  position: fixed;
  top: 5px;
  right: 10px;
  z-index: 99998;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 40%;
  pointer-events: none;

  @include media-md {
    width: 60%;
  }
  @include media-sm {
    right: 10px;
    left: 10px;
    width: auto;
  }
  .notification {
    display: flex;
    align-items: flex-end;
    min-width: 100%;
    margin-top: 5px;
    padding-bottom: 10px;
    overflow: hidden;
    pointer-events: all;

    &:last-child {
      padding-bottom: 0;
    }
    &-root {
      margin-bottom: 0;
    }
    ul {
      padding-left: 10px;
    }
    .notification-action {
      .material-icons {
        font-size: inherit;
      }
    }
  }
}
