.main-decorator__wrapper .account__title {
  background: none;
  margin-bottom: 15px;
  padding: 0;
  justify-content: start;
  height: auto;
  text-transform: inherit;

  @include media-xs {
    background: color(secondary, dark);
    height: 56px;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    margin: 0 0 20px 0;
  }
}
