.stepper {
  &-root {
    justify-content: center;
    background: var(--modal-title-background);
    padding: 0 0 15px;
  }

  &-step {
    padding: 0;
    max-width: 180px;

    & > div:nth-child(2) {
      top: 27px;
      left: calc(50% + 22px);
      right: calc(-50% + 17px);

      span {
        border-top-width: 1px;
        border-color: color(primary, dark);
      }
    }
    p {
      margin-top: 5px;
      font-weight: $font-weight-base;
      color: #737373;
    }
    text {
      font-size: 9px;
      line-height: 54px;
      fill: color(input, text);
    }
    svg {
      width: 54px;
      height: 54px;
      fill: color(primary, dark);
    }

    &.active {
      margin-left: 0;

      text {
        fill: color(select, background);
      }
      svg {
        fill: color(primary, main);
      }
    }
  }

  &-item--completed {
    svg {
      padding: 0;
      border-width: 5px;
    }
  }
}

.custom-stepper {
  &-root {
    background: transparent;

    @include media-xs {
      max-width: 250px;
      margin: 0 auto;
    }

    .stepper-step {
      &.active,
      &.completed {
        margin-left: 0;

        .custom-stepper-icon::after {
          color: color(primary, main);
        }
      }
    }
  }

  &-icon {
    position: relative;

    &::after {
      @include svg-icon('custom');
      content: '\0051';
      position: relative;
      font-size: 60px;
      color: color(input, background);

      @include media-xs {
        font-size: 32px;
      }
    }
  }
}
