.main-decorator__wrapper  {
  .not-found-page {
    display: flex;
    flex-grow: 1;

    &__content {
      padding: 15px;
      width: 100%;

      @include media-xs {
        padding: 0;
      }
    }
    &__title {
      background: none;
      width: auto;
      height: auto;
      font-size: 24px;
      color: inherit;
      font-weight: 300;
      justify-content: normal;

      @include media-xs {
        background: color(secondary, dark);
        color: color(primary, main);
        justify-content: center;
        font-size: 14px;
        height: 56px;
        margin: 0;
      }
    }
    &__text {
      margin-bottom: 14px;
    }
    &__link {
      display: inline-block;
    }

    &__text, &__link {
      @include media-xs {
        margin: 0 10px 14px 10px;
      }
    }
  }
}

