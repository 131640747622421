.attention {
  &__wrapper {
    background-color: color(alarm, background);
  }
  &__message {
    color: color(alarm, color);
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
  &__content {
    max-width: 1280px;
    margin: 0 auto;
    position: relative;
    padding: 22px 65px 22px 80px;

    &:before {
      @include svg-icon('custom');
      content: '\0055';
      position: absolute;
      top: 13px;
      left: 31px;
      color: color(alarm, color);
      font-size: 35px;
    }
  }
  &__wrapper &__close {
    &-button {
      @include size(25px);
      position: absolute;
      min-height: 25px;
      min-width: 25px;
      top: 50%;
      right: 30px;
      margin-top: -13px;
      background: none;

      &::before {
        font-family: 'Glyphter-custom';
        color: color(alarm, color);
        content: '\0054';
        font-size: 10px;
      }
    }
  }
}
