.home-slider {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;

  &__slide {
    position: relative;
    display: inline-block;
    height: 600px;

    @include media-md {
      height: 400px;
    }
    @include media-xs {
      height: 200px;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
