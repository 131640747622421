.main-decorator__footer {
  position: relative;
}
.live-chat-button {
  @include live-chat-button;
}
.footer {
  position: relative;
  margin: auto;
  background: var(--footer-background);
  color: color(footer, text);
  z-index: 7;

  &::before {
    content: '';
    position: absolute;
    height: 7px;
    width: 75%;
    top: -7px;
    left: 0;
    background-color: #b5a147;
    border-radius: 0 5px 0 0;
  }

  &__container {
   .payment-provider-icons, .footer__license {
     @include media(null, $size-md) {
       max-width: 1280px;
       margin: 0 auto;
     }
   }

   .payment-provider-icons {
     margin-bottom: 30px;

     @include media-xs {
       margin: 10px 0 15px;
     }
   }
  }

  &__top-section {
    padding: 30px 0;
    background-color: var(--footer-top-section-background);

    @include media-xs {
      padding: 10px 0;
    }
  }
  &__bottom-section {
    padding-top: 15px;
    background: color(footer, bottom-section);

    @include media-sm {
      padding-top: 15px;
    }

    #root:not([data-url='/']):not([data-url^='/group/']):not([data-url$='-games']) & {
      padding-bottom: 0;
    }
  }
  &__license {
    display: flex;
    flex-direction: row;
    gap: 0 20px;
    margin-bottom: 15px;
    font-size: 12px;

    &-links {
      display: flex;
      flex-direction: row;

      .live-chat-button {
        white-space: nowrap;
        line-height: 22px;
        color: color(primary, main);
        margin-right: 3px;
        cursor: pointer;
      }
    }

    @include media-sm {
      flex-direction: column-reverse;
      padding: 0 15px;
    }

    @include media-xs {
      margin: 0;
    }

  }

  &__license-logos {
    margin: 5px 0 20px 20px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;

    > div {
      display: flex;
      flex-direction: row;

      @include media-xs {
        flex-direction: row-reverse;
      }
    }

    @include media-xs {
      flex-direction: column;
      margin: 0;
    }

    &--copy {
      flex-grow: 1;
      text-align: left;
      font-size: 10px;
    }

    .footer-content__icons {
      width: 82px;
      margin-left: 18px;
      background: url('/cms/img/icons/nqa.png') no-repeat transparent;

      @include media-sm {
        background-size: 32px!important;
      }
    }

    .footer-content__icons_auditors {
      width: 112px;
      background: url('/cms/img/icons/auditors_icon.png') no-repeat transparent;
      background-size: 96px 96px;
      background-position: center;
    }

    .footer-content__icons, .footer-content__icons_auditors {
      height: 109px;
      display: block;
      float: left;
      position: relative;
      overflow: hidden;

      @include media-sm {
        width: 47px;
        height: 47px;
        background-size: 47px;
      }
    }

    a {
      display: inline-block;
      align-self: center;
      vertical-align: middle;
    }
    .curacao-license {
      display: inline-block;
      max-width: 90px;
      vertical-align: middle;
    }
    .mga-license {
      img {
        max-width: 100px;
      }
    }
    .uk-license {
      img {
        max-height: 50px;
      }
    }
    @include media-md {
      .curacao-license {
        max-width: 80px;
      }
      .mga-license {
        img {
          max-width: 80px;
        }
      }
      .uk-license {
        img {
          max-height: 40px;
        }
      }
    }
  }
  .third-party-logos {
    &__gambleaware {
      img {
        max-width: 150px;
      }
    }
    &__ibas {
      img {
        max-width: 100px;
      }
    }
    &__gamstop {
      img {
        width: 130px;
      }
    }
    @include media-md {
      &__gambleaware {
        img {
          max-width: 120px;
        }
      }
      &__ibas {
        img {
          max-width: 80px;
        }
      }
      &__gamstop {
        img {
          width: 100px;
        }
      }
    }
  }
  &__license-text {
    font-size: 12px;

    @include media-sm {
      margin-bottom: 20px;
    }
    @include media-xs {
      margin-bottom: 0;
    }
    a {
      color: color(footer, link);

      &:hover {
        color: color(footer, link);
      }
    }
    p {
      margin: 0;
      line-height: 22px;

      @include media-xs {
        font-size: 10px;
        text-align: justify;
        line-height: 1.5;
      }
    }
  }
  &__license-help {
    font-size: 12px;
    padding: 10px;
    margin-right: 30px;

    .links {
      margin: 5px 0;
    }

    @include media-xs {
      margin: 0;
      padding: 0;
    }

    &--title {
      font-size: 20px;
      margin-bottom: 7px;
    }

    &--contacts p {
      display: flex;
      flex-direction: column;
    }

    p {
      height: 100%;
      margin: 0;
      line-height: 1.5;
      width: 251px;

      a {
        padding: 0;
        color: color(primary, main);
        text-decoration: none;

        &:hover {
          text-decoration: none;
        }
      }

      img {
        width: 35px;
        max-height: 22px;
        margin-right: 6px;
      }
    }
  }
  .container {
    max-width: 1020px;
  }
  &__version {
    padding: 10px 0;
    font-size: 11px;
    text-align: center;
  }

}
