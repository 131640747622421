.setup-cookie-modal {
  .modal-paper {
    width: auto;
  }

  .modal-contentRoot {
    padding: 0;
  }

  &__buttons {
    @include media-xs {
      padding: 15px 0 0 0;
    }

    button + button {
      margin-left: 10px;
    }

    .button-default {
      background: color(popup, summary-background);
    }
  }
}

@include media-xs {
  body .setup-cookie-modal {
    top: 0;

    .modal-paper {
      background: transparent;

      .modalContentWrapper {
        background: #fff;
        border: 1px solid color(footer, background);
        margin: 10px;
        border-radius: 5px;

        .modal-titleRoot {
          background: none;
          border-bottom: 0;
          margin-bottom: 2px;

          h2 {
            margin-top: 0;
            font-size: 24px;
          }
        }

        .modal-contentRoot {
          color: var(--file-attachments-border);
        }
      }
    }
    &__buttons {
      border-top: 0;
      margin-top: 2px;
      text-align: right;
    }

    .step2 {
      display: flex;
      justify-content: right;
      flex-direction: row-reverse;
    }
  }
}
