.cookiePolicy {
  &__modal {

    .modal-rootButtons {
      right: 30px;
    }

    .modal-paper .modalContentWrapper .modal-contentRoot {
      padding: 0;

      h2 {
        margin-top: 14px;
        margin-bottom: 20px;
        font-size: 24px;
        font-weight: 300;
        letter-spacing: 0.5px;
      }

      ul li {
        list-style-type: disc;

        &::before {
          content: none;
        }
      }
    }
  }
}
