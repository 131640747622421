.view-image {
  &__modal {
    .modal-contentRoot {
      padding: 30px 20px;
    }
  }
  &__image {
    width: 100%;
  }
}
