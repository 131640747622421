.registration-modal {
  &__banner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    @include webp-bg-img('/cms/img/registration.webp', '/cms/img/registration.jpg');
    background: {
      position: center top;
      repeat: no-repeat;
      size: cover;
    }

    @include media-md {
      display: none;
    }
  }
  &__banner-promo {
    padding: 10px;
    font-size: 22px;
    font-weight: $font-weight-alt;
    color: #fff;
    text-transform: uppercase;
    text-align: center;

    p {
      line-height: 1;
    }
    strong {
      display: block;
      margin-top: 5px;
      margin-bottom: 20px;
      font-size: 40px;
      color: color(primary, main);
    }
  }
}

.registration-form-modal__login {
  &::before {
    @include svg-icon;
    content: '\006d';
    padding-right: 3px;
    font-size: 18px;
  }
}
