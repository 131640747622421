.info-block {
  &__wrapper {
    position: relative;
    width: 100%;
    border: 1px solid color(input, text);
    border-radius: 12px;
    padding: 25px;
    font-size: 16px;
    color: color(modal, header);
  }

  &__title {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 14px;
    border: 1px solid color(input, text);
    border-radius: 100px;
    width: 100%;
    padding: 10px 15px;
    max-width: max-content;

    &::before {
      @include svg-icon('custom');
      content: '\0066';
      font-size: 14px;
      color: color(primary, main);
      padding-right: 10px;
    }
  }

  &__text {
    margin-top: 15px;
  }
}
