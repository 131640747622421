.payment-provider-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0 15px;

  @include media-sm {
    margin-bottom: 10px;
    justify-content: space-between;
    gap: 10px;
  }
  .payment-provider-icon {
    margin: 5px auto;
    overflow: hidden;

    @include media-xs {
      margin: 0 0 10px;
    }
  }
  .seedPaymentProviderIcon__image {
    max-height: 20px;
    filter: brightness(5) contrast(.5) grayscale(100%);
    height: auto;
    object-fit: contain;
    margin: 5px auto;

    @include media-xs {
      height: 23px;
      margin: 5px auto 5px 0;
    }
  }
  .payment-provider-icon {
    &--210054 .seedPaymentProviderIcon__image,
    &--210055 .seedPaymentProviderIcon__image {
      filter: none;
    }
  }
}
