.game-info-modal {

  .modal-paper {
    background-color: rgba(33,31,34,.9);
    justify-content: center;

    .modalContentWrapper {
      color: #fff;

      table {
        width: 70%;
        margin: 0 auto;

        .firstRow {
          color: color(input, background);
          font-size: 18px;
          text-align: center;
        }

        .secondRow {
          color: var(--file-attachments-border);
          font-size: 14px;
          text-align: center;
          height: 50px;
          vertical-align: top;
        }

        .lines, .reels, .minBet, .maxBet, .loyality {
          color: var(--file-attachments-border);
          font-size: 14px;
          height: 40px;
          width: 50%;
        }

        .linesAmount, .reelsAmount, .minBetAmount, .maxBetAmount, .loyalityAmount {
          color: color(input, background);
          font-size: 18px;
        }
      }

      .button-accent,
      .button-default {
        font-size: 18px;
        margin: 10px auto 20px;
        font-weight: 400;
        padding: 15px 32px;
        width: fit-content;
      }

      a {
        font-size: 14px;
        margin: 0 auto;
      }

      .game-thumbnail__button--favourite {
        top: 15px;
        left: 15px;
        bottom: initial;
        right: initial;

        &::before {
          font-size: 30px;
        }
      }
    }
  }
}
