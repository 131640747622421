.playerVerificationStep3 {
  .lvcVerification {
    &__header {
      @include media(null, $size-xs) {
        margin-bottom: 20px;
      }
    }
  }

  .playerVerificationForm3 {
    max-width: 310px;
    margin: 0 auto;
    border: 1px solid var(--file-attachments-border);
    border-radius: 3px;
    padding: 15px 14px 5px;

    .container {
      padding: 0;
    }

    @include media-xs {
      border: none;
      max-width: 100%;
      padding: 0;
    }
  }
}

