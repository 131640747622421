.content-transactions {

  @include media-xs {
    .transactions {
      &__wrapper {
        background: color(footer, background);

        label {
          color: color(label, color);
        }

        input, .select-select {
          background: color(select, background);
          color: color(label, color);
          line-height: 2;
        }

        .select-root {
          color: color(label, color);
        }

        .container {
          background: none;

          .mobile-table-row {
            background: color(background, body);
            padding: 10px 15px;
            border-radius: 5px;
            margin: 5px 0;

            dl {
              color: color(primary, main);
              margin: 0;

              dt, dd {
                display: inline-block;
                margin: 0;
              }

              dt {
                width: 40%;
              }

              dd {
                width: 60%;
              }
            }
          }

          .transactions-form {
            padding: 0;

            h2 {
              margin: 0 0 14px;
              text-align: left;
              font-size: 15px;
            }
          }

          .notFoundNotice {
            color: color(error);
            background: color(notice, background);
          }
        }
      }
    }
  }
}

