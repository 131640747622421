// game decorator window
.gd-window {
  position: relative;
  margin: auto;
  padding: 10px 40px 15px 20px;
  background: var(--gd-background);
  transition: .2s;

  .desktop & {
    width: 100%;
    position: relative;
    padding: 100px 10px 60px 10px;
    background: color(secondary, dark) top center fixed;
    background-size: cover;
    margin: 0;
  }

  &__wrapper {
    margin: 0 auto;
  }

  &__body {
    position: relative;

    &.game-decorator-info-panel-opened {
      .gd-window__game {
        transform: rotateY(13deg);
      }
    }
  }
  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 17px 20px 7px 20px;
    background: color(secondary, dark);
    color: var(--gd-text);

    @include media(1024px) {
      padding: 12px 10px 2px 10px;
    }
  }

  &__title,
  &__local-time {
    font-size: 15px;

    @include media(1024px) {
      font-size: 13px;
    }

    @include media(767px) {
      font-size: 10px;
    }
  }
  &__title,
  &__title-counterweight {
    body:fullscreen &,
    body:-webkit-full-screen & {
      flex: 1;
    }
  }
  &__title {
    margin: 0;

    span {
      display: block;
      color: var(--file-attachments-border);
    }
  }
  &__description {
    font-size: 15px;

    @include media(1024px) {
      font-size: 13px;
    }

    @include media(767px) {
      font-size: 10px;
    }

    .firstRow, .secondRow {
      display: none;
    }

    table {
      width: 100%;

      tbody {
        display: flex;
        justify-content: end;

        tr {
          display: flex;
          flex-direction: column;
          margin: 0 15px;

          @include media(1024px) {
            margin: 0 10px;
          }

          td:first-child {
            color: var(--file-attachments-border);
          }

          td {
            text-align: center;
          }
        }
      }
    }


  }
  &__local-time,
  &__title-counterweight {
    body:not(:fullscreen) &,
    body:not(:-webkit-full-screen) & {
      display: none;
    }
  }
  &__local-time {
    margin: 0 5px;
  }
  &__game {
    transform-origin: left;
    transition: all 0.3s;
    background: color(secondary, dark);
    padding: 5px;

    .prefill { // ?????
      position: relative;
      display: flex;
      flex-wrap: wrap;
      max-width: 60vw;
      min-height: 60vh;
      padding: 40px;
      align-items: center;
      z-index: 50;
      background: #fff;

      .prefill__description {
        color: color(text, light)
      }
      .button-root {
        margin-top: 10px;
      }
    }
  }
  &__footer {
    .game-provider-license {
      max-height: 32px;
      margin: 6px 0 -6px;
      text-align: center;
      transition: $main-transition-time;

      p {
        margin: 0;
        font-size: 12px;
        color: #5a5c5e;
        text-align: center;
        transition: all 0.4s ease;

        img {
          max-width: 100px;
          vertical-align: middle;
        }
      }
      &--hidden {
        max-height: 0;

        p {
          opacity: 0;
        }
      }
    }
  }
  &__main-section {
    width: 100%;

    .aspectRatio-root {
      z-index: 5;
      transition: 300ms ease;

      .aspectRatio-content {
        iframe.game__content {
          width: 100%;
          height: 100%;
          border: 0;
        }
      }
    }
    .prefill { // ?????
      position: relative;
      display: flex;
      flex-wrap: wrap;
      max-width: 60vw;
      min-height: 60vh;
      padding: 40px;
      align-items: center;
      z-index: 50;

      .prefill__description {
        color: color(text, light)
      }
      .button-root {
        margin-top: 10px;
      }
    }
  }
  &__info-section {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    right: 0;
    height: 100%;
    width: 0;
    overflow: hidden;
    transition: all 0.3s;
    background: #fff;

    &.show {
      width: 260px;
    }
  }
  &__info-section-content {
    max-height: 100%;
    overflow-y: auto;
    width: 260px;
    min-width: 260px;
    padding: 10px 10px 10px 20px;

    p {
      margin: 0;
      font-size: 14px;

      strong {
        display: block;
        margin-bottom: 10px;
        font-size: 16px;
      }
    }
  }
  &__controls-section {
    display: flex;
    align-items: center;
  }
  &__control-btn {
    margin:0 10px;
    padding: 0;
    border: 0;
    min-width: 0;
    min-height: 38px;
    display: block;

    @include media(1024px) {
      transform: scale(.8);
      margin: 0 5px;
    }

    @include media(767px) {
      transform: scale(.7);
      margin: 0 2px;
    }

    .button-label {
      position: absolute;
      right: calc(100% + 60px);
      top: 3px;
      width: auto;
      padding: 5px 10px;
      text-align: center;
      color: #fff;
      background-color: color(secondary, main);
      opacity: 0;
      white-space: nowrap;
      z-index: 5;
      transform: translate3d(40px, 0, 0);
      transition: 300ms ease;
      pointer-events: none;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: -10px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 13px 0 13px 10px;
        border-color: transparent transparent transparent color(secondary, main);
        transform: translateZ(0);
      }
    }
    &:hover {
      cursor: pointer;
      filter: brightness(1.6) contrast(0.7);
    }
    &::before {
      @include svg-icon('custom');
      color: color(primary, main);
      line-height: 38px;
      font-size: 38px;
      height: 38px;
      width: 38px;
    }
    &--close {
      position: relative;
      left: 1px;
      top: 1px;

      &::before {
        content: '\004f';
        font-size: 17px;
      }
    }
    &--full-screen {
      &::before {
        content: '\0056';
      }
    }
    &.fullScreenButton-cancel {
      &::before {
        content: '\0056';
      }
    }
    &--favourite-off {
      &::before {
        content: '\0057';
        font-size: 20px;
      }
    }
    &--favourite-on {
      &::before {
        content: '\0058';
        font-size: 20px;
      }
    }
    &--info-on, &--info-off {
      &::before {
        content: '\0057';
      }
    }
    &--info-on {
      &::before {
        background: #ffcc00;
      }
    }
  }
}
