.page-decorator__wrapper {
  .title {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: .5px;
    color: var(--modal-title-color-mobile);
  }

  .mobile-page-theme {
    @include media-xs {

      .container {
        position: relative;
        padding: 0;
      }
      .title {
        display: block;
        color: color(primary, main);
        background: var(--modal-background);
        text-align: center;
        padding: 15px 0;

        & + div {
          padding: 20px 0 0 0;
        }
      }
    }
  }
}
