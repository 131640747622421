.lvcVerificationConfirmSnapshots {
  &__images {
    display: flex;
    justify-content: center;

    @include media-xs {
      display: block;
    }
  }
}
