body .privacy-policy-modal {

  .modal-buttonRoot {
    @include media-xs {
      display: block;
    }
  }

  .modal-paper {
    .modalContentWrapper {
      @include media-xs {
        padding: 0;
      }
    }

    .confirmation-modal__buttons {
      gap: 10px;
    }
  }
}
