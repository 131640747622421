.authentication {
  &__methods {
    margin-bottom: 20px;
    .radioGroup-root {
      label {
        min-width: 20%;
      }
    }
  }
  &__success {
    text-align: center;
    padding-bottom: 25px;
  }
}
.login__title {
  height: 56px;
  font-size: 14px;
  letter-spacing: 1.2px;
  line-height: 18px;
  font-weight: 400;
  margin-top: 0;
  text-align: center;
  color: color(primary, main);
  background: color(secondary, dark);
  text-transform: uppercase;
}
