.promotions-page-content {
  position: relative;
  max-width: calc(100% + 30px);
  width: calc(100% + 30px);
  margin-left: -15px;

  &::before {
    content: '';
    width: calc(100% + 30px);
    height: calc(100% + 45px);
    position: absolute;
    top: -15px;
    left: -15px;
    background: url('/cms/img/promotions/promotions-bg.jpg') no-repeat;
    background-size: cover;
    z-index: -1;

    @include media(1024px) {
      height: 330px;
    }

    @include media(168px) {
      height: 360px;
    }

    @include media-xs {
      width: calc(100% + 15px);
    }
  }
}

body .promotions-page {
  padding-top: 12%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;

  @include media(768px) {
    padding-top: 315px;
  }

  .content-blocks {
    background: #fff;
    border-radius: 10px 10px 0 0;
    padding: 30px 30px 15px;

    @include media(1280px) {
      border-radius: 0;
    }

    .content-block {
      flex-direction: row;
      margin: 0 0 30px 0;
      border-radius: 10px 3px 10px 3px;

      @include media-sm {
        flex-direction: column;
        margin-bottom: 10px;
      }

      &-sub-header, &-content {
        width: 50%;

        @include media-sm {
          width: 100%;
        }
      }

      &-sub-header {
        display: flex;
        flex-direction: column;

        img {
          max-width: 610px;
          max-height: 300px;
          width: 100%;
          height: 100%;
          border-radius: 10px 0 0 3px;

          @include media-sm {
            width: 100%;
            max-height: initial;
            max-width: initial;
          }
        }
      }

      &-content {
        color: #fff;
        background: color(footer, bottom-section) url('/cms/img/promotions/star.svg') no-repeat right;
        display: flex;
        flex-direction: column;
        padding: 29px;
        border-radius: 0 3px 10px 0;

        @include media-sm {
          padding: 18px;
        }

        &__header {
          font-size: 28px;
          text-align: left;
          text-transform: uppercase;
          margin-bottom: 28px;

          @include media(1024px) {
            font: normal normal normal 18px/14px Nunito;
            line-height: initial;
            margin-bottom: 19px;
          }

          @include media-sm {
            font: normal normal normal 18px/14px Nunito;
            line-height: initial;
            margin-bottom: 19px;
          }
        }

        &__text {
          margin: 0 0 auto 0;
          font: normal normal normal 16px/19px Nunito;
          color: var(--file-attachments-border);
          text-align: left;

          @include media(1024px) {
            font: normal normal normal 12px/14px Nunito;
          }

          @include media-sm {
            font: normal normal normal 12px/14px Nunito;
            margin-bottom: 19px;
          }
        }
      }

      .button-root {
        min-width: 165px;
        width: 50%;
        margin: 0 auto;
        padding: 15px 30px;
        border: 1px solid color(footer, link);
        border-radius: 10px 3px;
        font: normal normal normal 18px/24px Nunito;
        text-transform: uppercase;
        text-decoration: none;
        color: color(footer, background);
        background: color(primary, main) 0 0 no-repeat padding-box;

        @include media(1024px) {
          padding: 9px 30px;
          font: normal normal normal 12px/16px Nunito;
        }

        @include media-sm {
          padding: 9px 30px;
          font: normal normal normal 12px/16px Nunito;
          width: max-content;
        }
      }
    }
  }
}
