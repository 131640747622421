.swiper-wrapper {
  position: relative;

  .view {
    position: absolute;
    bottom: 3px;
    z-index: 1;
    left: 50%;
    border-radius: 10px 10px 0 0;
    background: color(footer, link);
    color: color(button-accent, color);
    font-size: 12px;
    padding: 5px 10px;
    transform: translateX(-50%);

    &:hover {
      background: color(footer, link);

      &::after, &::before {
        filter: brightness(2) contrast(1);
      }
    }

    &::after, &::before {
      font-family: 'Glyphter-custom';
      content: '\004e';
      color: #746d4d;
    }

    &::before {
      margin-right: 10px;
    }

    &::after {
      margin-left: 10px;
    }
  }
}
.page-slider {
  height: calc(60vh - 77px);
  position: relative;
  margin-right: auto;
  margin-left: auto;
  white-space: nowrap;
  overflow: hidden;
  border-bottom: 4px solid color(footer, link);

  &::after {
    display: none;
  }

  @include media-xs-landscape {
    height: calc(100vh - 57px);
    min-height: 350px;
  }

  .swiper-slide {
    position: relative;
    z-index: 1;
    display: inline-block;
    width: 100%;
    height: 52.5vh;

    @include media-xs-landscape {
      min-height: 350px;
      height: calc(52.5vh - 57px);
    }
    @include media-xs {
      height: 55vh;
    }
  }

  &__image {
    @include size(100%);
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    object-fit: cover;
    height: 100%;

    @include media-min-lg {
      object-position: 50% 30%;
    }

    @include media-xs {
      object-position: top;
    }
  }

  &__content {
    position: absolute;
    z-index: 2;
    right: 0;
    left: 0;
    color: #fff;
    white-space: initial;
    display: flex;
    height: 100%;
    align-items: center;

    @include media-xs {
      left: 50%;
      transform: translateX(-50%);
      height: calc(100% + 50px);
    }

    .container {
      margin: 0 auto -15%;
      max-width: initial;
      padding: 0 7.5%;

      @include media-xs {
        margin-top: -90%;
      }
      @include media-xs-landscape {
        margin-top: -25%;
      }
    }

    .slide-center & {
      .container {
        text-align: center;
      }
    }
  }

  &__title {
    color: var(--content-title-color);
    font-size: 2rem;
    text-transform: none;
    line-height: 1.2;
    margin: 0;

    span {
      color: #e2aa1e;
    }

    @include media-md {
      font-size: 1.3rem;
    }

    @include media-sm {
      font-size: 1rem;
    }

    &[class*="mobile"] {
      font-size: 3.8vw;
    }

    .slide-center & {
      font-size: 2.35rem;

      @include media-md {
        font-size: 1.1rem;
      }
    }
  }

  &__text {
    font-size: 2rem;
    margin: 0 0 1.5rem;

    span {
      color: #e2aa1e;
    }

    @include media-md {
      font-size: 1.3rem;
      margin: 0 0 0.6rem;
    }

    @include media-sm {
      font-size: 1rem;
    }

    &[class*="mobile"] {
      margin: 0 0 5px;
      color: color(link, default);
      font-size: 3.8vw
    }

    .slide-center & {
      font-size: 1.7rem;

      @include media-md {
        font-size: .8rem;
        margin: 0 0 .5rem;
      }
    }
  }

  &__action {
    display: inline-block;
    font-weight: 400;
    text-transform: uppercase;
    border-radius: 10px 3px 10px 3px;
    padding: 10px 30px;
    font-size: 18px;
    line-height: 1.4;
  }

  // Wrapper
  .swiper-wrapper {
    position: relative;
    z-index: 1;
    height: 60vh;
  }

  // Pagination
  .swiper-pagination {
    position: absolute;
    z-index: 2;
    bottom: 3rem;
    left: 50%;
    display: flex;
    transform: translateX(-50%);

    @include media-xs {
      display: none;
    }

    &-bullet {
      @include size(1.4rem);
      display: block;
      position: relative;
      cursor: pointer;

      &::before {
        @include size(.69rem);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        background-color: rgba(255, 255, 255, .5);
        border-radius: 50%;
        content: '';
      }

      &-active {
        cursor: default;

        &::before {
          background-color: color(link, active);
        }
      }
    }
  }

  .swiper-button {
    &-next,
    &-prev {
      position: absolute;
      width: 20px;
      height: 71px;
      top: 50%;
      transform: translateY(-50%);
      background: rgba(12,12,12,0.75);
      transition: all .3s ease;
      z-index: 1;
      cursor: pointer;

      @include media-sm {
        display: none;
      }

      &::before {
        content: '';
        @include size(10px);
        display: block;
        position: absolute;
        top: 55%;
        margin-top: -9px;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &:hover {
        opacity: 1;
      }
    }

    &-next {
      right: 0;

      &::before {
        right: 6px;
        border-top: 1px solid color(primary, main);
        border-right: 1px solid color(primary, main);
      }
    }

    &-prev {
      left: 0;

      &::before {
        left: 6px;
        border-left: 1px solid color(primary, main);
        border-bottom: 1px solid color(primary, main);
      }
    }
  }
}

.swiper-wrapper {
  @include media-xs {
    .prelogin {
      height: calc(60vh - 50px);
    }
    .postlogin {
      height: calc(60vh - 90px);
    }
  }
}
