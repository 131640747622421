.lvcVerificationConfirmDocumentDetails {
  max-width: 350px;
  margin: 0 auto;

  .container {
    @include media-xs {
      padding: 0;
    }
  }

  &__document {
    padding: 10px;

    @include media-xs {
      padding: 10px 0;
    }
  }
}
