@mixin button-secondary-box-shadow($color: color(primary, dark)) {
  box-shadow: inset 0 0 0 1px $color;
}
@mixin button-accent($background: color(primary, main), $color: color(primary, dark)) {
  color: $color;
  background-color: $background;

  &:hover {
    :not(.mobile) & {
      color: $color;
      background-color: color(secondary, main);
    }
  }
}
@mixin button-primary($color: color(primary, main), $color-hover: color(secondary, main)) {
  color: $color;
  border: 1px solid $color;
  transition: .5s;
  font-size: 12px;
  line-height: 1.5;

  &:hover {
    :not(.mobile) & {
      color: $color-hover;
      border: 1px solid $color-hover;
      background: none;
    }
  }
}
@mixin button-secondary($color: var(--button-secondary-color), $border: var(--button-secondary-color)) {
  color: $color;
  border: 1px solid $border;

  &:hover {
    :not(.mobile) & {
      background: none;
    }
  }
}
@mixin button-default($color: var(--button-default-color), $background: color(primary, dark)) {
  min-width: 100px;
  position: relative;
  font-size: 13px;
  color: $color;
  background: $background;

  &:hover {
    color: color(secondary, main);
    background: $background;
  }
}
@mixin button-danger($color: color(button-danger, color), $background: color(attention)) {
  font-size: 12px;
  color: $color;
  background: $background;
  padding: 10px 23px 8px;

  &:hover {
    background: color(button-danger, hover);
  }
}
@mixin button-link($background: transparent, $color: color(primary, main)) {
  color: $color;
  background-color: $background;
  text-decoration: none;
  padding: 0;
  font-size: inherit;
  min-width: 0;
  min-height: 0;
  text-align: left;

  &:hover {
    color: #f2b735;
    background: none;
  }
}

.button-root {
  min-width: auto;
  min-height: auto;
  padding: 7px 19px 7px;
  border-radius: 10px 3px;
  font-size: 12px;
  font-family: $font-primary;
  color: var(--body-text-dark-color);
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 16px;
  cursor: pointer;

  &.button-loading {
    color: transparent;
  }
}
.button-accent {
  @include button-accent;
}
.button-primary {
  @include button-primary;
}
.button-secondary {
  transition: color $main-transition-time, background-color $main-transition-time;
  @include button-secondary;
}
.button-default {
  @include button-default;
}
.button-danger {
  background: color(attention);
  @include button-danger;
}
.button-link {
  @include button-link;
}
.button-disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.group-root--buttons {
  margin-top: 15px;
  margin-bottom: 15px;

  button + button {
    margin-left: 15px;
  }
}
.group-root--password-buttons {
  @include media-sm {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;

    .button-root {
      margin: 0;
    }
  }
}
