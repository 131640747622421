.takeSnapshotForm {
  &__content {
    height: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__form {
    display: flex;
    justify-content: space-between;

    @include media-sm {
      display: block;
      max-height: calc(100vh - 280px);
      flex-grow: 2;
      overflow-y: auto;
    }

    @include media-sm-landscape {
      display: flex;
      max-height: initial;
    }
  }

  &__video,
  &__snapshot {
    flex-basis: 48%;
    overflow: hidden;
    border-radius: 5px;
    background: color(popup, summary-background);

    @include media-sm {
      min-height: auto;
      max-width: 100%;
    }

    * {
      width: 100%;
      overflow: hidden;
      border-radius: 5px;
    }
  }

  &__video {
    @include media-xs {
      margin-bottom: 15px;
    }
  }

  &__snapshot.hidden {
    @include media-sm {
      display: none;
    }
  }

  &__buttons {
    margin-top: 15px;

    @include media-xs {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      justify-content: space-between;
    }

    .takeSnapshotForm__snap {
      margin-right: 15px;

      @include media-xs {
        margin-right: 0;
      }
    }
  }

  &__confirm {
    float: right;
  }
}

.take-snapshot-modal {
  z-index: 1700;

  h2 {
    padding-right: 45px;
  }
}
