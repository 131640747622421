/* Animation */

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

/*------------------------------------*\
          Style Guideline
\*------------------------------------*/

$font-fallback:
  // Safari for macOS and iOS (San Francisco)
  -apple-system,
    // Chrome < 56 for macOS (San Francisco)
  BlinkMacSystemFont,
    // Windows
  "Segoe UI",
    // Android
  "Roboto",
    // Basic web fallback
  "Helvetica Neue", Arial, sans-serif,
    // Emoji fonts
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-primary: 'Nunito';
$font-weight-base: 400;
$font-weight-alt: 700;

/* color variable map */
$colors: (
  primary: (
    main: #b5a147,
    light: #f5f5f5,
    dark: #2e2e2e,
  ),
  secondary: (
    main: #decf89,
    light: #bcbec0,
    dark: #1e1e1e
  ),
  accent: (
    main: #e3a52f
  ),
  info: #e3a52f,
  error: #c84b4b,
  success: #49a06a,
  warning: #ff7428,
  expired: #d7b354,
  attention: #C65252,
  disable: (
    dark: #a09f9f,
  ),
  label: (
    color: #bebebe,
    unfilled: #5E6061
  ),
  input: (
    text: #9f9f9f,
    background: #E0E0E0
  ),
  input-dark: (
    text: #bebebe,
    background: #211f22,
  ),
  input-mobile: (
    text: #bebebe,
    background: #2e2e2e,
  ),
  select: (
    text: #5E6061,
    background: #2e2e2e,
    list: #bebebe,
    arrow: #e3a52f,
    shadow-right: #19181a,
    shadow-left: #414141
  ),
  button-default: (
    color: #e3a52f,
    hover: #daa01c
  ),
  button-danger: (
    color: #e0e0e0,
    hover: #d58080
  ),
  button-accent: (
    color: #1d1d1d
  ),
  modal: (
    header: #1d1d1d,
    title: #e3a52f,
    title-mobile: #f1f1f1,
    title-background: #1d1d1d,
    text: #bebebe,
    border: #141414,
    background: #141414,
    background-login: #2e2e2e
  ),
  close-button: (
    main: #e3a52f,
    background: #2e2e2e
  ),
  footer: (
    text: #bcbec0,
    link: #a79130,
    background: #1e1e1e,
    bottom-section: #292929
  ),
  turnover-progress: (
    text: #fff,
    progress: #49A06A,
    background: #211f22
  ),
  content: (
    title: #fff,
    background: #fff
  ),
  text: (
    main: #666,
    light: #fff,
    dark: #333
  ),
  link: (
    default: #e2a415,
    hover: #DAA01C,
    active: #DAA01C
  ),
  main-menu: (
    link: #BCBEC0,
    link-hover: #f1f1f1,
    link-active: #ffcc00,
  ),
  static-menu: (
    border: #e8e8e8,
    background: #f8f8f8
  ),
  loyalty-statuses: (
    bronze: #6398e6,
    silver: #f9813f,
    gold: #ffcd13,
    platinum: #339999,
  ),
  limit-amount: (
    progress-back: #ccc,
    progress-path: #ffcc00,
    switch-default: #ccc,
    background: #f6f6f6,
    color: #606060,
    date-background: #dedede
  ),
  background: (
    header: #141414,
    footer: #a4a6a7,
    aside-menu: #fff,
    home-menu: #1D1D1D,
    protection: #333,
    modal: #fff,
    post-login-menu: #fff,
    post-login-content: #fff,
    static-menu: #fff,
    static-content: #fff,
    filter: #fff,
    game-window: #fff
  ),
  body: (
    color: #1e1e1e,
    background: color(primary, light)
  ),
  promotions-card: (
    title-color: #211f22,
    title-background: #e2a415,
    text: #bebebe,
    link-color: #1D1D1D,
    link-background: #E3A52F,
    background: #2E2E2E,
    block-background: #d3d3d3
  ),
  table: (
    even-background: #ebebeb,
  ),
  popup: (
    summary-background: #171718
  ),
  settings-menu: (
    background: #2b2b2b,
    border: #363636,
    shadow: #1d1d1d,
    link: #4f5051,
    active-link: #a5a6a9
  ),
  details-summary: (
    color: #4f5051,
    border-top: #2E2E2E,
    border-bottom: #070707
  ),
  notice: (
    color: #f1f1f1,
    background: #272628,
    hr: #0f0f10
  ),
  verification: (
    label: #cccccc,
    background-left: #000000,
    background-right: #B5A147,
    title-left: #A79130,
    title-right: #2E2E2E,
    select-left: #907e2c,
    select-right: #a89433,
    option-name: #000000,
    close-button-backgrount: #1D1D1D
  ),
  verification-mobile: (
    title: #2e2e2e,
    select-top: #907e2c,
  ),
  alarm: (
    background: #a79130,
    color: #f5f5f5
  )
);

$preloading-width: 120px;
$preloading-height: 4px;
$preloading-time: 5s;

// heights
$header-collapsed-height: 0px;
$header-expanded-height: 0px;

// retrieve color from map. Example: 'color: color(primary, main);', 'background: color(background)'
@function color($color-name, $color-variant: null) {
  // color variant is optional
  @if ($color-variant != null) {
    @return map-get(map-get($colors, $color-name), $color-variant);
  }
  @else {
    @return map-get($colors, $color-name);
  }
}

/* Mixins */

@mixin svg-icon($name: 'Glyphter') {
  display: inline-block;
  @if $name == 'custom' {
    font-family: 'Glyphter-custom';
  } @else {
    font-family: 'Glyphter';
  }
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: initial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}
@mixin truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin live-chat-button {
  width: 171px;
  height: 46px;
  min-height: 46px;
  padding-left: 68px;
  font-size: 15px;
  overflow: hidden;

  &::before {
    content: ' ';
    width: 46px;
    height: 46px;
    background: color(primary, main);
    position: absolute;
    left: 0;
    border-radius: 10px 0 0 3px;
  }

  &::after {
    position: absolute;
    left: 12px;
    top: 6px;
    width: 26px;
    height: 24px;
    font-family: Glyphter-custom;
    content: '\004f';
    color: color(primary, dark);
    font-size: 24px;
  }
}

$main-transition-time: .25s;

$size-xl: 1920px;
$size-lg: 1440px;
$size-md: 1024px;
$size-sm: 767px;
$size-xs: 480px;


@mixin media($max, $min: null) {
  @if ($min == null) {
    @media (max-width: $max) {
      @content;
    }
  } @else if($max == null) {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @media (max-width: $max) and (min-width: $min) {
      @content;
    }
  }
}
@mixin media-xl {
  @media (max-width: $size-xl) {
    @content;
  }
}
@mixin media-lg {
  @media (max-width: $size-lg) {
    @content;
  }
}
@mixin media-md {
  @media (max-width: $size-md) {
    @content;
  }
}
@mixin media-sm-landscape {
  @media (max-width: $size-sm) and (orientation: landscape) {
    @content;
  }
}
@mixin media-sm {
  @media (max-width: $size-sm) {
    @content;
  }
}
@mixin media-xs-landscape {
  @media (max-width: $size-md) and (orientation: landscape) {
    @content;
  }
}
@mixin media-xs {
  @media (max-width: $size-xs) {
    @content;
  }
}
@mixin media-min-lg {
  @media (min-width: $size-lg) {
    @content;
  }
}


// Size
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
@mixin customVh($vh: 100) {
  height: calc(var(--vh, 1vh) * #{$vh});
  will-change: height;
}

// Browsers check hacks
// Microsoft Edge
@mixin isMicrosoftEdge {
  @supports (-ms-ime-align: auto) {
    @content;
  }
}

// Mozilla Firefox
@mixin isMozillaFirefox {
  @-moz-document url-prefix() {
    @content;
  }
}

// WebP background images with fallback
@mixin webp-bg-img($webp, $fallback) {
  background-image: url($webp);

  html.no-webp & {
    background-image: url($fallback);
  }
}

// Close button
@mixin close-button {
  @include size(30px);
  position: relative;
  min-width: 20px;
  min-height: 20px;
  padding: 0;
  flex: 0 0 auto;
  font-size: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  background: none;
  backface-visibility: hidden;

  &::before {
    @include svg-icon;
    content: '\004f';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--close-button-color);
    font-size: 15px;
    transition: all $main-transition-time;
  }

  &:hover {
    &::before {
      transform: translate(-50%, -50%) rotateZ(180deg);
    }
  }
}

// Regular Vegas linear gradient background
@mixin regular-vegas-linear-gradient {
  background-image: linear-gradient(to bottom, color(background, body) 0, color(background, body) 50%, color(promotions-card, title-color) 50%, color(promotions-card, title-color) 100%);
}

@mixin regular-vegas-separator {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  box-shadow: -1px 0 0 #000000, -2px 0 0 var(--home-menu-icon-color);

  @include media-sm {
    margin-bottom: 0;
  }
}

@mixin modal-fullscreen-mode {
  width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  border-radius: 0;
}

@mixin select-dropdown-arrow {
  background: color(input-dark, background);
  height: 40px;
  width: 40px;
  border: 1px solid color(select, background);
  text-align: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: -1px 0 0 color(select, shadow-left), -2px 0 0 color(select, shadow-right);
  fill: color(primary, main);
  top: 0;
}

@mixin custom-hint {
  .input-input {

    &--hint, &--hint__static {
      right: 9px;
      width: 14px;
      height: 14px;
      font-size: 14px;
      border-radius: 50%;
      text-align: center;
      font-weight: bold;
      cursor: help;
      line-height: 1;

      + span {
        display: none;
        padding: 2px 5px;
        border-radius: 3px;
        border: 1px solid color(input, text);
        background: #fff;
        color: #707070;
        z-index: 1100;
        right: 35px;
        position: absolute;
        font-size: 10px;
        font-weight: normal;
        max-width: 200px;
        text-align: left;
        line-height: 1.5;
        margin-top: 30px;

        @include media-xs {
          color: color(input-dark, background);
          background: color(primary, main);
          border: none;
        }
      }
    }

    &--hint {
      position: absolute;
      top: calc(50% - 7px);
      color: color(input, text);
      font-weight: 900;

      &__static {
        position: static;
        display: inline-block;

        + span {
          margin: 0;
          right: -90px;
          width: 200px;
          text-transform: inherit;
        }
      }
    }

    &--hint:hover, &--hint__static:hover {
      + span {
        display: block;
      }
    }
  }
}

:root {
  --body-background: #{color(primary, light)};
  --body-text-dark-color: #{color(text, dark)};
  --body-text-light-color: #{color(text, light)};

  --content-title-color: #{color(content, title)};
  --content-background: #{color(content, background)};

  --home-menu-background: #{color(background, home-menu)};
  --home-menu-item-color: #5E6061;
  --home-menu-icon-color: #2f2f2f;
  --home-menu-swiper-from: rgba(255, 255, 255, 1);
  --home-menu-swiper-to: rgba(255, 255, 255, 0);

  --header-background: #{color(background, header)};
  --header-color: #{color(primary, dark)};
  --header-border: 1px solid #{lighten(color(primary, dark), 60%)};
  --header-box-shadow: none;

  --aside-menu-background: #{color(background, aside-menu)};
  --aside-menu-link-color: #{color(main-menu, link)};
  --aside-menu-link-color-hover: #{color(main-menu, link-hover)};
  --aside-menu-border-color: #ccc;

  --close-button-color: #{color(primary, main)};

  --button-secondary-color: #{var(--file-attachments-border)};
  --button-secondary-hover-color: #{color(primary, light)};
  --button-default-color: #{color(primary, main)};
  --button-default-background: #{color(primary, dark)};

  --post-login-content-background: #{color(background, post-login-content)};
  --post-login-menu-wrap-background: #{color(background, post-login-menu)};
  --post-login-settings-menu-background: #f8f8f8;
  --post-login-settings-menu-border-color: #e8e8e8;
  --post-login-menu-link-color: #{color(primary, dark)};

  --footer-background: #{color(footer, background)};
  --footer-top-section-background: rgba(0, 0, 0, 0.10);
  --footer-link-hover-color: #{color(footer, link-active)};

  --primary-light-color: #{color(primary, light)};
  --preloader-backgound: #fff;

  --modal-title-background: #{color(modal, title-background)};
  --modal-background: #{color(modal, background)};
  --modal-background-login: #{color(modal, background-login)};
  --modal-title-color: #{color(modal, title)};
  --modal-title-color-modal: #{color(modal, title-modal)};
  --modal-text-color: #{color(modal, text)};

  --static-page-background: #{color(background, static-content)};
  --static-menu-background: #{color(background, static-menu)};
  --static-item-color: #{color(primary, dark)};

  --select-icon-color: #{color(select, arrow)};
  --select-menu-background: #{color(select, background)};
  --select-menu-item-color: #{color(select, text)};

  --form-secondary-background: #f2f2f2;

  --table-even-background: #{color(table, even-background)};
  --paginator-background: var(--body-background);

  --notification-background: #{color(primary, light)};
  --notification-color: #{color(text, main)};

  --limit-amount-color: #777;
  --limit-amount-second-color: #{color(primary, dark)};
  --limit-amount-link-color: #{color(modal, text)};
  --limit-amount-background: #{color(limit-amount, background)};

  --limit-modal-color: #{color(modal, border)};

  --transactions-bets-background: #{color(primary, light)};

  --game-thumbnail-background: var(--body-background);
  --game-slider-nav-background: rgba(255, 255, 255, 0.7);
  --game-slider-nav-icon-color: #{color(secondary, main)};

  --game-info-page-inner-background: #fff;

  --winner-list-widget-box-shadow: inset 0 10px 10px -10px #eee;

  --balance-popover-background: #{color(modal, header)};
  --balance-popover-border-color: #{lighten(color(primary, dark), 50%)};
  --balance-popover-border: 1px solid var(--balance-popover-border-color);

  --protection-login-form-input-background: #{color(primary, light)};
  --protection-adornment-password-background:  #{color(primary, light)};

  --adornment-password-color: #{var(--file-attachments-border)};

  --input-color: #{color(input, text)};
  --input-color-login: #{color(input-dark, text)};
  --input-color-mobile: #{color(input-mobile, text)};
  --input-background: #{color(input, background)};
  --input-background-login: #{color(input-dark, background)};
  --input-background-mobile: #{color(input-mobile, background)};
  --input-label-unfilled-color: #{color(label, unfilled)};
  --input-label-filled-color: #{color(label, filled)};
  --input-label-mobile-color: #{color(label, color)};
  --input-webkit-autofill-box-shadow: inset 0 0 0 100px #{color(input, background)};
  --input-webkit-autofill-box-shadow-login: inset 0 0 0 100px #{color(input-dark, background)};
  --input-webkit-text-fill-color: #{color(input, text)};
  --input-webkit-text-fill-color-login: #{color(input-dark, text)};

  --games-filter-background: #{color(background, filter)};
  --games-filter-text-color: #{color(text, dark)};
  --games-filter-text-checked-color: #{color(text, dark)};

  --gd-background: #{color(background, game-window)};
  --gd-text: #fff;
  --gd-footer-background: #{color(primary, light)};
  --gd-error-background: #{color(background, game-window)};

  --activity-form-cell-color: #000;
  --activity-form-post-login-title: #fff;

  --file-attachments-border: #637284;
  --file-attachments-color: #313131;

  --game-status-win: #2c774f;
  --game-status-loss: #C65252;

  --home-menu-background-mobile: #e1e1e1;

  --loyality-program-color: #54503d;

  --loyalty-level-bronze: #7b7348;
  --loyalty-level-silver: #b6b6b5;
  --loyalty-level-gold: #a79131;
  --loyalty-level-platinum: #808c9b;
}
