.form-control-hint-block {
  z-index: 10;
  position: absolute;
  top: 11px;
  right: 10px;
  width: 18px;
  height: 18px;
}
.form-control-hint-block_offset {
  top: 36px;
  right: 50px;
}
.form-control-hint-block__icon{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    @include svg-icon('custom');
    font-size: 10px;
    color: color(input, text);
    border-radius: 50%;
    padding: 3px;
  }
  @include media-xs {
    background: initial;
  }
}
.form-control-hint-block__icon_valid {
  &:before {
    content: '\0053';
    background-color: #247e55;
    color: #f1f1f1;
    @include media-xs {
      background-color: initial;
      color: #247e55;
    }
  }
}
.form-control-hint-block__icon_questions {
  &:before {
    content: '\0052';
    font-weight: bold;
    cursor: help;
  }
}
.form-control-hint-block__icon_error {
  &:before {
    content: '\0054';
    background-color: #c84b4b;
    color: #f1f1f1;
    @include media-xs {
      background-color: initial;
      color: #c84b4b;
    }
  }
}
.form-control-hint-block__text {
  position: absolute;
  top: 29px;
  right: -60px;
  z-index: 1100;
  max-width: 200px;
  width: max-content;
  display: none;
  border: none;
  border-radius: 3px;
  padding: 2px 5px;
  background-color: color(primary, main);
  color: color(input-dark, background);
  font-size: 10px;
  line-height: 1.5;
  @include media-sm {
    max-width: 120px;
    right: 20px;
  }
  @include media-xs {
    max-width: 200px;
    right: 0;
  }

}
.form-control-hint-block__icon:hover + .form-control-hint-block__text {
  display: block;
}
