.fee-modal {
  .modal-paper {
    width: 767px;
  }
  .modal-rootButtons {
    display: none;
  }
  .modal-paper .modalContentWrapper .modal-titleRoot {
    h2 {
      color: #000;
      text-transform: uppercase;
    }
  }
  .buttons {
    margin-top: 20px;
    text-align: initial;
  }
  .notification-root {
    color: #f1f1f1;
    background-color: #272628;
    padding: 19px 23px;
    border-radius: initial;
  }

  @include media-sm {
    &.modal-root {
      top: 90px;
    }

    .modal-paper {
      background: #f5f5f5;
      box-shadow: none;
    }
    & > div:first-child {
      opacity: 0 !important;
    }
    .buttons {
      margin-top: 20px;
      text-align: center;
    }

    .notification-root {
      color: initial;
      background: none;
      padding: 10px 15px;
    }

    .modal-paper .modalContentWrapper {
      padding: 0;

      .modal-titleRoot,
      .modal-titleRoot + .modal-contentRoot {
        padding-right: 10px;
        padding-left: 10px;
      }

      .modal-titleRoot + .modal-contentRoot {
        padding-top: 20px;
      }

      .modal-titleRoot {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #1e1e1e;
        height: 56px;

        h2 {
          color: #b5a147;
          text-align: center;
          margin: 0;
        }
      }
      .modal-contentRoot {
      }
    }
  }

  &__fee-details {
    display: grid !important;
    height: auto!important;
    grid-template-columns: 2fr 1fr;
    padding: 10px 15px;
    border-radius: 4px;
    background-color: #E0E0E0;
    margin-top: 10px;

    & > div {
      padding: 8px;

      &:nth-child(5), &:nth-child(6) {
        font-weight: 700;
      }
    }

    @include media-sm {
      background-color: initial;
      padding: 10px 0;

      & > div {
        padding: 3px 0;
      }
    }
  }
}
