.loyalty-program {
  $spacer: (
    lg: 50px,
    sm: 30px
  );
  $color-brand: color(primary, main);
  $color-muted: #a09f9f;
  $color-minor: #c3c3c3;

  @include media-sm {
    padding: 10px 0 map-get($spacer, sm);
  }

  @include media-xs {
    color: var(--file-attachments-border);
  }

  // Reset
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
  }

  // Description
  &__description {

    p {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  // Steps
  &__steps {
    $icon-steps-size: 96px;

    @include media-sm {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-list {
      position: relative;
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
      width: auto;
      margin: 0 -10px;

      @include media-sm {
        display: inline-block;
        margin: 0;
      }
    }
    &-line-through {
      position: absolute;
      z-index: 1;
      top: calc($icon-steps-size / 2);
      left: 50%;
      transform: translate(-50%, -50%);
      // `width` is calculated based on the number of steps
      height: 16px;
      background: $color-brand;

      @include media-sm {
        top: calc($icon-steps-size / 2);
        bottom: calc($icon-steps-size / 2);
        left: calc($icon-steps-size / 2);
        transform: translate(-50%, 0);
        width: 16px !important;
        height: auto;
      }
    }
    &-item {
      position: relative;
      z-index: 2;
      padding: 0 10px;

      @include media-sm {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0;

        &:not(:last-child) {
          margin-bottom: map-get($spacer, sm);
        }
      }

      &-icon {
        @include size($icon-steps-size);

        position: relative;
        margin: 0 auto;
        background: $color-brand;
        border-radius: 50%;

        @include media-sm {
          flex-shrink: 0;
          margin: 0;
        }

        &::before {
          @include svg-icon;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 64px;
        }

        &--step-1 {
          &::before {
            content: '\005f';
          }
        }
        &--step-2 {
          &::before {
            content: '\0060';
          }
        }
        &--step-3 {
          &::before {
            content: '\007b';
          }
        }
      }

      &-description {
        margin-top: 10px;
        text-align: center;

        @include media-sm {
          margin-top: 0;
          margin-left: 20px;
          text-align: left;
        }
      }
    }
  }

  // Form
  &__description {
    margin-bottom: 70px;
    border-bottom: 1px solid color(promotions-card, block-background);
    padding: 10px 15px;
    font-size: 18px;
    color: var(--file-attachments-border);
    text-transform: uppercase;

    .period-ends {
      text-transform: initial;
      font-size: 15px;
      text-align: right;

      @include media-xs {
        margin-top: 15px;
        text-align: center;
      }

      strong {
        display: inline;
        font-size: 15px;
      }
    }

    @include media-xs {
      border: none;
      text-align: center;
      margin-bottom: 0;
      padding: 0;
    }

    strong {
      display: block;
      font-weight: normal;
      font-size: 12px;
      color: #d27b7a;

      @include media-xs {
        font-size: 10px;
      }
    }
  }

  &__help {
    background: color(promotions-card, block-background);
    text-align: center;
    font-size: 15px;
    padding: 10px 15px;

    @include media-xs {
      background: none;
      padding: 0;
      font-size: 14px;
    }
  }
  // Progress
  &__progress {
    // Info
    &-info {
      margin-bottom: calc(map-get($spacer, lg) / 2);

      &-title {
        margin-bottom: calc(map-get($spacer, lg) / 2);
        font: {
          size: 22px;
          weight: bold;
        }
        text-align: center;

        @include media-sm {
          margin-bottom: map-get($spacer, sm);
        }
      }
      &-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        list-style: none;
        border: 1px solid color(promotions-card, block-background);
        border-radius: 5px;

        @include media-xs {
          border: 1px solid #000;
          border-radius: 10px;
          background: color(table, even-background);
          padding: 10px;
          width: 100%;
          margin: 20px 0;
        }
      }
      &-item {
        padding: 10px;
        width: 100%;

        @include media-sm {
          padding: 0;

          &:not(:last-child) {
            margin-bottom: map-get($spacer, sm);
          }
        }
      }
      &-block {
        &-title {
          flex-basis: 100%;

          @include media-xs {
            margin: 10px 0;
            //p {
            //  font-size: 14px;
            //}
          }

          strong {
            text-transform: uppercase;
            color: var(--loyalty-level-bronze);
            font-weight: normal;
          }
        }
        &-content {
          p {
            font: {
              size: 18px;
            }
            line-height: 1;

            @include media-xs {
              font-size: 14px;
            }

            a {
              font-size: 14px;
              white-space: nowrap;
            }
          }

          &.has-icon {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include media-xs {
              flex-direction: column;
              text-align: center;
            }

            img {
              position: relative;
              flex: 0 0 auto;
              display: block;
              margin-right: 5px;
            }

            p {
              &::first-letter {
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }

    // Tiers
    &-tiers {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid color(promotions-card, block-background);
      border-radius: 5px;
      position: relative;
      flex-direction: column;

      @include media-xs {
        border: none;
      }
    }
    &-tier-current {
      width: 73%;
      position: relative;
      top: 66px;

      @include media-xs {
        transform: rotate(90deg);
        width: 95%;
        top: 240px;
      }

      .linearProgress {
        &-content > div {
          position: absolute;
          margin-left: -100px;
          width: 100px;
          top: -115px;
          transform: translateX(50%);
          background: color(success);
          border-radius: 10px 3px 10px 3px;
          padding: 3px 10px;
          color: #ced8d1;
          text-align: center;
          animation: 1.1s swingViewport;

          @include media-xs {
            top: auto;
            bottom: -155px;
            transform: rotate(-90deg);
            margin-left: -50px;
          }

          @keyframes swingViewport {
            from {
              left: 0;
            }
          }

          &::before {
            content: '';
            position: absolute;
            left: 50%;
            height: 65px;
            top: 50px;
            border: 0.5px dashed color(success);

            @include media-xs {
              transform: rotate(-90deg);
              left: auto;
              right: -47%;
              top: -20px;
              height: 75px;
            }
          }
          &::after {
            content: '';
            position: absolute;
            left: calc(50% - 4px);
            bottom: -4px;
            width: 8px;
            height: 8px;
            background: color(success);
            transform: rotate(45deg);

            @include media-xs {
              bottom: calc(50% - 4px);
              right: -4px;
              left: auto;
            }
          }
        }

        &-progressLine {
          height: 2px;
        }
      }
    }

    &-tiers-list {
      $border-width: 10px;
      flex: 1;
      position: relative;
      flex-wrap: nowrap;
      padding: $border-width;

      @include media-xs {
        flex-direction: column;
        align-items: center;
      }
    }
    &-tiers-item {
      padding: 10px;
      text-align: center;

      @include media-sm {
        max-width: 25%;
        flex-basis: 25%;
      }

      @include media-xs {
        //display: flex;
        //width: 75%;
        //max-width: 75%;
        //flex-basis: 75%;
      }

      &-icon {
        @include size(70px);
        position: relative;
        margin: 0 auto 15px;
        border-radius: 50%;

        @include media-xs {
          margin-bottom: 0;
        }

        img {
          position: absolute;
          z-index: 1;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        span {
          position: absolute;
          z-index: 2;
          top: 100%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding: 2px 4px;
          background-color: #e1e1e1;
          color: $color-muted;
          content: attr(data-amount);
          white-space: nowrap;

          .is-current & {

          }
        }
      }
      &-title {
        font-weight: 700;
        text-transform: uppercase;
        font-size: 14px;

        @include media-xs {
          position: relative;
          top: -50px;
          left: 90px;
          text-align: left;
          white-space: nowrap;
          font-weight: normal;
        }

        &--bronze {
          color: var(--loyalty-level-bronze)
        }

        &--silver {
          color: var(--loyalty-level-silver);
        }

        &--gold {
          color: var(--loyalty-level-gold);
        }

        &--platinum {
          color: var(--loyalty-level-platinum);
        }
      }

      &-description {
        font-weight: normal;
        font-size: 14px;

        @include media-xs {
          text-transform: initial;
        }
      }

      &.is-current {
        ~ div {
          .loyalty-program__progress-tiers-item-icon {
            //background-color: $color-minor;

            img {
              //filter: contrast(0);
              //mix-blend-mode: screen;
            }
            span {
              background-color: color(primary, main);
              color: color(primary, dark);
            }
          }
          .loyalty-program__progress-tiers-item-title,
          .loyalty-program__progress-tiers-item-description {
            //color: $color-minor;
          }
        }
      }
    }
  }

  // Circular progress
  &__circular-progress {
    &-content {
      padding: 40px;
      text-align: center;
    }
    &-amount {
      font: {
        size: 48px;
        weight: bold;
      }
    }
  }

  // Tier expiry
  &__tier-expiry {
    margin-top: 10px;
    color: $color-muted;
    text-align: right;

    @include media-sm {
      text-align: center;
    }
  }
}
