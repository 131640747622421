// Netherlands login notification
.netherlands-login-notification {
  position: relative;
  width: 640px;
  margin-left: auto;

  @include media-sm {
    width: 100%;
    margin-left: 0;
  }

  // Root
  &.notification-root {
    display: block;

    &::before {
      display: none;
    }
    .notification-message {
      padding: 0;
      color: var(--limit-amount-second-color);
      font-size: 14px;
    }
    .notification-action {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  // Col
  &--col {
    padding: 16px 20px 20px;

    @media all and (min-width: 480px) {
      max-width: 50%;
      flex-basis: 50%;
    }
    &-title {
      margin-bottom: 16px;
      color: var(--limit-amount-color);
      font-weight: $font-weight-base;
      text-transform: uppercase;
    }
    &-limits {
      background-color: var(--limit-amount-background);
    }
    &-limits:first-of-type {
      border-right: 1px solid;
      border-color: var(--limit-modal-color);
    }
  }

  // Limits
  &--limit {
    position: relative;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    &-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: baseline;
      margin-bottom: 4px;
    }
    &-title {
      font-weight: $font-weight-alt;
      text-transform: uppercase;

      &:not(:last-child) {
        margin-right: 12px;
      }
    }
    &-description {
      color: var(--limit-amount-color);
      font-size: 12px;
    }
  }

  &--nld-limit {
    .netherlands-login-notification--col {
      padding: 16px 20px 28px;
    }

    .netherlands-login-notification--limit {
      &-title-not-reach{
        color: color(accent, main);
      }
      &:not(:last-child) {
        margin-bottom: 26px;
      }
      &::before {
        content: "";
        position: absolute;
        border-width: 4px 6px 0 6px;
        border-style: solid;
        border-color: transparent;
        border-top-color: color(accent, main);
        z-index: 99;
        margin-top: 1px;

        top: 100%;
        left: 50%;
        transform: translatex(-50%) rotate(180deg);
      }
      &::after {
        content: "50 %";
        position: absolute;
        background: color(accent, main);
        text-align: center;
        color: #fff;
        font-size: 12px;
        border-radius: 2px;
        width: 31px;
        height: 18px;
        font-weight: 600;
        pointer-events: none;
        z-index: 99;
        margin-top: 5px;

        left: 50%;
        top: 100%;
        transform: translatex(-50%);
      }
    }
  }

  // Max balance Limit
  &--max-balance {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 20px 12px 20px;
    background: var(--limit-amount-background);
    border-top: 1px solid;
    border-color: var(--limit-modal-color);

    @include media-xs {
      flex-direction: column;
    }
    a {
      font-size: 13px;
      color: var(--limit-amount-link-color) !important;
    }
    p {
      @include media-xs {
        margin-top: 7px;
      }
    }
    &-wrapper {
      display: flex;
    }
    &-title {
      margin-right: 5px;
      text-transform: uppercase;
      color: color(text, light);
    }
  }

  // Last login time
  &__last-login-time {
    padding: 12px;
    background: color(primary, main);
    color: color(primary, dark);
    text-align: center;

    &::before {
      @include svg-icon;
      content: "\006d";
      position: relative;
      top: -1px;
      margin-right: 4px;
      font-size: 16px;
      vertical-align: middle;
    }
  }
}
