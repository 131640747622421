body .reality-check-modal {
  @include media-xs {
    height: auto;
    padding: 10px;
  }

  .modal-paper {
    @include media-xs {
      border-radius: 6px;
      box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
    }
    .modalContentWrapper {
      .modal-titleRoot {
        h2 {
          text-transform: uppercase;
          margin: 0;

          @include media-xs {
            color: color(secondary, dark);
            font-size: 24px;
            font-weight: normal;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
  &__descriptor {
    &-addon {
      display: inline;
      padding-right: 5px;

      p {
        display: inline;
        margin: 0;
      }
      & + .button-default {
        min-height: 0;
        padding: 0;

        &::after {
          bottom: 0;
          width: 100%;
        }
      }
    }
  }
  &__buttons {
    margin: 0;

    button,
    button + button {
      @include media-xs {
        padding: 11px 14px 8px;
      }
    }
    @include media-xs {
      padding: 0;

      button + button {
        margin: 5px 0 0 5px;
      }
    }
  }
}
