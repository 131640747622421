.fields-container {
  .gridItem-typeItem {
    width: 100%;
  }
}
.field-input,
:not(.select-locale) > .field-select,
.select-root,
.field-string,
.field-phoneNumberField {
  width: 100%;
  max-width: 100%;
  font-family: $font-primary;

  label,
  legend {
    font-family: $font-primary;
    position: relative;
    color: color(secondary, dark);
    pointer-events: none;
    transition: none;
    transform: none;
    transform-origin: initial;
    margin-bottom: 5px;
    font-size: 14px;
    z-index: 2;
    line-height: 1.5;
    white-space: nowrap;

    @include media-xs {
      font-size: 12px;
    }

    &[data-shrink="false"] {
      position: relative;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      font-size: 14px;

      @include media-xs {
        font-size: 12px;
      }

      & + div {
        > .input-input--type--date,
        > .dateInput-root,
        .dateInput-input {
          color: transparent;

          &::-webkit-input-placeholder {
            color: transparent;
          }
          &::-moz-placeholder {
            color: transparent;
          }
        }
      }
    }
  }
  &.field-disabled {
    label,
    legend {
      color: color(label, disabled);

      & + div,
      & + .phone-number-wrapper > div {
        &::before {
          background: color(input, disabled) !important;
        }
        .select-root .select-icon {
          fill: color(input, disabled) !important;
        }
      }

      & + div .select-root,
      &[data-shrink="true"] + div {
        input,
        .select-disabled {
          color: color(input, disabled);
          -webkit-text-fill-color: color(input, disabled);
        }
      }
    }
    .input-disabled,
    .input-disabled:hover {
      &::before {
        background: color(input, disabled);
      }
    }
  }
  .input-multiline {
    padding: 0;
  }
}
:not(.select-locale) > .field-select label + div,
:not(.select-locale) > .field-select legend + div,
.field-input .input-root,
.field-checkbox,
.field-input .phone-number-wrapper {
  margin: 0;

  > .input-inputDisabled, .select-disabled, .checkbox-disabled {
    opacity: 0.65;
  }

  > .input-input--type--date {
    appearance: textfield;
  }
}
:not(.select-locale) > .field-select > div,
.field-input .input-root:first-child {
  margin-bottom: 5px;
}
.field-root {
  .field-helper {
    margin-top: 5px;
    font-family: $font-primary;
    font-size: 10px;

    &Hint {
      color: color(input, hint);
    }
    &Error {
      color: color(error);
    }
  }
}
.input-focused:not(.input-error) + .field-helperHint {
  color: color(input, hint);
}
input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"]:not(.dateInput-input),
.input-input--type--date,
.dateInput-root, // Safari
.select-selectMenu,
textarea.input-inputMultiline {
  max-width: 100%;
  height: 40px;
  padding: 9px 15px;
  line-height: 30px;
  font-size: 14px;
  border: 0;
  border-radius: 3px;
  color: var(--input-color);
  background: var(--input-background); // fix for safari, otherwise text is invisible
  box-shadow: none;
  font-family: $font-primary;
}
input[type="password"] {
  padding-right: 45px;
}
input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"]:not(.dateInput-input),
.input-input--type--date,
.dateInput-root {
  @include isMozillaFirefox {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none !important;
}
.input-before {
  color: color(input, text);
}
.dateInput-root { // Safari
  -webkit-appearance: initial;
}
textarea.input-inputMultiline {
  height: 100%;
  line-height: 1.5;
}
.field-input,
:not(.select-locale) > .field-root.field-select {
  .input-underline,
  label + div,
  .phone-number-wrapper > div,
  > div {
    &::before {
      content: none;
    }
    &:hover {
      &::before {
        content: none;
      }
    }
  }
  .input-focused,
  .select-focused,
  label + div:focus {
    &::after {
      content: none;
    }
  }
}
:not(.select-locale) > .field-error {
  .input-error,
  &.field-select label + div,
  &.field-select .select-focused,
  .phone-number-wrapper > div {
    &::after {
      content: none;
    }
  }
}
input {
  outline: none;
}

/* Remove background from autofilled inputs in Chrome */
input:-webkit-autofill {
  -webkit-box-shadow: var(--input-webkit-autofill-box-shadow) !important;
  -webkit-text-fill-color: var(--input-webkit-text-fill-color);
  -webkit-background-clip: text;
}

/* Dark theme for inputs */
.dark-input {
  input {
    color: var(--input-color-login);
    background: var(--input-background-login);

    &:-webkit-autofill {
      -webkit-box-shadow: var(--input-webkit-autofill-box-shadow-login) !important;
      -webkit-text-fill-color: var(--input-webkit-text-fill-color-login);
      -webkit-background-clip: text;
    }
  }
}
/** */

input,
.dateInput-input {
  &::-moz-placeholder {
    color: color(input, text);
  }
  &::-webkit-input-placeholder {
    color: color(input, text);
  }
}
input:not(.dateInput-input) {
  &::-moz-placeholder {
    font-size: 12px;
    line-height: 40px;
    opacity: 1;
  }
  &::-webkit-input-placeholder {
    font-size: 12px;
    line-height: 40px;
    opacity: 1;
  }
}
button.adornment-password {
  position: absolute;
  right: 0;
  align-self: center;
  width: 40px;
  height: 40px;
  color: var(--adornment-password-color);
  font-size: 18px;

  svg {
    opacity: 0;
  }
  &::before {
    @include svg-icon;
    position: absolute;
  }
}
input[type='text'] + .adornment-password {
  &::before {
    content: '\0021';
  }
}
input[type='password'] + .adornment-password {
  &::before {
    content: '\0039';
  }
}

/* Select */

.select-root {
  .select-selectMenu {
    display: flex;
    align-items: center;
    padding-right: 32px;
  }
  .select-icon {
    top: 3px;
    right: 0;
    width: 34px;
    height: 34px;
  }
}
.select-select:focus {
  background: var(--input-background);
  border-radius: 5px;
}
.select-menuModal {
  .applied-country--swe & {
    z-index: 1502; // 1501 is z-index responsible widget
  }

  .select-menuPaper {
    margin: auto;
    background: var(--home-menu-background-mobile);

    ul {
      padding: 0;

      li {
        font-family: $font-primary;
        font-size: 12px;

        &.select-search {
          padding-top: 40px;
          // overflow: auto; [1]

          .select-searchInput {
            background: var(--select-menu-background);
            position: fixed; // [1] instead of `position: sticky` because Safari doesn't support `sticky` inside parent element with `overflow: auto`
            transform: translate3d(0, 0, 0); // Fix for Safari
            width: 100%;

            .select-searchIcon {
              fill: var(--select-icon-color);
            }
          }
        }
        &:not(.select-search) {
          height: 100%;
          transition: all 0.3s;
          padding: 5px;
          color: #868686;
        }
      }
    }
  }
}

/* Checkbox */

.field-checkbox {
  margin-top: 10px;

  label {
    margin-right: 0;
    margin-left: 0;
    align-items: flex-start;
    a {
      text-decoration: none;
    }
  }
  .checkbox-default,
  .checkbox-checked {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 3px;
    border: 1px solid;
    color: var(--button-secondary-color);

    &.checkbox-disabled {
      border-color: color(input, disabled);
    }

    svg {
      display: none;
    }
    + span {
      font-size: 12px;
    }
    &.checkbox-disabled + span {
      color: color(input, disabled);
    }
  }
  .checkbox-checked {

    &::before {
      @include svg-icon;
      content: '\0075';
      margin-left: 4px;
      font-size: 10px;
      font-weight: $font-weight-alt;

      @include media-xs {
        margin-left: 2px;
      }
    }

    &.checkbox-disabled {
      border-color: color(input, disabled);
      background: color(input, disabled);
    }
    &::before {
      //color: color(secondary, main);;
    }
  }
}

/* Phone number */

.phone-number-wrapper {
  display: flex;

  .input-root {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 10px;

    input {
      border-radius: 0 3px 3px 0;
    }
  }
  .select-select {
    display: flex;
    align-items: center;
    border-radius: 3px 0 0 3px;
    width: 100px; // needed for label floating animation

    @include media-sm {
      width: 80px;
    }

    .group-root {
      display: flex;
      align-items: center;

      img {
        width: 26px;
        margin-right: 5px;
      }
    }
  }
  .select-root .select-icon {
    @include media-sm {
      display: none;
    }
  }
}
#menu-phoneNumber_code {
  .selectItem-root {
    .phone-number__code {
      padding-right: 5px;
    }
    img {
      width: 26px;
      margin-right: 10px;
    }
  }
}

/* Button group */

.field-inputValues {
  .input-valuesLabel {
    font-size: 12px;
  }
  .input-values {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
    transition: all 0.3s;

    .input-value {
      flex: 1;
      white-space: nowrap;
      border: 1px solid color(input, border);

      + .input-value {
        margin-left: -1px;
      }
      button {
        width: 100%;
        min-width: 0;
        color: color(input, text);
        font-size: 16px;
        font-weight: $font-weight-base;
        text-transform: none;
        border-radius: 0;

        &:hover {
          background: transparent;
        }
        @include media-xs {
          padding-left: 8px;
          padding-right: 8px;
        }
      }
      &.input-selectedValue {
        z-index: 5;
        border-color: color(primary, main);

        button {
          color: color(primary, dark);
        }
      }
    }
  }
}

/* Radio group */

.field-radioGroup {
  display: flex;

  legend {
    color: var(--input-label-filled-color);
  }
}
.radioGroup-root {
  flex-direction: initial;
  padding: 1px 0 0 1px;

  label {
    flex: 1;
    min-width: 33.33333%;
    margin: -1px 0 0 -1px;
  }
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 0 5px;
    font-size: 12px;
    line-height: 1;
  }
  .formControlLabel-label {
    border: 1px solid color(input, border);
    border-radius: 0;
    text-align: center;
    color: var(--input-color);
    background: transparent;

    @include media-xs {
      color: var(--input-color-mobile);
    }
  }
  .radio-checked,
  .radio-default {
    display: none;
  }
}
.radio-checked + .formControlLabel-label {
  border-color: color(primary, main);
  z-index: 2;
}

/* Radio list */

.radio-list {
  display: block;

  span {
    width: auto;
    padding: 0;
  }
  .formControlLabel-root {
    display: block;
    min-width: 0;
    float: left;
    clear: both;
    text-align: left;
  }
  .radio-default {
    display: inline-flex;
    vertical-align: top;

    svg {
      width: 20px;
      height: 20px;
      fill: color(primary, main);
    }
  }
  .formControlLabel-label {
    display: inline-flex;
    max-width: calc(100% - 20px);
    padding-left: 10px;
    text-align: left;
    font-size: 14px;
    border: 0;
  }
  label + label {
    margin-left: 0;
  }
}
