.playerVerificationStep1 {
  .playerVerificationForm1 {
    .container {
      @include media-xs {
        padding: 0;
      }
    }
    &__fields {
      @include media(null, $size-xs) {
        column-gap: 10px;
        display: grid;
        grid-template-columns: repeat(5, 7%) 10px repeat(5, 7%);

        & > div {
          max-width: initial;
        }

        .city,
        .country,
        .lastName,
        .residentialCity,
        .mothersMaidenName {
          grid-row: 1/1;
        }

        .address,
        .zipCode,
        .firstName,
        .birthPlace,
        .houseNumber,
        .residentialZipCode,
        .residentialAddress,
        .residentialHouseNumber {
          grid-row: 2/2;
        }

        .address,
        .country,
        .lastName,
        .firstName,
        .maidenName,
        .residentialAddress {
          grid-column: 1/6;
        }

        .city,
        .birthPlace,
        .citizenship,
        .residentialCity,
        .mothersMaidenName {
          grid-column: 7/12;
        }

        .maidenName,
        .citizenship {
          grid-row: 3/3;
        }

        .houseNumber,
        .residentialHouseNumber {
          grid-column: 7/10;
        }
        .zipCode,
        .residentialZipCode {
          grid-column: 10/12;
        }

        .phoneNumber {
          grid-row: 4/4;
        }

        .phoneNumber {
          grid-column: 4/9;
        }

        &.playerResidentialAddress {
          .city,
          .address,
          .zipCode,
          .houseNumber {
            grid-row: 1/1;
          }
          .address {
            grid-column: 1/4;
          }
          .houseNumber {
            grid-column: 4/6;
          }
          .city {
            grid-column: 7/10;
          }
          .zipCode {
            grid-column: 10/12;
          }
        }
      }
    }
    &__header {
      max-width: 722px;
      padding: 0 70px;
      margin: 40px auto 20px auto;
      color: color(secondary, light);
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.5px;
      line-height: 1;
      text-align: center;

      @include media-xs {
        color: var(--file-attachments-border);
        font-size: 12px;
        padding: 0;
        margin: 10px 0 14px;
        font-weight: 500;
        text-align: left;
      }
    }

    .playerResidentialAddress {
      @include media-xs {
        .fields-container__grid-root {
          &--city {
            order: 3;
          }

          &--address {
            order: 1;
          }

          &--houseNumber {
            order: 2;
          }

          &--zipCode {
            order: 4;
          }
        }
      }
    }
  }
}
