body .liveStudioPage {
  margin: -15px;

  &__header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    height: 450px;
    background-image: url('/cms/img/livestudio/liveStudio-mainbg.png');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    color: color(secondary, light);

    @include media-xs {
      background-size: 175%;
      padding: 0;
      height: auto;
    }

    .button-root {
      display: inline-block;
      padding: 10px 23px 8px;
      font-size: 18px;
      line-height: 1.5;

      @include media-xs {
        margin: 0 auto;
        display: block;
        width: fit-content;
      }
    }
  }

  &__info {
    min-width: 600px;
    margin-bottom: 10px;

    @include media-xs {
      margin-top: 154px;
      background: color(secondary, dark);
      min-width: auto;
      padding: 10px;

      img {
        display: none;
      }
    }
  }

  &__schedule {
    font-size: 24px;
    margin: 40px 0;

    @include media-xs {
      font-size: 16px;
      margin: 0 0 20px 0;
    }
  }

  &__description {
    font-size: 14px;
    margin-bottom: 36px;

    @include media-xs {
      color: #fff;

      br {
        display: none;
      }
    }
  }

  &__textSection {
    position: relative;
    background-color: color(secondary, dark);
    padding: 15px;
    text-align: center;
    color: #fff;

    @include media-xs {
      display: none;
    }

    h3 {
      font-size: 36px;
      margin-top: 0;
      margin-bottom: 27px;
      text-transform: uppercase;
      line-height: 1;
    }

    p {
      font-size: 16px;
      max-width: 900px;
      margin: 0 auto;
    }

    &::after {
      content: '';
      position: absolute;
      display: block;
      height: 15px;
      width: 15px;
      bottom: -7px;
      left: calc(50% - 8px);
      background: color(secondary, dark);
      z-index: 100;
      transform: rotate(45deg);
    }
  }

  #gameGroupTitle {
    display: none;

    @include media-xs {
      display: block;
      height: auto;
      font-size: 16px;
      margin-top: 0;
      margin-bottom: 15px;
      text-align: center;
      color: color(primary, main);
    }
  }
}

.live-studio-games {
  background: #000;
  margin-left: -15px;
  width: calc(100% + 30px);
  margin-top: 15px;
  padding: 20px 10px;

  @include media-xs {
    margin-top: 0;
    padding: 10px;
    max-width: calc(100% + 30px);
    background: none;
  }

  .game-thumbnail {
    margin: 2px;

    @media (min-width: 1025px) {
      width: calc(20% - 4px);
    }

    @media (max-width: 1023px) {
      width: calc(25% - 4px);
    }

    @media (max-width: 961px) {
      width: calc(33.333% - 4px);
    }

    @media (max-width: 730px) {
      width: calc(50% - 4px);
    }
  }
}


[data-url="/live-studio"] {
  .main-decorator__wrapper {
    margin-bottom: 0;
  }

  @include media-xs {
    .title {
      display: none;
    }
  }
}
