@keyframes show-gallery-item {
  from {
    transform: rotateZ(-10deg) scale(0.7);
    opacity: 0;
  }
  to {
    transform: rotateZ(0) scale(1);
    opacity: 1;
  }
}
@keyframes show-gallery {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@mixin gallery-buttons {
  user-select: none;
  animation: show-gallery 0.3s 0.3s backwards;
  width: 42px;
  min-width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  background: #0003;
  transition: all 0.3s;
  &:hover {
    background: #000;
  }
}

.snapshotField {
  &-root {
    justify-content: center;
    flex: 1;
    width: 100%;
  }
  &-spinner {
    width: 362px;
    height: 308px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-snap {
    border-radius: 0;
    background: color(primary, main);

    &:hover {
      background-color: darken(color(primary, main), 8%);
    }
  }
  &-button {
    padding: 30px;
    font-size: 16px;
    font-weight: $font-weight-base;
    color: #fff;
    background: color(primary, main);

    > span:first-child {
      display: flex;
      flex-direction: column;

      &::before {
        @include svg-icon;
        content: '\0029';
        margin-bottom: 10px;
        font-size: 80px;
        color: #fff;
      }
    }
    &:hover {
      background-color: darken(color(primary, main), 8%);
    }
  }
  &-popover {
    width: 362px;
    height: 308px;
  }
  &-video {
    height: 272px;
  }
}
.gallery {
  &-root {
    animation: show-gallery 0.3s;
    transition: all 0.3s;
    background: #000a;

    .galleryItem {
      &-root:not(.galleryItem-selected) {
        left: 0 !important;
        transform: rotateZ(10deg) scale(1.2);
        opacity: 0;
        pointer-events: none;
      }
      &-selected {
        opacity: 1;
        transform: scale(1);
        animation: show-gallery-item 0.3s;
      }
      &-delete {
        position: absolute;
        bottom: 20px;
        right: 50%;
        transform: translateX(50%);
      }
    }
  }
  &-removing {
    opacity: 0;
    .galleryItem-selected {
      transform: rotateZ(-10deg) scale(0.7);
      opacity: 0;
    }
  }
  &-delete,
  &-prev,
  &-next,
  &-close {
    @include gallery-buttons;
  }
  &-close {
    &:before {
      @include svg-icon;
      content: 'O';
    }
  }
  &-prev,
  &-next {
    &:before {
      @include svg-icon;
      content: 'b';
    }
  }
  &-prev {
    &:before {
      transform: rotateZ(180deg);
    }
  }
}
.galleryItem {
  &-delete {
    @include gallery-buttons;
    &:before {
      @include svg-icon;
      content: '\007c';
    }
  }
}
.table-root td.table-empty-cell {
  padding: 0;
}

// Circular progress
.circularProgress-root {
  .circularProgress-path {
    stroke: color(primary, main);
  }
}

// Linear progress
.linearProgress-root {
  .linearProgress-progress {
    fill: color(turnover-progress, progress);
  }
}

// Tooltip
.tooltip-root {
  background: #fff;
  padding: 5px;
  border: 1px solid #b3b3b3;
  color: color(primary, dark);

  .tooltip-arrow {
    &:before {
      content: '';
      display: block;
      position: absolute;
      border: inherit;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      border: inherit;
      margin-top: 1px;
    }
  }

  &[data-popper-placement^="bottom"] {
    .tooltip-arrow {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid transparent;

      &:before {
        border-bottom-color: #b3b3b3;
      }

      &:after {
        border-bottom-color: #fff;
      }
    }
  }
}
